import { ENVIRONMENTS } from '@shared/interfaces';

/* eslint-disable @typescript-eslint/no-var-requires */
export const environment = {
  env: ENVIRONMENTS.DEV,
  production: false,
  isDebugMode: true,
  isSentryDebugMode: false,
  VERSION: require('../../../../package.json').version,
  CORE_VERSION: require('../../../../package.json').coreversion,
  API_URL: 'https://dev.avo.jic.echonlabs.com',
  WEB_URL: 'https://dev.avo.jic.echonlabs.com',
  WEB_DOMAIN: 'dev.avo.jic.echonlabs.com',
  API_DOMAIN: 'dev.avo.jic.echonlabs.com',
  SENTRY_DSN: process.env.NX_WEB_SENTRY_DSN,
};
