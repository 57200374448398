export enum PAYMENT_TYPES {
  CUSTOMER = 'CUSTOMER',
  AGENT = 'AGENT',
  BROKER = 'BROKER',
}

export enum PAYMENT_STATUS {
  PENDING_OTP = 'PENDING_OTP',
  PAYMENT_DRAFT = 'PAYMENT_DRAFT',
  ONLINE_PAYMENT_DRAFT = 'ONLINE_PAYMENT_DRAFT',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
}

export enum PAYMENT_REFERENCE_CATEGORIES {
  BANKS = 'Banks new',
  BRANCHES = 'Banks  branches new',
  PAYER_RELATIONSHIP = 'Payer Relationship',
  CHEQUE_RELATIONSHIP = 'Cheque Relationship',
  OTP_CONFIGURATION = 'OTP configuration',
}
