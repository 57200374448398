import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseWidget, NgCompInputs } from 'gridstack/dist/angular';

import { ANALYZING_MODES, WIDGET_CATEGORIES } from '@shared/constants';
import {
  CommonResponseDTO,
  IBarWidgetData,
  IBarWidgetResponse,
  IWidget,
} from '@shared/interfaces';

import { PermissionFiltrationService } from '../../../../../services/permission-filtration.service';
import { SnackbarService } from '../../../../../services/snackbar.service';
import {
  LoggedUserService,
  UserInfoResponseDTO,
} from '../../../../auth/services';
import { ColorSchemeService } from '../../../../setup/appearance/services/color-scheme.service';
import { WidgetApiService } from '../../../services/widgets.api.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: 'bar-chart.component.html',
  styleUrls: ['bar-chart.component.scss'],
})
export class BarChartComponent extends BaseWidget implements OnInit, OnChanges {
  @Input() widget: IWidget;
  @Input() clickable: boolean;
  @Input() closable: boolean;

  single: IBarWidgetData[];
  description: string;

  animations = true;
  xAxis = true;
  yAxis = true;
  show;
  showLegends = true;
  scheme = 'cool';

  loggedUserInfo: UserInfoResponseDTO;
  hasPermissions = false;
  isFailedToLoad = false;
  isLoading = false;
  darkMode = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private snackbar: SnackbarService,
    private widgetApiService: WidgetApiService,
    private permissionFiltrationService: PermissionFiltrationService,
    private colorSchemeService: ColorSchemeService,
    private loggedUserService: LoggedUserService
  ) {
    super();
  }

  onColorSchemeChange() {
    const currentColorScheme = this.colorSchemeService.currentActive();

    if (currentColorScheme === 'dark') {
      this.darkMode = true;
      return true;
    } else {
      this.darkMode = false;
      return false;
    }
  }

  public override serialize(): NgCompInputs | undefined {
    return this.widget ? { text: this.widget } : undefined;
  }

  ngOnInit(): void {
    this.onColorSchemeChange();
    this.isLoading = true;
    this.loggedUserService.dataStore.subscribe((data) => {
      this.loggedUserInfo = data;
      if (data) {
        this.refreshWidget();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget?.currentValue.endpoint) {
      this.isLoading = true;
      this.refreshWidget();
    }
  }

  onClickWidget() {
    switch (this.widget.category_key) {
      case WIDGET_CATEGORIES.LOGS:
        this.router.navigate([`/app/logs`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.CONNECTED_APPS:
        this.router.navigate([`/app/apps`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.CONNECTIONS:
        this.router.navigate([`/app/connections`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.DRIVES:
        this.router.navigate([`app/storage/drives`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.IDENTITIES:
        this.router.navigate([`/app/identities`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.KNOWLEDGEBASE:
        this.router.navigate([`/app/knowledgebase`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.MESSAGES:
        this.router.navigate([`/app/messages`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.MODULES:
        this.router.navigate([`/app/modules`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.NOTICES:
        this.router.navigate([`/app/notices`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.PERMISSIONS:
        this.router.navigate([`/app/authorization/permissions`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.PRIVILEGES:
        this.router.navigate([`/app/authorization/privileges`], {
          queryParams: { isSearch: true },
        });
        break;

      case WIDGET_CATEGORIES.ROLES:
        this.router.navigate([`/app/authorization/roles`], {
          queryParams: { is_search: true },
        });
        break;

      case WIDGET_CATEGORIES.ACTIVITIES:
        this.router.navigate([`/app/authorization/activities`], {
          queryParams: { isSearch: true },
        });
        break;

      case WIDGET_CATEGORIES.AGENTS:
        this.router.navigate([`/app/agents`], {
          queryParams: { isSearch: true },
        });
        break;

      case WIDGET_CATEGORIES.CLAIMS:
        this.router.navigate([`/app/claims`], {
          queryParams: { isSearch: true },
        });
        break;

      case WIDGET_CATEGORIES.COVER_NOTES:
        this.router.navigate([`/app/cover-notes`], {
          queryParams: { isSearch: true },
        });
        break;

      case WIDGET_CATEGORIES.ENDORSEMENTS:
        this.router.navigate([`/app/endorsements`], {
          queryParams: { isSearch: true },
        });
        break;

      case WIDGET_CATEGORIES.INVOICES:
        this.router.navigate([`/app/invoices`], {
          queryParams: { isSearch: true },
        });
        break;

      case WIDGET_CATEGORIES.LEADS:
        this.router.navigate([`/app/leads`], {
          queryParams: { isSearch: true },
        });
        break;

      case WIDGET_CATEGORIES.POLICIES:
        this.router.navigate([`/app/policies`], {
          queryParams: { isSearch: true },
        });
        break;

      default:
        this.router.navigate([`/app/launchpad`]);
        break;
    }
  }

  refreshWidget() {
    this.isLoading = true;
    this.hasPermissions = this.permissionFiltrationService.validatePermissions({
      analyzingMode: ANALYZING_MODES.EVERY,
      permissions: this.widget.permissions,
    });
    this.widgetApiService.getWidgetData(this.widget.endpoint).subscribe({
      next: (res: CommonResponseDTO<IBarWidgetResponse>) => {
        this.single = res.data.data;
        this.description = res.data.description;
        this.isLoading = false;
        this.isFailedToLoad = false;
      },
      error: () => {
        this.isFailedToLoad = true;
        this.isLoading = false;
        this.snackbar.error(
          this.translate.instant('widgets.count-widget.failed-to-load-widget')
        );
      },
    });
  }
}
