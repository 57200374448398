import { CONFIGURABLE_FIELD_CHECKBOX_TYPES } from '../../configurable-fields';

export const LEADS_MODULE_PASS_TO_ACTIVITY_CHECKBOX = {
  key: 'Pass to Activity',
  type: CONFIGURABLE_FIELD_CHECKBOX_TYPES.PASS_TO_ACTIVITIES,
  isChecked: true,
};

export enum LEADS_DEFAULT_FIELDS {
  PRODUCTS = 'Products',
  ACTIVITY_TYPE = 'Activity Type',
  CATEGORIES = 'Categories',
  RELATIONSHIPS = 'Relationships',
  NIC = 'National Identity Card (NIC) Number',
  CONTACT_NUMBER = 'Contact Number (Type)',
  ADDRESS = 'Address',
  EMAIL = 'Email Address (Type)',
  BIRTHDAY = 'Birthday',
  EXPENSES_TYPE = 'Expenses Type',
  INCOME_MIN = 'Income Min',
  INCOME_MAX = 'Income Max',
  INTEREST_RATE = 'Interest Rate',
  ASSETS = 'Assets',
  ASSETS_TYPES = 'Assets Types',
  LIABILITIES = 'Liabilities',
  OCCUPATIONS = 'Occupations',
  INSURER = 'Insurer',
  POLICY_STATUS = 'Policy Status',

  // Quotation Flow
  PREFERRED_LANGUAGE = 'Preferred Language',

  CASH_ADVANCE_POLICY_TERM = 'Cash Advance Policy Term',
  CASH_ADVANCE_PREMIUM_TERM = 'Cash Advance Premium Term',

  LIFE_UNLIMITED_POLICY_TERM = 'Life Unlimited Policy Term',
  LIFE_UNLIMITED_PREMIUM_TERM = 'Life Unlimited Premium Term',

  FAMILY_PLUS_POLICY_TERM = 'Family Plus Policy Term',
  FAMILY_PLUS_PREMIUM_TERM = 'Family Plus Premium Term',

  JEEVITHA_VARDANA_POLICY_TERM = 'Jeevitha Vardana Policy Term',
  JEEVITHA_VARDANA_PREMIUM_TERM = 'Jeevitha Vardana Premium Term',

  JEEVITHA_THILINA_POLICY_TERM = 'Jeevitha Thilina Policy Term',
  JEEVITHA_THILINA_PREMIUM_TERM = 'Jeevitha Thilina Premium Term',

  SHILPASHAKTHI_POLICY_TERM = 'Shilpashakthi Policy Term',
  SHILPASHAKTHI_PREMIUM_TERM = 'Shilpashakthi Premium Term',

  SUWASHAKTHI_POLICY_TERM = 'Suwashakthi Policy Term',
  SUWASHAKTHI_PREMIUM_TERM = 'Suwashakthi Premium Term',

  INVESTMENT_POLICY_TERM = 'Investment Policy Term',
  INVESTMENT_PREMIUM_TERM = 'Investment Premium Term',

  SWARNASHAKTHI_POLICY_TERM = 'Swarnashakthi Policy Term',
  SWARNASHAKTHI_PREMIUM_TERM = 'Swarnashakthi Premium Term',

  DREAM_SERVER_POLICY_TERM = 'Dream Saver Policy Term',
  DREAM_SERVER_PREMIUM_TERM = 'Dream Saver Premium Term',

  FLEXI_POLICY_TERM = 'Investment Flexi Policy Term',
  FLEXI_PREMIUM_TERM = 'Investment Flexi Premium Term',

  //Cash advance
  CASH_ADVANCE_BASIC_SELF_MIN_ASSURED = 'Cash Advance Basic Self Assured Min',
  CASH_ADVANCE_BASIC_SELF_MAX_ASSURED = 'Cash Advance Basic Self Assured Max',
  CASH_ADVANCE_ADDITIONAL_SELF_MIN_ASSURED = 'Cash Advance Additional Self Assured Min',
  CASH_ADVANCE_ADDITIONAL_SELF_MAX_ASSURED = 'Cash Advance Additional Self Assured Max',
  CASH_ADVANCE_ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Cash Advance Additional Accident Self Assured Min',
  CASH_ADVANCE_ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Cash Advance Additional Accident Self Assured Max',
  CASH_ADVANCE_CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Cash Advance Critical illness Self Assured Min',
  CASH_ADVANCE_CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Cash Advance Critical illness Self Assured Max',
  CASH_ADVANCE_HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Cash Advance Hospital Per Day Self Assured Min',
  CASH_ADVANCE_HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Cash Advance Hospital Per Day Self Assured Max',
  CASH_ADVANCE_HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Cash Advance Hospital Reimbursement Self Assured Min',
  CASH_ADVANCE_HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Cash Advance Hospital Reimbursement Self Assured Max',
  CASH_ADVANCE_FAMILY_INCOME_SELF_MIN_ASSURED = 'Cash Advance Family Income Self Assured Min',
  CASH_ADVANCE_FAMILY_INCOME_SELF_MAX_ASSURED = 'Cash Advance Family Income Self Assured Max',
  CASH_ADVANCE_FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Cash Advance Funeral Expenses Self Assured Min',
  CASH_ADVANCE_FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Cash Advance Funeral Expenses Self Assured Max',

  CASH_ADVANCE_BASIC_SPOUSE_MIN_ASSURED = 'Cash Advance Basic Spouse Assured Min',
  CASH_ADVANCE_BASIC_SPOUSE_MAX_ASSURED = 'Cash Advance Basic Spouse Assured Max',
  CASH_ADVANCE_ADDITIONAL_SPOUSE_MIN_ASSURED = 'Cash Advance Additional Spouse Assured Min',
  CASH_ADVANCE_ADDITIONAL_SPOUSE_MAX_ASSURED = 'Cash Advance Additional Spouse Assured Max',
  CASH_ADVANCE_ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Cash Advance Additional Accident Spouse Assured Min',
  CASH_ADVANCE_ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Cash Advance Additional Accident Spouse Assured Max',
  CASH_ADVANCE_CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Cash Advance Critical illness Spouse Assured Min',
  CASH_ADVANCE_CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Cash Advance Critical illness Spouse Assured Max',
  CASH_ADVANCE_HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Cash Advance Hospital Per Day Spouse Assured Min',
  CASH_ADVANCE_HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Cash Advance Hospital Per Day Spouse Assured Max',
  CASH_ADVANCE_HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Cash Advance Hospital Reimbursement Spouse Assured Min',
  CASH_ADVANCE_HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Cash Advance Hospital Reimbursement Spouse Assured Max',
  CASH_ADVANCE_FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Cash Advance Family Income Spouse Assured Min',
  CASH_ADVANCE_FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Cash Advance Family Income Spouse Assured Max',
  CASH_ADVANCE_FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Cash Advance Funeral Expenses Spouse Assured Min',
  CASH_ADVANCE_FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Cash Advance Funeral Expenses Spouse Assured Max',

  CASH_ADVANCE_BASIC_DEPENDENTS_MIN_ASSURED = 'Cash Advance Basic Dependents Assured Min',
  CASH_ADVANCE_BASIC_DEPENDENTS_MAX_ASSURED = 'Cash Advance Basic Dependents Assured Max',
  CASH_ADVANCE_ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Cash Advance Additional Dependents Assured Min',
  CASH_ADVANCE_ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Cash Advance Additional Dependents Assured Max',
  CASH_ADVANCE_ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Cash Advance Additional Accident Dependents Assured Min',
  CASH_ADVANCE_ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Cash Advance Additional Accident Dependents Assured Max',
  CASH_ADVANCE_CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Cash Advance Critical illness Dependents Assured Min',
  CASH_ADVANCE_CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Cash Advance Critical illness Dependents Assured Max',
  CASH_ADVANCE_HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Cash Advance Hospital Per Day Dependents Assured Min',
  CASH_ADVANCE_HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Cash Advance Hospital Per Day Dependents Assured Max',
  CASH_ADVANCE_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Cash Advance Hospital Reimbursement Dependents Assured Min',
  CASH_ADVANCE_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Cash Advance Hospital Reimbursement Dependents Assured Max',
  CASH_ADVANCE_FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Cash Advance Family Income Dependents Assured Min',
  CASH_ADVANCE_FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Cash Advance Family Income Dependents Assured Max',
  CASH_ADVANCE_FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Cash Advance Funeral Expenses Dependents Assured Min',
  CASH_ADVANCE_FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Cash Advance Funeral Expenses Dependents Assured Max',

  CASH_ADVANCE_YEARLY_MIN_MODE = 'Cash Advance Min Yearly Mode',
  CASH_ADVANCE_HALF_YEARLY_MIN_MODE = 'Cash Advance Min Half Yearly Mode',
  CASH_ADVANCE_QUARTERLY_MIN_MODE = 'Cash Advance Min Quarterly Mode',
  CASH_ADVANCE_MONTHLY_MIN_MODE = 'Cash Advance Min Month Mode',

  CASH_ADVANCE_BASIC_MULTIPLE = 'Cash Advance Basic Multiple',
  CASH_ADVANCE_ADDITIONAL_MULTIPLE = 'Cash Advance Additional Multiple',
  CASH_ADVANCE_ADDITIONAL_ACCIDENT_MULTIPLE = 'Cash Advance Additional Accident Multiple',
  CASH_ADVANCE_CRITICAL_ILLNESS_MULTIPLE = 'Cash Advance Critical illness Multiple',
  CASH_ADVANCE_HOSPITAL_PER_DAY_MULTIPLE = 'Cash Advance Hospital Per Day Multiple',
  CASH_ADVANCE_HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Cash Advance Hospital Reimbursement Multiple',
  CASH_ADVANCE_FAMILY_INCOME_MULTIPLE = 'Cash Advance Family Income Multiple',
  CASH_ADVANCE_FUNERAL_EXPENSES_MULTIPLE = 'Cash Advance Funeral Expenses Multiple',

  CASH_ADVANCE_BASIC_SELF_RATE = 'Cash Advance Basic Self Rate',
  CASH_ADVANCE_ADDITIONAL_SELF_RATE = 'Cash Advance Additional Self Rate',
  CASH_ADVANCE_ADDITIONAL_ACCIDENT_SELF_RATE = 'Cash Advance Additional Accident Self Rate',
  CASH_ADVANCE_CRITICAL_ILLNESS_SELF_RATE = 'Cash Advance Critical illness Self Rate',
  CASH_ADVANCE_HOSPITAL_PER_DAY_SELF_RATE = 'Cash Advance Hospital Per Day Self Rate',
  CASH_ADVANCE_HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Cash Advance Hospital Reimbursement Self Rate',
  CASH_ADVANCE_FAMILY_INCOME_SELF_RATE = 'Cash Advance Family Income Self Rate',
  CASH_ADVANCE_FUNERAL_EXPENSES_SELF_RATE = 'Cash Advance Funeral Expenses Self Rate',

  CASH_ADVANCE_BASIC_SPOUSE_RATE = 'Cash Advance Basic Spouse Rate',
  CASH_ADVANCE_ADDITIONAL_SPOUSE_RATE = 'Cash Advance Additional Spouse Rate',
  CASH_ADVANCE_ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Cash Advance Additional Accident Spouse Rate',
  CASH_ADVANCE_CRITICAL_ILLNESS_SPOUSE_RATE = 'Cash Advance Critical illness Spouse Rate',
  CASH_ADVANCE_HOSPITAL_PER_DAY_SPOUSE_RATE = 'Cash Advance Hospital Per Day Spouse Rate',
  CASH_ADVANCE_HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Cash Advance Hospital Reimbursement Spouse Rate',
  CASH_ADVANCE_FAMILY_INCOME_SPOUSE_RATE = 'Cash Advance Family Income Spouse Rate',
  CASH_ADVANCE_FUNERAL_EXPENSES_SPOUSE_RATE = 'Cash Advance Funeral Expenses Spouse Rate',

  CASH_ADVANCE_BASIC_DEPENDENTS_RATE = 'Cash Advance Basic Dependents Rate',
  CASH_ADVANCE_ADDITIONAL_DEPENDENTS_RATE = 'Cash Advance Additional Dependents Rate',
  CASH_ADVANCE_ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Cash Advance Additional Accident Dependents Rate',
  CASH_ADVANCE_CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Cash Advance Critical illness Dependents Rate',
  CASH_ADVANCE_HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Cash Advance Hospital Per Day Dependents Rate',
  CASH_ADVANCE_HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Cash Advance Hospital Reimbursement Dependents Rate',
  CASH_ADVANCE_FAMILY_INCOME_DEPENDENTS_RATE = 'Cash Advance Family Income Dependents Rate',
  CASH_ADVANCE_FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Cash Advance Funeral Expenses Dependents Rate',

  //Janashakthi Investment
  INVESTMENT_BASIC_SELF_MIN_ASSURED = 'Janashakthi Investment Basic Self Assured Min',
  INVESTMENT_BASIC_SELF_MAX_ASSURED = 'Janashakthi Investment Basic Self Assured Max',
  INVESTMENT_ADDITIONAL_SELF_MIN_ASSURED = 'Janashakthi Investment Additional Self Assured Min',
  INVESTMENT_ADDITIONAL_SELF_MAX_ASSURED = 'Janashakthi Investment Additional Self Assured Max',
  INVESTMENT_ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Janashakthi Investment Additional Accident Self Assured Min',
  INVESTMENT_ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Janashakthi Investment Additional Accident Self Assured Max',
  INVESTMENT_CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Janashakthi Investment Critical illness Self Assured Min',
  INVESTMENT_CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Janashakthi Investment Critical illness Self Assured Max',
  INVESTMENT_HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Janashakthi Investment Hospital Per Day Self Assured Min',
  INVESTMENT_HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Janashakthi Investment Hospital Per Day Self Assured Max',
  INVESTMENT_HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Janashakthi Investment Hospital Reimbursement Self Assured Min',
  INVESTMENT_HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Janashakthi Investment Hospital Reimbursement Self Assured Max',
  INVESTMENT_FAMILY_INCOME_SELF_MIN_ASSURED = 'Janashakthi Investment Family Income Self Assured Min',
  INVESTMENT_FAMILY_INCOME_SELF_MAX_ASSURED = 'Janashakthi Investment Family Income Self Assured Max',
  INVESTMENT_FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Janashakthi Investment Funeral Expenses Self Assured Min',
  INVESTMENT_FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Janashakthi Investment Funeral Expenses Self Assured Max',

  INVESTMENT_BASIC_SPOUSE_MIN_ASSURED = 'Janashakthi Investment Basic Spouse Assured Min',
  INVESTMENT_BASIC_SPOUSE_MAX_ASSURED = 'Janashakthi Investment Basic Spouse Assured Max',
  INVESTMENT_ADDITIONAL_SPOUSE_MIN_ASSURED = 'Janashakthi Investment Additional Spouse Assured Min',
  INVESTMENT_ADDITIONAL_SPOUSE_MAX_ASSURED = 'Janashakthi Investment Additional Spouse Assured Max',
  INVESTMENT_ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Janashakthi Investment Additional Accident Spouse Assured Min',
  INVESTMENT_ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Janashakthi Investment Additional Accident Spouse Assured Max',
  INVESTMENT_CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Janashakthi Investment Critical illness Spouse Assured Min',
  INVESTMENT_CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Janashakthi Investment Critical illness Spouse Assured Max',
  INVESTMENT_HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Janashakthi Investment Hospital Per Day Spouse Assured Min',
  INVESTMENT_HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Janashakthi Investment Hospital Per Day Spouse Assured Max',
  INVESTMENT_HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Janashakthi Investment Hospital Reimbursement Spouse Assured Min',
  INVESTMENT_HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Janashakthi Investment Hospital Reimbursement Spouse Assured Max',
  INVESTMENT_FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Janashakthi Investment Family Income Spouse Assured Min',
  INVESTMENT_FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Janashakthi Investment Family Income Spouse Assured Max',
  INVESTMENT_FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Janashakthi Investment Funeral Expenses Spouse Assured Min',
  INVESTMENT_FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Janashakthi Investment Funeral Expenses Spouse Assured Max',

  INVESTMENT_BASIC_DEPENDENTS_MIN_ASSURED = 'Janashakthi Investment Basic Dependents Assured Min',
  INVESTMENT_BASIC_DEPENDENTS_MAX_ASSURED = 'Janashakthi Investment Basic Dependents Assured Max',
  INVESTMENT_ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Janashakthi Investment Additional Dependents Assured Min',
  INVESTMENT_ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Janashakthi Investment Additional Dependents Assured Max',
  INVESTMENT_ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Janashakthi Investment Additional Accident Dependents Assured Min',
  INVESTMENT_ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Janashakthi Investment Additional Accident Dependents Assured Max',
  INVESTMENT_CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Janashakthi Investment Critical illness Dependents Assured Min',
  INVESTMENT_CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Janashakthi Investment Critical illness Dependents Assured Max',
  INVESTMENT_HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Janashakthi Investment Hospital Per Day Dependents Assured Min',
  INVESTMENT_HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Janashakthi Investment Hospital Per Day Dependents Assured Max',
  INVESTMENT_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Janashakthi Investment Hospital Reimbursement Dependents Assured Min',
  INVESTMENT_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Janashakthi Investment Hospital Reimbursement Dependents Assured Max',
  INVESTMENT_FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Janashakthi Investment Family Income Dependents Assured Min',
  INVESTMENT_FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Janashakthi Investment Family Income Dependents Assured Max',
  INVESTMENT_FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Janashakthi Investment Funeral Expenses Dependents Assured Min',
  INVESTMENT_FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Janashakthi Investment Funeral Expenses Dependents Assured Max',

  INVESTMENT_YEARLY_MIN_MODE = 'Janashakthi Investment Min Yearly Mode',
  INVESTMENT_HALF_YEARLY_MIN_MODE = 'Janashakthi Investment Min Half Yearly Mode',
  INVESTMENT_QUARTERLY_MIN_MODE = 'Janashakthi Investment Min Quarterly Mode',
  INVESTMENT_MONTHLY_MIN_MODE = 'Janashakthi Investment Min Month Mode',

  INVESTMENT_BASIC_MULTIPLE = 'Janashakthi Investment Basic Multiple',
  INVESTMENT_ADDITIONAL_MULTIPLE = 'Janashakthi Investment Additional Multiple',
  INVESTMENT_ADDITIONAL_ACCIDENT_MULTIPLE = 'Janashakthi Investment Additional Accident Multiple',
  INVESTMENT_CRITICAL_ILLNESS_MULTIPLE = 'Janashakthi Investment Critical illness Multiple',
  INVESTMENT_HOSPITAL_PER_DAY_MULTIPLE = 'Janashakthi Investment Hospital Per Day Multiple',
  INVESTMENT_HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Janashakthi Investment Hospital Reimbursement Multiple',
  INVESTMENT_FAMILY_INCOME_MULTIPLE = 'Janashakthi Investment Family Income Multiple',
  INVESTMENT_FUNERAL_EXPENSES_MULTIPLE = 'Janashakthi Investment Funeral Expenses Multiple',

  INVESTMENT_BASIC_SELF_RATE = 'Janashakthi Investment Basic Self Rate',
  INVESTMENT_ADDITIONAL_SELF_RATE = 'Janashakthi Investment Additional Self Rate',
  INVESTMENT_ADDITIONAL_ACCIDENT_SELF_RATE = 'Janashakthi Investment Additional Accident Self Rate',
  INVESTMENT_CRITICAL_ILLNESS_SELF_RATE = 'Janashakthi Investment Critical illness Self Rate',
  INVESTMENT_HOSPITAL_PER_DAY_SELF_RATE = 'Janashakthi Investment Hospital Per Day Self Rate',
  INVESTMENT_HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Janashakthi Investment Hospital Reimbursement Self Rate',
  INVESTMENT_FAMILY_INCOME_SELF_RATE = 'Janashakthi Investment Family Income Self Rate',
  INVESTMENT_FUNERAL_EXPENSES_SELF_RATE = 'Janashakthi Investment Funeral Expenses Self Rate',

  INVESTMENT_BASIC_SPOUSE_RATE = 'Janashakthi Investment Basic Spouse Rate',
  INVESTMENT_ADDITIONAL_SPOUSE_RATE = 'Janashakthi Investment Additional Spouse Rate',
  INVESTMENT_ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Janashakthi Investment Additional Accident Spouse Rate',
  INVESTMENT_CRITICAL_ILLNESS_SPOUSE_RATE = 'Janashakthi Investment Critical illness Spouse Rate',
  INVESTMENT_HOSPITAL_PER_DAY_SPOUSE_RATE = 'Janashakthi Investment Hospital Per Day Spouse Rate',
  INVESTMENT_HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Janashakthi Investment Hospital Reimbursement Spouse Rate',
  INVESTMENT_FAMILY_INCOME_SPOUSE_RATE = 'Janashakthi Investment Family Income Spouse Rate',
  INVESTMENT_FUNERAL_EXPENSES_SPOUSE_RATE = 'Janashakthi Investment Funeral Expenses Spouse Rate',

  INVESTMENT_BASIC_DEPENDENTS_RATE = 'Janashakthi Investment Basic Dependents Rate',
  INVESTMENT_ADDITIONAL_DEPENDENTS_RATE = 'Janashakthi Investment Additional Dependents Rate',
  INVESTMENT_ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Janashakthi Investment Additional Accident Dependents Rate',
  INVESTMENT_CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Janashakthi Investment Critical illness Dependents Rate',
  INVESTMENT_HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Janashakthi Investment Hospital Per Day Dependents Rate',
  INVESTMENT_HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Janashakthi Investment Hospital Reimbursement Dependents Rate',
  INVESTMENT_FAMILY_INCOME_DEPENDENTS_RATE = 'Janashakthi Investment Family Income Dependents Rate',
  INVESTMENT_FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Janashakthi Investment Funeral Expenses Dependents Rate',

  //Suwashakthi
  SUWASHAKTHI_BASIC_SELF_MIN_ASSURED = 'Suwashakthi Basic Self Assured Min',
  SUWASHAKTHI_BASIC_SELF_MAX_ASSURED = 'Suwashakthi Basic Self Assured Max',
  SUWASHAKTHI_ADDITIONAL_SELF_MIN_ASSURED = 'Suwashakthi Additional Self Assured Min',
  SUWASHAKTHI_ADDITIONAL_SELF_MAX_ASSURED = 'Suwashakthi Additional Self Assured Max',
  SUWASHAKTHI_ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Suwashakthi Additional Accident Self Assured Min',
  SUWASHAKTHI_ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Suwashakthi Additional Accident Self Assured Max',
  SUWASHAKTHI_CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Suwashakthi Critical illness Self Assured Min',
  SUWASHAKTHI_CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Suwashakthi Critical illness Self Assured Max',
  SUWASHAKTHI_HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Suwashakthi Hospital Per Day Self Assured Min',
  SUWASHAKTHI_HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Suwashakthi Hospital Per Day Self Assured Max',
  SUWASHAKTHI_HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Suwashakthi Hospital Reimbursement Self Assured Min',
  SUWASHAKTHI_HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Suwashakthi Hospital Reimbursement Self Assured Max',
  SUWASHAKTHI_FAMILY_INCOME_SELF_MIN_ASSURED = 'Suwashakthi Family Income Self Assured Min',
  SUWASHAKTHI_FAMILY_INCOME_SELF_MAX_ASSURED = 'Suwashakthi Family Income Self Assured Max',
  SUWASHAKTHI_FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Suwashakthi Funeral Expenses Self Assured Min',
  SUWASHAKTHI_FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Suwashakthi Funeral Expenses Self Assured Max',

  SUWASHAKTHI_BASIC_SPOUSE_MIN_ASSURED = 'Suwashakthi Basic Spouse Assured Min',
  SUWASHAKTHI_BASIC_SPOUSE_MAX_ASSURED = 'Suwashakthi Basic Spouse Assured Max',
  SUWASHAKTHI_ADDITIONAL_SPOUSE_MIN_ASSURED = 'Suwashakthi Additional Spouse Assured Min',
  SUWASHAKTHI_ADDITIONAL_SPOUSE_MAX_ASSURED = 'Suwashakthi Additional Spouse Assured Max',
  SUWASHAKTHI_ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Suwashakthi Additional Accident Spouse Assured Min',
  SUWASHAKTHI_ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Suwashakthi Additional Accident Spouse Assured Max',
  SUWASHAKTHI_CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Suwashakthi Critical illness Spouse Assured Min',
  SUWASHAKTHI_CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Suwashakthi Critical illness Spouse Assured Max',
  SUWASHAKTHI_HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Suwashakthi Hospital Per Day Spouse Assured Min',
  SUWASHAKTHI_HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Suwashakthi Hospital Per Day Spouse Assured Max',
  SUWASHAKTHI_HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Suwashakthi Hospital Reimbursement Spouse Assured Min',
  SUWASHAKTHI_HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Suwashakthi Hospital Reimbursement Spouse Assured Max',
  SUWASHAKTHI_FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Suwashakthi Family Income Spouse Assured Min',
  SUWASHAKTHI_FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Suwashakthi Family Income Spouse Assured Max',
  SUWASHAKTHI_FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Suwashakthi Funeral Expenses Spouse Assured Min',
  SUWASHAKTHI_FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Suwashakthi Funeral Expenses Spouse Assured Max',

  SUWASHAKTHI_BASIC_DEPENDENTS_MIN_ASSURED = 'Suwashakthi Basic Dependents Assured Min',
  SUWASHAKTHI_BASIC_DEPENDENTS_MAX_ASSURED = 'Suwashakthi Basic Dependents Assured Max',
  SUWASHAKTHI_ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Suwashakthi Additional Dependents Assured Min',
  SUWASHAKTHI_ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Suwashakthi Additional Dependents Assured Max',
  SUWASHAKTHI_ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Suwashakthi Additional Accident Dependents Assured Min',
  SUWASHAKTHI_ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Suwashakthi Additional Accident Dependents Assured Max',
  SUWASHAKTHI_CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Suwashakthi Critical illness Dependents Assured Min',
  SUWASHAKTHI_CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Suwashakthi Critical illness Dependents Assured Max',
  SUWASHAKTHI_HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Suwashakthi Hospital Per Day Dependents Assured Min',
  SUWASHAKTHI_HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Suwashakthi Hospital Per Day Dependents Assured Max',
  SUWASHAKTHI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Suwashakthi Hospital Reimbursement Dependents Assured Min',
  SUWASHAKTHI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Suwashakthi Hospital Reimbursement Dependents Assured Max',
  SUWASHAKTHI_FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Suwashakthi Family Income Dependents Assured Min',
  SUWASHAKTHI_FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Suwashakthi Family Income Dependents Assured Max',
  SUWASHAKTHI_FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Suwashakthi Funeral Expenses Dependents Assured Min',
  SUWASHAKTHI_FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Suwashakthi Funeral Expenses Dependents Assured Max',

  SUWASHAKTHI_YEARLY_MIN_MODE = 'Suwashakthi Min Yearly Mode',
  SUWASHAKTHI_HALF_YEARLY_MIN_MODE = 'Suwashakthi Min Half Yearly Mode',
  SUWASHAKTHI_QUARTERLY_MIN_MODE = 'Suwashakthi Min Quarterly Mode',
  SUWASHAKTHI_MONTHLY_MIN_MODE = 'Suwashakthi Min Month Mode',

  SUWASHAKTHI_BASIC_MULTIPLE = 'Suwashakthi Basic Multiple',
  SUWASHAKTHI_ADDITIONAL_MULTIPLE = 'Suwashakthi Additional Multiple',
  SUWASHAKTHI_ADDITIONAL_ACCIDENT_MULTIPLE = 'Suwashakthi Additional Accident Multiple',
  SUWASHAKTHI_CRITICAL_ILLNESS_MULTIPLE = 'Suwashakthi Critical illness Multiple',
  SUWASHAKTHI_HOSPITAL_PER_DAY_MULTIPLE = 'Suwashakthi Hospital Per Day Multiple',
  SUWASHAKTHI_HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Suwashakthi Hospital Reimbursement Multiple',
  SUWASHAKTHI_FAMILY_INCOME_MULTIPLE = 'Suwashakthi Family Income Multiple',
  SUWASHAKTHI_FUNERAL_EXPENSES_MULTIPLE = 'Suwashakthi Funeral Expenses Multiple',

  SUWASHAKTHI_BASIC_SELF_RATE = 'Suwashakthi Basic Self Rate',
  SUWASHAKTHI_ADDITIONAL_SELF_RATE = 'Suwashakthi Additional Self Rate',
  SUWASHAKTHI_ADDITIONAL_ACCIDENT_SELF_RATE = 'Suwashakthi Additional Accident Self Rate',
  SUWASHAKTHI_CRITICAL_ILLNESS_SELF_RATE = 'Suwashakthi Critical illness Self Rate',
  SUWASHAKTHI_HOSPITAL_PER_DAY_SELF_RATE = 'Suwashakthi Hospital Per Day Self Rate',
  SUWASHAKTHI_HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Suwashakthi Hospital Reimbursement Self Rate',
  SUWASHAKTHI_FAMILY_INCOME_SELF_RATE = 'Suwashakthi Family Income Self Rate',
  SUWASHAKTHI_FUNERAL_EXPENSES_SELF_RATE = 'Suwashakthi Funeral Expenses Self Rate',

  SUWASHAKTHI_BASIC_SPOUSE_RATE = 'Suwashakthi Basic Spouse Rate',
  SUWASHAKTHI_ADDITIONAL_SPOUSE_RATE = 'Suwashakthi Additional Spouse Rate',
  SUWASHAKTHI_ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Suwashakthi Additional Accident Spouse Rate',
  SUWASHAKTHI_CRITICAL_ILLNESS_SPOUSE_RATE = 'Suwashakthi Critical illness Spouse Rate',
  SUWASHAKTHI_HOSPITAL_PER_DAY_SPOUSE_RATE = 'Suwashakthi Hospital Per Day Spouse Rate',
  SUWASHAKTHI_HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Suwashakthi Hospital Reimbursement Spouse Rate',
  SUWASHAKTHI_FAMILY_INCOME_SPOUSE_RATE = 'Suwashakthi Family Income Spouse Rate',
  SUWASHAKTHI_FUNERAL_EXPENSES_SPOUSE_RATE = 'Suwashakthi Funeral Expenses Spouse Rate',

  SUWASHAKTHI_BASIC_DEPENDENTS_RATE = 'Suwashakthi Basic Dependents Rate',
  SUWASHAKTHI_ADDITIONAL_DEPENDENTS_RATE = 'Suwashakthi Additional Dependents Rate',
  SUWASHAKTHI_ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Suwashakthi Additional Accident Dependents Rate',
  SUWASHAKTHI_CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Suwashakthi Critical illness Dependents Rate',
  SUWASHAKTHI_HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Suwashakthi Hospital Per Day Dependents Rate',
  SUWASHAKTHI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Suwashakthi Hospital Reimbursement Dependents Rate',
  SUWASHAKTHI_FAMILY_INCOME_DEPENDENTS_RATE = 'Suwashakthi Family Income Dependents Rate',
  SUWASHAKTHI_FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Suwashakthi Funeral Expenses Dependents Rate',

  //Jeevith Vardana
  VARDANA_BASIC_SELF_MIN_ASSURED = 'Jeevitha Vardana Basic Self Assured Min',
  VARDANA_BASIC_SELF_MAX_ASSURED = 'Jeevitha Vardana Basic Self Assured Max',
  VARDANA_ADDITIONAL_SELF_MIN_ASSURED = 'Jeevitha Vardana Additional Self Assured Min',
  VARDANA_ADDITIONAL_SELF_MAX_ASSURED = 'Jeevitha Vardana Additional Self Assured Max',
  VARDANA_ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Jeevitha Vardana Additional Accident Self Assured Min',
  VARDANA_ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Jeevitha Vardana Additional Accident Self Assured Max',
  VARDANA_CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Jeevitha Vardana Critical illness Self Assured Min',
  VARDANA_CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Jeevitha Vardana Critical illness Self Assured Max',
  VARDANA_HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Jeevitha Vardana Hospital Per Day Self Assured Min',
  VARDANA_HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Jeevitha Vardana Hospital Per Day Self Assured Max',
  VARDANA_HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Jeevitha Vardana Hospital Reimbursement Self Assured Min',
  VARDANA_HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Jeevitha Vardana Hospital Reimbursement Self Assured Max',
  VARDANA_FAMILY_INCOME_SELF_MIN_ASSURED = 'Jeevitha Vardana Family Income Self Assured Min',
  VARDANA_FAMILY_INCOME_SELF_MAX_ASSURED = 'Jeevitha Vardana Family Income Self Assured Max',
  VARDANA_FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Jeevitha Vardana Funeral Expenses Self Assured Min',
  VARDANA_FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Jeevitha Vardana Funeral Expenses Self Assured Max',

  VARDANA_BASIC_SPOUSE_MIN_ASSURED = 'Jeevitha Vardana Basic Spouse Assured Min',
  VARDANA_BASIC_SPOUSE_MAX_ASSURED = 'Jeevitha Vardana Basic Spouse Assured Max',
  VARDANA_ADDITIONAL_SPOUSE_MIN_ASSURED = 'Jeevitha Vardana Additional Spouse Assured Min',
  VARDANA_ADDITIONAL_SPOUSE_MAX_ASSURED = 'Jeevitha Vardana Additional Spouse Assured Max',
  VARDANA_ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Jeevitha Vardana Additional Accident Spouse Assured Min',
  VARDANA_ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Jeevitha Vardana Additional Accident Spouse Assured Max',
  VARDANA_CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Jeevitha Vardana Critical illness Spouse Assured Min',
  VARDANA_CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Jeevitha Vardana Critical illness Spouse Assured Max',
  VARDANA_HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Jeevitha Vardana Hospital Per Day Spouse Assured Min',
  VARDANA_HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Jeevitha Vardana Hospital Per Day Spouse Assured Max',
  VARDANA_HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Jeevitha Vardana Hospital Reimbursement Spouse Assured Min',
  VARDANA_HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Jeevitha Vardana Hospital Reimbursement Spouse Assured Max',
  VARDANA_FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Jeevitha Vardana Family Income Spouse Assured Min',
  VARDANA_FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Jeevitha Vardana Family Income Spouse Assured Max',
  VARDANA_FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Jeevitha Vardana Funeral Expenses Spouse Assured Min',
  VARDANA_FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Jeevitha Vardana Funeral Expenses Spouse Assured Max',

  VARDANA_BASIC_DEPENDENTS_MIN_ASSURED = 'Jeevitha Vardana Basic Dependents Assured Min',
  VARDANA_BASIC_DEPENDENTS_MAX_ASSURED = 'Jeevitha Vardana Basic Dependents Assured Max',
  VARDANA_ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Jeevitha Vardana Additional Dependents Assured Min',
  VARDANA_ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Jeevitha Vardana Additional Dependents Assured Max',
  VARDANA_ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Jeevitha Vardana Additional Accident Dependents Assured Min',
  VARDANA_ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Jeevitha Vardana Additional Accident Dependents Assured Max',
  VARDANA_CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Jeevitha Vardana Critical illness Dependents Assured Min',
  VARDANA_CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Jeevitha Vardana Critical illness Dependents Assured Max',
  VARDANA_HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Jeevitha Vardana Hospital Per Day Dependents Assured Min',
  VARDANA_HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Jeevitha Vardana Hospital Per Day Dependents Assured Max',
  VARDANA_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Jeevitha Vardana Hospital Reimbursement Dependents Assured Min',
  VARDANA_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Jeevitha Vardana Hospital Reimbursement Dependents Assured Max',
  VARDANA_FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Jeevitha Vardana Family Income Dependents Assured Min',
  VARDANA_FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Jeevitha Vardana Family Income Dependents Assured Max',
  VARDANA_FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Jeevitha Vardana Funeral Expenses Dependents Assured Min',
  VARDANA_FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Jeevitha Vardana Funeral Expenses Dependents Assured Max',

  VARDANA_YEARLY_MIN_MODE = 'Jeevitha Vardana Min Yearly Mode',
  VARDANA_HALF_YEARLY_MIN_MODE = 'Jeevitha Vardana Min Half Yearly Mode',
  VARDANA_QUARTERLY_MIN_MODE = 'Jeevitha Vardana Min Quarterly Mode',
  VARDANA_MONTHLY_MIN_MODE = 'Jeevitha Vardana Min Month Mode',

  VARDANA_BASIC_MULTIPLE = 'Jeevitha Vardana Basic Multiple',
  VARDANA_ADDITIONAL_MULTIPLE = 'Jeevitha Vardana Additional Multiple',
  VARDANA_ADDITIONAL_ACCIDENT_MULTIPLE = 'Jeevitha Vardana Additional Accident Multiple',
  VARDANA_CRITICAL_ILLNESS_MULTIPLE = 'Jeevitha Vardana Critical illness Multiple',
  VARDANA_HOSPITAL_PER_DAY_MULTIPLE = 'Jeevitha Vardana Hospital Per Day Multiple',
  VARDANA_HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Jeevitha Vardana Hospital Reimbursement Multiple',
  VARDANA_FAMILY_INCOME_MULTIPLE = 'Jeevitha Vardana Family Income Multiple',
  VARDANA_FUNERAL_EXPENSES_MULTIPLE = 'Jeevitha Vardana Funeral Expenses Multiple',

  VARDANA_BASIC_SELF_RATE = 'Jeevitha Vardana Basic Self Rate',
  VARDANA_ADDITIONAL_SELF_RATE = 'Jeevitha Vardana Additional Self Rate',
  VARDANA_ADDITIONAL_ACCIDENT_SELF_RATE = 'Jeevitha Vardana Additional Accident Self Rate',
  VARDANA_CRITICAL_ILLNESS_SELF_RATE = 'Jeevitha Vardana Critical illness Self Rate',
  VARDANA_HOSPITAL_PER_DAY_SELF_RATE = 'Jeevitha Vardana Hospital Per Day Self Rate',
  VARDANA_HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Jeevitha Vardana Hospital Reimbursement Self Rate',
  VARDANA_FAMILY_INCOME_SELF_RATE = 'Jeevitha Vardana Family Income Self Rate',
  VARDANA_FUNERAL_EXPENSES_SELF_RATE = 'Jeevitha Vardana Funeral Expenses Self Rate',

  VARDANA_BASIC_SPOUSE_RATE = 'Jeevitha Vardana Basic Spouse Rate',
  VARDANA_ADDITIONAL_SPOUSE_RATE = 'Jeevitha Vardana Additional Spouse Rate',
  VARDANA_ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Jeevitha Vardana Additional Accident Spouse Rate',
  VARDANA_CRITICAL_ILLNESS_SPOUSE_RATE = 'Jeevitha Vardana Critical illness Spouse Rate',
  VARDANA_HOSPITAL_PER_DAY_SPOUSE_RATE = 'Jeevitha Vardana Hospital Per Day Spouse Rate',
  VARDANA_HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Jeevitha Vardana Hospital Reimbursement Spouse Rate',
  VARDANA_FAMILY_INCOME_SPOUSE_RATE = 'Jeevitha Vardana Family Income Spouse Rate',
  VARDANA_FUNERAL_EXPENSES_SPOUSE_RATE = 'Jeevitha Vardana Funeral Expenses Spouse Rate',

  VARDANA_BASIC_DEPENDENTS_RATE = 'Jeevitha Vardana Basic Dependents Rate',
  VARDANA_ADDITIONAL_DEPENDENTS_RATE = 'Jeevitha Vardana Additional Dependents Rate',
  VARDANA_ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Jeevitha Vardana Additional Accident Dependents Rate',
  VARDANA_CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Jeevitha Vardana Critical illness Dependents Rate',
  VARDANA_HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Jeevitha Vardana Hospital Per Day Dependents Rate',
  VARDANA_HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Jeevitha Vardana Hospital Reimbursement Dependents Rate',
  VARDANA_FAMILY_INCOME_DEPENDENTS_RATE = 'Jeevitha Vardana Family Income Dependents Rate',
  VARDANA_FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Jeevitha Vardana Funeral Expenses Dependents Rate',

  //Shilpashakthi
  SHILPASHAKTHI_BASIC_SELF_MIN_ASSURED = 'Shilpashakthi Basic Self Assured Min',
  SHILPASHAKTHI_BASIC_SELF_MAX_ASSURED = 'Shilpashakthi Basic Self Assured Max',
  SHILPASHAKTHI_ADDITIONAL_SELF_MIN_ASSURED = 'Shilpashakthi Additional Self Assured Min',
  SHILPASHAKTHI_ADDITIONAL_SELF_MAX_ASSURED = 'Shilpashakthi Additional Self Assured Max',
  SHILPASHAKTHI_ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Shilpashakthi Additional Accident Self Assured Min',
  SHILPASHAKTHI_ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Shilpashakthi Additional Accident Self Assured Max',
  SHILPASHAKTHI_CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Shilpashakthi Critical illness Self Assured Min',
  SHILPASHAKTHI_CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Shilpashakthi Critical illness Self Assured Max',
  SHILPASHAKTHI_HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Shilpashakthi Hospital Per Day Self Assured Min',
  SHILPASHAKTHI_HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Shilpashakthi Hospital Per Day Self Assured Max',
  SHILPASHAKTHI_HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Shilpashakthi Hospital Reimbursement Self Assured Min',
  SHILPASHAKTHI_HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Shilpashakthi Hospital Reimbursement Self Assured Max',
  SHILPASHAKTHI_FAMILY_INCOME_SELF_MIN_ASSURED = 'Shilpashakthi Family Income Self Assured Min',
  SHILPASHAKTHI_FAMILY_INCOME_SELF_MAX_ASSURED = 'Shilpashakthi Family Income Self Assured Max',
  SHILPASHAKTHI_FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Shilpashakthi Funeral Expenses Self Assured Min',
  SHILPASHAKTHI_FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Shilpashakthi Funeral Expenses Self Assured Max',

  SHILPASHAKTHI_BASIC_SPOUSE_MIN_ASSURED = 'Shilpashakthi Basic Spouse Assured Min',
  SHILPASHAKTHI_BASIC_SPOUSE_MAX_ASSURED = 'Shilpashakthi Basic Spouse Assured Max',
  SHILPASHAKTHI_ADDITIONAL_SPOUSE_MIN_ASSURED = 'Shilpashakthi Additional Spouse Assured Min',
  SHILPASHAKTHI_ADDITIONAL_SPOUSE_MAX_ASSURED = 'Shilpashakthi Additional Spouse Assured Max',
  SHILPASHAKTHI_ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Shilpashakthi Additional Accident Spouse Assured Min',
  SHILPASHAKTHI_ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Shilpashakthi Additional Accident Spouse Assured Max',
  SHILPASHAKTHI_CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Shilpashakthi Critical illness Spouse Assured Min',
  SHILPASHAKTHI_CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Shilpashakthi Critical illness Spouse Assured Max',
  SHILPASHAKTHI_HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Shilpashakthi Hospital Per Day Spouse Assured Min',
  SHILPASHAKTHI_HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Shilpashakthi Hospital Per Day Spouse Assured Max',
  SHILPASHAKTHI_HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Shilpashakthi Hospital Reimbursement Spouse Assured Min',
  SHILPASHAKTHI_HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Shilpashakthi Hospital Reimbursement Spouse Assured Max',
  SHILPASHAKTHI_FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Shilpashakthi Family Income Spouse Assured Min',
  SHILPASHAKTHI_FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Shilpashakthi Family Income Spouse Assured Max',
  SHILPASHAKTHI_FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Shilpashakthi Funeral Expenses Spouse Assured Min',
  SHILPASHAKTHI_FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Shilpashakthi Funeral Expenses Spouse Assured Max',

  SHILPASHAKTHI_BASIC_DEPENDENTS_MIN_ASSURED = 'Shilpashakthi Basic Dependents Assured Min',
  SHILPASHAKTHI_BASIC_DEPENDENTS_MAX_ASSURED = 'Shilpashakthi Basic Dependents Assured Max',
  SHILPASHAKTHI_ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Shilpashakthi Additional Dependents Assured Min',
  SHILPASHAKTHI_ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Shilpashakthi Additional Dependents Assured Max',
  SHILPASHAKTHI_ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Shilpashakthi Additional Accident Dependents Assured Min',
  SHILPASHAKTHI_ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Shilpashakthi Additional Accident Dependents Assured Max',
  SHILPASHAKTHI_CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Shilpashakthi Critical illness Dependents Assured Min',
  SHILPASHAKTHI_CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Shilpashakthi Critical illness Dependents Assured Max',
  SHILPASHAKTHI_HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Shilpashakthi Hospital Per Day Dependents Assured Min',
  SHILPASHAKTHI_HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Shilpashakthi Hospital Per Day Dependents Assured Max',
  SHILPASHAKTHI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Shilpashakthi Hospital Reimbursement Dependents Assured Min',
  SHILPASHAKTHI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Shilpashakthi Hospital Reimbursement Dependents Assured Max',
  SHILPASHAKTHI_FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Shilpashakthi Family Income Dependents Assured Min',
  SHILPASHAKTHI_FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Shilpashakthi Family Income Dependents Assured Max',
  SHILPASHAKTHI_FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Shilpashakthi Funeral Expenses Dependents Assured Min',
  SHILPASHAKTHI_FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Shilpashakthi Funeral Expenses Dependents Assured Max',

  SHILPASHAKTHI_YEARLY_MIN_MODE = 'Shilpashakthi Min Yearly Mode',
  SHILPASHAKTHI_HALF_YEARLY_MIN_MODE = 'Shilpashakthi Min Half Yearly Mode',
  SHILPASHAKTHI_QUARTERLY_MIN_MODE = 'Shilpashakthi Min Quarterly Mode',
  SHILPASHAKTHI_MONTHLY_MIN_MODE = 'Shilpashakthi Min Month Mode',

  SHILPASHAKTHI_BASIC_MULTIPLE = 'Shilpashakthi Basic Multiple',
  SHILPASHAKTHI_ADDITIONAL_MULTIPLE = 'Shilpashakthi Additional Multiple',
  SHILPASHAKTHI_ADDITIONAL_ACCIDENT_MULTIPLE = 'Shilpashakthi Additional Accident Multiple',
  SHILPASHAKTHI_CRITICAL_ILLNESS_MULTIPLE = 'Shilpashakthi Critical illness Multiple',
  SHILPASHAKTHI_HOSPITAL_PER_DAY_MULTIPLE = 'Shilpashakthi Hospital Per Day Multiple',
  SHILPASHAKTHI_HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Shilpashakthi Hospital Reimbursement Multiple',
  SHILPASHAKTHI_FAMILY_INCOME_MULTIPLE = 'Shilpashakthi Family Income Multiple',
  SHILPASHAKTHI_FUNERAL_EXPENSES_MULTIPLE = 'Shilpashakthi Funeral Expenses Multiple',

  SHILPASHAKTHI_BASIC_SELF_RATE = 'Shilpashakthi Basic Self Rate',
  SHILPASHAKTHI_ADDITIONAL_SELF_RATE = 'Shilpashakthi Additional Self Rate',
  SHILPASHAKTHI_ADDITIONAL_ACCIDENT_SELF_RATE = 'Shilpashakthi Additional Accident Self Rate',
  SHILPASHAKTHI_CRITICAL_ILLNESS_SELF_RATE = 'Shilpashakthi Critical illness Self Rate',
  SHILPASHAKTHI_HOSPITAL_PER_DAY_SELF_RATE = 'Shilpashakthi Hospital Per Day Self Rate',
  SHILPASHAKTHI_HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Shilpashakthi Hospital Reimbursement Self Rate',
  SHILPASHAKTHI_FAMILY_INCOME_SELF_RATE = 'Shilpashakthi Family Income Self Rate',
  SHILPASHAKTHI_FUNERAL_EXPENSES_SELF_RATE = 'Shilpashakthi Funeral Expenses Self Rate',

  SHILPASHAKTHI_BASIC_SPOUSE_RATE = 'Shilpashakthi Basic Spouse Rate',
  SHILPASHAKTHI_ADDITIONAL_SPOUSE_RATE = 'Shilpashakthi Additional Spouse Rate',
  SHILPASHAKTHI_ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Shilpashakthi Additional Accident Spouse Rate',
  SHILPASHAKTHI_CRITICAL_ILLNESS_SPOUSE_RATE = 'Shilpashakthi Critical illness Spouse Rate',
  SHILPASHAKTHI_HOSPITAL_PER_DAY_SPOUSE_RATE = 'Shilpashakthi Hospital Per Day Spouse Rate',
  SHILPASHAKTHI_HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Shilpashakthi Hospital Reimbursement Spouse Rate',
  SHILPASHAKTHI_FAMILY_INCOME_SPOUSE_RATE = 'Shilpashakthi Family Income Spouse Rate',
  SHILPASHAKTHI_FUNERAL_EXPENSES_SPOUSE_RATE = 'Shilpashakthi Funeral Expenses Spouse Rate',

  SHILPASHAKTHI_BASIC_DEPENDENTS_RATE = 'Shilpashakthi Basic Dependents Rate',
  SHILPASHAKTHI_ADDITIONAL_DEPENDENTS_RATE = 'Shilpashakthi Additional Dependents Rate',
  SHILPASHAKTHI_ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Shilpashakthi Additional Accident Dependents Rate',
  SHILPASHAKTHI_CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Shilpashakthi Critical illness Dependents Rate',
  SHILPASHAKTHI_HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Shilpashakthi Hospital Per Day Dependents Rate',
  SHILPASHAKTHI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Shilpashakthi Hospital Reimbursement Dependents Rate',
  SHILPASHAKTHI_FAMILY_INCOME_DEPENDENTS_RATE = 'Shilpashakthi Family Income Dependents Rate',
  SHILPASHAKTHI_FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Shilpashakthi Funeral Expenses Dependents Rate',

  //Swarnashakthi
  SWARNASHAKTHI_BASIC_SELF_MIN_ASSURED = 'Swarnashakthi Basic Self Assured Min',
  SWARNASHAKTHI_BASIC_SELF_MAX_ASSURED = 'Swarnashakthi Basic Self Assured Max',
  SWARNASHAKTHI_ADDITIONAL_SELF_MIN_ASSURED = 'Swarnashakthi Additional Self Assured Min',
  SWARNASHAKTHI_ADDITIONAL_SELF_MAX_ASSURED = 'Swarnashakthi Additional Self Assured Max',
  SWARNASHAKTHI_ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Swarnashakthi Additional Accident Self Assured Min',
  SWARNASHAKTHI_ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Swarnashakthi Additional Accident Self Assured Max',
  SWARNASHAKTHI_CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Swarnashakthi Critical illness Self Assured Min',
  SWARNASHAKTHI_CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Swarnashakthi Critical illness Self Assured Max',
  SWARNASHAKTHI_HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Swarnashakthi Hospital Per Day Self Assured Min',
  SWARNASHAKTHI_HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Swarnashakthi Hospital Per Day Self Assured Max',
  SWARNASHAKTHI_HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Swarnashakthi Hospital Reimbursement Self Assured Min',
  SWARNASHAKTHI_HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Swarnashakthi Hospital Reimbursement Self Assured Max',
  SWARNASHAKTHI_FAMILY_INCOME_SELF_MIN_ASSURED = 'Swarnashakthi Family Income Self Assured Min',
  SWARNASHAKTHI_FAMILY_INCOME_SELF_MAX_ASSURED = 'Swarnashakthi Family Income Self Assured Max',
  SWARNASHAKTHI_FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Swarnashakthi Funeral Expenses Self Assured Min',
  SWARNASHAKTHI_FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Swarnashakthi Funeral Expenses Self Assured Max',

  SWARNASHAKTHI_BASIC_SPOUSE_MIN_ASSURED = 'Swarnashakthi Basic Spouse Assured Min',
  SWARNASHAKTHI_BASIC_SPOUSE_MAX_ASSURED = 'Swarnashakthi Basic Spouse Assured Max',
  SWARNASHAKTHI_ADDITIONAL_SPOUSE_MIN_ASSURED = 'Swarnashakthi Additional Spouse Assured Min',
  SWARNASHAKTHI_ADDITIONAL_SPOUSE_MAX_ASSURED = 'Swarnashakthi Additional Spouse Assured Max',
  SWARNASHAKTHI_ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Swarnashakthi Additional Accident Spouse Assured Min',
  SWARNASHAKTHI_ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Swarnashakthi Additional Accident Spouse Assured Max',
  SWARNASHAKTHI_CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Swarnashakthi Critical illness Spouse Assured Min',
  SWARNASHAKTHI_CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Swarnashakthi Critical illness Spouse Assured Max',
  SWARNASHAKTHI_HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Swarnashakthi Hospital Per Day Spouse Assured Min',
  SWARNASHAKTHI_HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Swarnashakthi Hospital Per Day Spouse Assured Max',
  SWARNASHAKTHI_HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Swarnashakthi Hospital Reimbursement Spouse Assured Min',
  SWARNASHAKTHI_HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Swarnashakthi Hospital Reimbursement Spouse Assured Max',
  SWARNASHAKTHI_FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Swarnashakthi Family Income Spouse Assured Min',
  SWARNASHAKTHI_FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Swarnashakthi Family Income Spouse Assured Max',
  SWARNASHAKTHI_FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Swarnashakthi Funeral Expenses Spouse Assured Min',
  SWARNASHAKTHI_FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Swarnashakthi Funeral Expenses Spouse Assured Max',

  SWARNASHAKTHI_BASIC_DEPENDENTS_MIN_ASSURED = 'Swarnashakthi Basic Dependents Assured Min',
  SWARNASHAKTHI_BASIC_DEPENDENTS_MAX_ASSURED = 'Swarnashakthi Basic Dependents Assured Max',
  SWARNASHAKTHI_ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Swarnashakthi Additional Dependents Assured Min',
  SWARNASHAKTHI_ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Swarnashakthi Additional Dependents Assured Max',
  SWARNASHAKTHI_ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Swarnashakthi Additional Accident Dependents Assured Min',
  SWARNASHAKTHI_ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Swarnashakthi Additional Accident Dependents Assured Max',
  SWARNASHAKTHI_CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Swarnashakthi Critical illness Dependents Assured Min',
  SWARNASHAKTHI_CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Swarnashakthi Critical illness Dependents Assured Max',
  SWARNASHAKTHI_HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Swarnashakthi Hospital Per Day Dependents Assured Min',
  SWARNASHAKTHI_HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Swarnashakthi Hospital Per Day Dependents Assured Max',
  SWARNASHAKTHI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Swarnashakthi Hospital Reimbursement Dependents Assured Min',
  SWARNASHAKTHI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Swarnashakthi Hospital Reimbursement Dependents Assured Max',
  SWARNASHAKTHI_FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Swarnashakthi Family Income Dependents Assured Min',
  SWARNASHAKTHI_FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Swarnashakthi Family Income Dependents Assured Max',
  SWARNASHAKTHI_FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Swarnashakthi Funeral Expenses Dependents Assured Min',
  SWARNASHAKTHI_FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Swarnashakthi Funeral Expenses Dependents Assured Max',

  SWARNASHAKTHI_YEARLY_MIN_MODE = 'Swarnashakthi Min Yearly Mode',
  SWARNASHAKTHI_HALF_YEARLY_MIN_MODE = 'Swarnashakthi Min Half Yearly Mode',
  SWARNASHAKTHI_QUARTERLY_MIN_MODE = 'Swarnashakthi Min Quarterly Mode',
  SWARNASHAKTHI_MONTHLY_MIN_MODE = 'Swarnashakthi Min Month Mode',

  SWARNASHAKTHI_BASIC_MULTIPLE = 'Swarnashakthi Basic Multiple',
  SWARNASHAKTHI_ADDITIONAL_MULTIPLE = 'Swarnashakthi Additional Multiple',
  SWARNASHAKTHI_ADDITIONAL_ACCIDENT_MULTIPLE = 'Swarnashakthi Additional Accident Multiple',
  SWARNASHAKTHI_CRITICAL_ILLNESS_MULTIPLE = 'Swarnashakthi Critical illness Multiple',
  SWARNASHAKTHI_HOSPITAL_PER_DAY_MULTIPLE = 'Swarnashakthi Hospital Per Day Multiple',
  SWARNASHAKTHI_HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Swarnashakthi Hospital Reimbursement Multiple',
  SWARNASHAKTHI_FAMILY_INCOME_MULTIPLE = 'Swarnashakthi Family Income Multiple',
  SWARNASHAKTHI_FUNERAL_EXPENSES_MULTIPLE = 'Swarnashakthi Funeral Expenses Multiple',

  SWARNASHAKTHI_BASIC_SELF_RATE = 'Swarnashakthi Basic Self Rate',
  SWARNASHAKTHI_ADDITIONAL_SELF_RATE = 'Swarnashakthi Additional Self Rate',
  SWARNASHAKTHI_ADDITIONAL_ACCIDENT_SELF_RATE = 'Swarnashakthi Additional Accident Self Rate',
  SWARNASHAKTHI_CRITICAL_ILLNESS_SELF_RATE = 'Swarnashakthi Critical illness Self Rate',
  SWARNASHAKTHI_HOSPITAL_PER_DAY_SELF_RATE = 'Swarnashakthi Hospital Per Day Self Rate',
  SWARNASHAKTHI_HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Swarnashakthi Hospital Reimbursement Self Rate',
  SWARNASHAKTHI_FAMILY_INCOME_SELF_RATE = 'Swarnashakthi Family Income Self Rate',
  SWARNASHAKTHI_FUNERAL_EXPENSES_SELF_RATE = 'Swarnashakthi Funeral Expenses Self Rate',

  SWARNASHAKTHI_BASIC_SPOUSE_RATE = 'Swarnashakthi Basic Spouse Rate',
  SWARNASHAKTHI_ADDITIONAL_SPOUSE_RATE = 'Swarnashakthi Additional Spouse Rate',
  SWARNASHAKTHI_ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Swarnashakthi Additional Accident Spouse Rate',
  SWARNASHAKTHI_CRITICAL_ILLNESS_SPOUSE_RATE = 'Swarnashakthi Critical illness Spouse Rate',
  SWARNASHAKTHI_HOSPITAL_PER_DAY_SPOUSE_RATE = 'Swarnashakthi Hospital Per Day Spouse Rate',
  SWARNASHAKTHI_HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Swarnashakthi Hospital Reimbursement Spouse Rate',
  SWARNASHAKTHI_FAMILY_INCOME_SPOUSE_RATE = 'Swarnashakthi Family Income Spouse Rate',
  SWARNASHAKTHI_FUNERAL_EXPENSES_SPOUSE_RATE = 'Swarnashakthi Funeral Expenses Spouse Rate',

  SWARNASHAKTHI_BASIC_DEPENDENTS_RATE = 'Swarnashakthi Basic Dependents Rate',
  SWARNASHAKTHI_ADDITIONAL_DEPENDENTS_RATE = 'Swarnashakthi Additional Dependents Rate',
  SWARNASHAKTHI_ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Swarnashakthi Additional Accident Dependents Rate',
  SWARNASHAKTHI_CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Swarnashakthi Critical illness Dependents Rate',
  SWARNASHAKTHI_HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Swarnashakthi Hospital Per Day Dependents Rate',
  SWARNASHAKTHI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Swarnashakthi Hospital Reimbursement Dependents Rate',
  SWARNASHAKTHI_FAMILY_INCOME_DEPENDENTS_RATE = 'Swarnashakthi Family Income Dependents Rate',
  SWARNASHAKTHI_FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Swarnashakthi Funeral Expenses Dependents Rate',

  //Family Plus
  FAMILY_PLUS_BASIC_SELF_MIN_ASSURED = 'Family Plus Basic Self Assured Min',
  FAMILY_PLUS_BASIC_SELF_MAX_ASSURED = 'Family Plus Basic Self Assured Max',
  FAMILY_PLUS_ADDITIONAL_SELF_MIN_ASSURED = 'Family Plus Additional Self Assured Min',
  FAMILY_PLUS_ADDITIONAL_SELF_MAX_ASSURED = 'Family Plus Additional Self Assured Max',
  FAMILY_PLUS_ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Family Plus Additional Accident Self Assured Min',
  FAMILY_PLUS_ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Family Plus Additional Accident Self Assured Max',
  FAMILY_PLUS_CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Family Plus Critical illness Self Assured Min',
  FAMILY_PLUS_CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Family Plus Critical illness Self Assured Max',
  FAMILY_PLUS_HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Family Plus Hospital Per Day Self Assured Min',
  FAMILY_PLUS_HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Family Plus Hospital Per Day Self Assured Max',
  FAMILY_PLUS_HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Family Plus Hospital Reimbursement Self Assured Min',
  FAMILY_PLUS_HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Family Plus Hospital Reimbursement Self Assured Max',
  FAMILY_PLUS_FAMILY_INCOME_SELF_MIN_ASSURED = 'Family Plus Family Income Self Assured Min',
  FAMILY_PLUS_FAMILY_INCOME_SELF_MAX_ASSURED = 'Family Plus Family Income Self Assured Max',
  FAMILY_PLUS_FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Family Plus Funeral Expenses Self Assured Min',
  FAMILY_PLUS_FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Family Plus Funeral Expenses Self Assured Max',

  FAMILY_PLUS_BASIC_SPOUSE_MIN_ASSURED = 'Family Plus Basic Spouse Assured Min',
  FAMILY_PLUS_BASIC_SPOUSE_MAX_ASSURED = 'Family Plus Basic Spouse Assured Max',
  FAMILY_PLUS_ADDITIONAL_SPOUSE_MIN_ASSURED = 'Family Plus Additional Spouse Assured Min',
  FAMILY_PLUS_ADDITIONAL_SPOUSE_MAX_ASSURED = 'Family Plus Additional Spouse Assured Max',
  FAMILY_PLUS_ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Family Plus Additional Accident Spouse Assured Min',
  FAMILY_PLUS_ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Family Plus Additional Accident Spouse Assured Max',
  FAMILY_PLUS_CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Family Plus Critical illness Spouse Assured Min',
  FAMILY_PLUS_CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Family Plus Critical illness Spouse Assured Max',
  FAMILY_PLUS_HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Family Plus Hospital Per Day Spouse Assured Min',
  FAMILY_PLUS_HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Family Plus Hospital Per Day Spouse Assured Max',
  FAMILY_PLUS_HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Family Plus Hospital Reimbursement Spouse Assured Min',
  FAMILY_PLUS_HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Family Plus Hospital Reimbursement Spouse Assured Max',
  FAMILY_PLUS_FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Family Plus Family Income Spouse Assured Min',
  FAMILY_PLUS_FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Family Plus Family Income Spouse Assured Max',
  FAMILY_PLUS_FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Family Plus Funeral Expenses Spouse Assured Min',
  FAMILY_PLUS_FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Family Plus Funeral Expenses Spouse Assured Max',

  FAMILY_PLUS_BASIC_DEPENDENTS_MIN_ASSURED = 'Family Plus Basic Dependents Assured Min',
  FAMILY_PLUS_BASIC_DEPENDENTS_MAX_ASSURED = 'Family Plus Basic Dependents Assured Max',
  FAMILY_PLUS_ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Family Plus Additional Dependents Assured Min',
  FAMILY_PLUS_ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Family Plus Additional Dependents Assured Max',
  FAMILY_PLUS_ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Family Plus Additional Accident Dependents Assured Min',
  FAMILY_PLUS_ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Family Plus Additional Accident Dependents Assured Max',
  FAMILY_PLUS_CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Family Plus Critical illness Dependents Assured Min',
  FAMILY_PLUS_CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Family Plus Critical illness Dependents Assured Max',
  FAMILY_PLUS_HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Family Plus Hospital Per Day Dependents Assured Min',
  FAMILY_PLUS_HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Family Plus Hospital Per Day Dependents Assured Max',
  FAMILY_PLUS_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Family Plus Hospital Reimbursement Dependents Assured Min',
  FAMILY_PLUS_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Family Plus Hospital Reimbursement Dependents Assured Max',
  FAMILY_PLUS_FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Family Plus Family Income Dependents Assured Min',
  FAMILY_PLUS_FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Family Plus Family Income Dependents Assured Max',
  FAMILY_PLUS_FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Family Plus Funeral Expenses Dependents Assured Min',
  FAMILY_PLUS_FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Family Plus Funeral Expenses Dependents Assured Max',

  FAMILY_PLUS_YEARLY_MIN_MODE = 'Family Plus Min Yearly Mode',
  FAMILY_PLUS_HALF_YEARLY_MIN_MODE = 'Family Plus Min Half Yearly Mode',
  FAMILY_PLUS_QUARTERLY_MIN_MODE = 'Family Plus Min Quarterly Mode',
  FAMILY_PLUS_MONTHLY_MIN_MODE = 'Family Plus Min Month Mode',

  FAMILY_PLUS_BASIC_MULTIPLE = 'Family Plus Basic Multiple',
  FAMILY_PLUS_ADDITIONAL_MULTIPLE = 'Family Plus Additional Multiple',
  FAMILY_PLUS_ADDITIONAL_ACCIDENT_MULTIPLE = 'Family Plus Additional Accident Multiple',
  FAMILY_PLUS_CRITICAL_ILLNESS_MULTIPLE = 'Family Plus Critical illness Multiple',
  FAMILY_PLUS_HOSPITAL_PER_DAY_MULTIPLE = 'Family Plus Hospital Per Day Multiple',
  FAMILY_PLUS_HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Family Plus Hospital Reimbursement Multiple',
  FAMILY_PLUS_FAMILY_INCOME_MULTIPLE = 'Family Plus Family Income Multiple',
  FAMILY_PLUS_FUNERAL_EXPENSES_MULTIPLE = 'Family Plus Funeral Expenses Multiple',

  FAMILY_PLUS_BASIC_SELF_RATE = 'Family Plus Basic Self Rate',
  FAMILY_PLUS_ADDITIONAL_SELF_RATE = 'Family Plus Additional Self Rate',
  FAMILY_PLUS_ADDITIONAL_ACCIDENT_SELF_RATE = 'Family Plus Additional Accident Self Rate',
  FAMILY_PLUS_CRITICAL_ILLNESS_SELF_RATE = 'Family Plus Critical illness Self Rate',
  FAMILY_PLUS_HOSPITAL_PER_DAY_SELF_RATE = 'Family Plus Hospital Per Day Self Rate',
  FAMILY_PLUS_HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Family Plus Hospital Reimbursement Self Rate',
  FAMILY_PLUS_FAMILY_INCOME_SELF_RATE = 'Family Plus Family Income Self Rate',
  FAMILY_PLUS_FUNERAL_EXPENSES_SELF_RATE = 'Family Plus Funeral Expenses Self Rate',

  FAMILY_PLUS_BASIC_SPOUSE_RATE = 'Family Plus Basic Spouse Rate',
  FAMILY_PLUS_ADDITIONAL_SPOUSE_RATE = 'Family Plus Additional Spouse Rate',
  FAMILY_PLUS_ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Family Plus Additional Accident Spouse Rate',
  FAMILY_PLUS_CRITICAL_ILLNESS_SPOUSE_RATE = 'Family Plus Critical illness Spouse Rate',
  FAMILY_PLUS_HOSPITAL_PER_DAY_SPOUSE_RATE = 'Family Plus Hospital Per Day Spouse Rate',
  FAMILY_PLUS_HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Family Plus Hospital Reimbursement Spouse Rate',
  FAMILY_PLUS_FAMILY_INCOME_SPOUSE_RATE = 'Family Plus Family Income Spouse Rate',
  FAMILY_PLUS_FUNERAL_EXPENSES_SPOUSE_RATE = 'Family Plus Funeral Expenses Spouse Rate',

  FAMILY_PLUS_BASIC_DEPENDENTS_RATE = 'Family Plus Basic Dependents Rate',
  FAMILY_PLUS_ADDITIONAL_DEPENDENTS_RATE = 'Family Plus Additional Dependents Rate',
  FAMILY_PLUS_ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Family Plus Additional Accident Dependents Rate',
  FAMILY_PLUS_CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Family Plus Critical illness Dependents Rate',
  FAMILY_PLUS_HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Family Plus Hospital Per Day Dependents Rate',
  FAMILY_PLUS_HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Family Plus Hospital Reimbursement Dependents Rate',
  FAMILY_PLUS_FAMILY_INCOME_DEPENDENTS_RATE = 'Family Plus Family Income Dependents Rate',
  FAMILY_PLUS_FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Family Plus Funeral Expenses Dependents Rate',

  //Life Investment Flexi
  FLEXI_BASIC_SELF_MIN_ASSURED = 'Life Investment Flexi Basic Self Assured Min',
  FLEXI_BASIC_SELF_MAX_ASSURED = 'Life Investment Flexi Basic Self Assured Max',
  FLEXI_ADDITIONAL_SELF_MIN_ASSURED = 'Life Investment Flexi Additional Self Assured Min',
  FLEXI_ADDITIONAL_SELF_MAX_ASSURED = 'Life Investment Flexi Additional Self Assured Max',
  FLEXI_ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Life Investment Flexi Additional Accident Self Assured Min',
  FLEXI_ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Life Investment Flexi Additional Accident Self Assured Max',
  FLEXI_CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Life Investment Flexi Critical illness Self Assured Min',
  FLEXI_CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Life Investment Flexi Critical illness Self Assured Max',
  FLEXI_HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Life Investment Flexi Hospital Per Day Self Assured Min',
  FLEXI_HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Life Investment Flexi Hospital Per Day Self Assured Max',
  FLEXI_HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Life Investment Flexi Hospital Reimbursement Self Assured Min',
  FLEXI_HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Life Investment Flexi Hospital Reimbursement Self Assured Max',
  FLEXI_FAMILY_INCOME_SELF_MIN_ASSURED = 'Life Investment Flexi Family Income Self Assured Min',
  FLEXI_FAMILY_INCOME_SELF_MAX_ASSURED = 'Life Investment Flexi Family Income Self Assured Max',
  FLEXI_FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Life Investment Flexi Funeral Expenses Self Assured Min',
  FLEXI_FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Life Investment Flexi Funeral Expenses Self Assured Max',

  FLEXI_BASIC_SPOUSE_MIN_ASSURED = 'Life Investment Flexi Basic Spouse Assured Min',
  FLEXI_BASIC_SPOUSE_MAX_ASSURED = 'Life Investment Flexi Basic Spouse Assured Max',
  FLEXI_ADDITIONAL_SPOUSE_MIN_ASSURED = 'Life Investment Flexi Additional Spouse Assured Min',
  FLEXI_ADDITIONAL_SPOUSE_MAX_ASSURED = 'Life Investment Flexi Additional Spouse Assured Max',
  FLEXI_ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Life Investment Flexi Additional Accident Spouse Assured Min',
  FLEXI_ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Life Investment Flexi Additional Accident Spouse Assured Max',
  FLEXI_CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Life Investment Flexi Critical illness Spouse Assured Min',
  FLEXI_CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Life Investment Flexi Critical illness Spouse Assured Max',
  FLEXI_HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Life Investment Flexi Hospital Per Day Spouse Assured Min',
  FLEXI_HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Life Investment Flexi Hospital Per Day Spouse Assured Max',
  FLEXI_HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Life Investment Flexi Hospital Reimbursement Spouse Assured Min',
  FLEXI_HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Life Investment Flexi Hospital Reimbursement Spouse Assured Max',
  FLEXI_FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Life Investment Flexi Family Income Spouse Assured Min',
  FLEXI_FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Life Investment Flexi Family Income Spouse Assured Max',
  FLEXI_FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Life Investment Flexi Funeral Expenses Spouse Assured Min',
  FLEXI_FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Life Investment Flexi Funeral Expenses Spouse Assured Max',

  FLEXI_BASIC_DEPENDENTS_MIN_ASSURED = 'Life Investment Flexi Basic Dependents Assured Min',
  FLEXI_BASIC_DEPENDENTS_MAX_ASSURED = 'Life Investment Flexi Basic Dependents Assured Max',
  FLEXI_ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Life Investment Flexi Additional Dependents Assured Min',
  FLEXI_ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Life Investment Flexi Additional Dependents Assured Max',
  FLEXI_ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Life Investment Flexi Additional Accident Dependents Assured Min',
  FLEXI_ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Life Investment Flexi Additional Accident Dependents Assured Max',
  FLEXI_CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Life Investment Flexi Critical illness Dependents Assured Min',
  FLEXI_CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Life Investment Flexi Critical illness Dependents Assured Max',
  FLEXI_HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Life Investment Flexi Hospital Per Day Dependents Assured Min',
  FLEXI_HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Life Investment Flexi Hospital Per Day Dependents Assured Max',
  FLEXI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Life Investment Flexi Hospital Reimbursement Dependents Assured Min',
  FLEXI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Life Investment Flexi Hospital Reimbursement Dependents Assured Max',
  FLEXI_FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Life Investment Flexi Family Income Dependents Assured Min',
  FLEXI_FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Life Investment Flexi Family Income Dependents Assured Max',
  FLEXI_FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Life Investment Flexi Funeral Expenses Dependents Assured Min',
  FLEXI_FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Life Investment Flexi Funeral Expenses Dependents Assured Max',

  FLEXI_YEARLY_MIN_MODE = 'Life Investment Flexi Min Yearly Mode',
  FLEXI_HALF_YEARLY_MIN_MODE = 'Life Investment Flexi Min Half Yearly Mode',
  FLEXI_QUARTERLY_MIN_MODE = 'Life Investment Flexi Min Quarterly Mode',
  FLEXI_MONTHLY_MIN_MODE = 'Life Investment Flexi Min Month Mode',

  FLEXI_BASIC_MULTIPLE = 'Life Investment Flexi Basic Multiple',
  FLEXI_ADDITIONAL_MULTIPLE = 'Life Investment Flexi Additional Multiple',
  FLEXI_ADDITIONAL_ACCIDENT_MULTIPLE = 'Life Investment Flexi Additional Accident Multiple',
  FLEXI_CRITICAL_ILLNESS_MULTIPLE = 'Life Investment Flexi Critical illness Multiple',
  FLEXI_HOSPITAL_PER_DAY_MULTIPLE = 'Life Investment Flexi Hospital Per Day Multiple',
  FLEXI_HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Life Investment Flexi Hospital Reimbursement Multiple',
  FLEXI_FAMILY_INCOME_MULTIPLE = 'Life Investment Flexi Family Income Multiple',
  FLEXI_FUNERAL_EXPENSES_MULTIPLE = 'Life Investment Flexi Funeral Expenses Multiple',

  FLEXI_BASIC_SELF_RATE = 'Life Investment Flexi Basic Self Rate',
  FLEXI_ADDITIONAL_SELF_RATE = 'Life Investment Flexi Additional Self Rate',
  FLEXI_ADDITIONAL_ACCIDENT_SELF_RATE = 'Life Investment Flexi Additional Accident Self Rate',
  FLEXI_CRITICAL_ILLNESS_SELF_RATE = 'Life Investment Flexi Critical illness Self Rate',
  FLEXI_HOSPITAL_PER_DAY_SELF_RATE = 'Life Investment Flexi Hospital Per Day Self Rate',
  FLEXI_HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Life Investment Flexi Hospital Reimbursement Self Rate',
  FLEXI_FAMILY_INCOME_SELF_RATE = 'Life Investment Flexi Family Income Self Rate',
  FLEXI_FUNERAL_EXPENSES_SELF_RATE = 'Life Investment Flexi Funeral Expenses Self Rate',

  FLEXI_BASIC_SPOUSE_RATE = 'Life Investment Flexi Basic Spouse Rate',
  FLEXI_ADDITIONAL_SPOUSE_RATE = 'Life Investment Flexi Additional Spouse Rate',
  FLEXI_ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Life Investment Flexi Additional Accident Spouse Rate',
  FLEXI_CRITICAL_ILLNESS_SPOUSE_RATE = 'Life Investment Flexi Critical illness Spouse Rate',
  FLEXI_HOSPITAL_PER_DAY_SPOUSE_RATE = 'Life Investment Flexi Hospital Per Day Spouse Rate',
  FLEXI_HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Life Investment Flexi Hospital Reimbursement Spouse Rate',
  FLEXI_FAMILY_INCOME_SPOUSE_RATE = 'Life Investment Flexi Family Income Spouse Rate',
  FLEXI_FUNERAL_EXPENSES_SPOUSE_RATE = 'Life Investment Flexi Funeral Expenses Spouse Rate',

  FLEXI_BASIC_DEPENDENTS_RATE = 'Life Investment Flexi Basic Dependents Rate',
  FLEXI_ADDITIONAL_DEPENDENTS_RATE = 'Life Investment Flexi Additional Dependents Rate',
  FLEXI_ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Life Investment Flexi Additional Accident Dependents Rate',
  FLEXI_CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Life Investment Flexi Critical illness Dependents Rate',
  FLEXI_HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Life Investment Flexi Hospital Per Day Dependents Rate',
  FLEXI_HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Life Investment Flexi Hospital Reimbursement Dependents Rate',
  FLEXI_FAMILY_INCOME_DEPENDENTS_RATE = 'Life Investment Flexi Family Income Dependents Rate',
  FLEXI_FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Life Investment Flexi Funeral Expenses Dependents Rate',

  //Janashakthi Life Unlimited
  LIFE_UNLIMITED_BASIC_SELF_MIN_ASSURED = 'Life Unlimited Basic Self Assured Min',
  LIFE_UNLIMITED_BASIC_SELF_MAX_ASSURED = 'Life Unlimited Basic Self Assured Max',
  LIFE_UNLIMITED_ADDITIONAL_SELF_MIN_ASSURED = 'Life Unlimited Additional Self Assured Min',
  LIFE_UNLIMITED_ADDITIONAL_SELF_MAX_ASSURED = 'Life Unlimited Additional Self Assured Max',
  LIFE_UNLIMITED_ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Life Unlimited Additional Accident Self Assured Min',
  LIFE_UNLIMITED_ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Life Unlimited Additional Accident Self Assured Max',
  LIFE_UNLIMITED_CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Life Unlimited Critical illness Self Assured Min',
  LIFE_UNLIMITED_CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Life Unlimited Critical illness Self Assured Max',
  LIFE_UNLIMITED_HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Life Unlimited Hospital Per Day Self Assured Min',
  LIFE_UNLIMITED_HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Life Unlimited Hospital Per Day Self Assured Max',
  LIFE_UNLIMITED_HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Life Unlimited Hospital Reimbursement Self Assured Min',
  LIFE_UNLIMITED_HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Life Unlimited Hospital Reimbursement Self Assured Max',
  LIFE_UNLIMITED_FAMILY_INCOME_SELF_MIN_ASSURED = 'Life Unlimited Family Income Self Assured Min',
  LIFE_UNLIMITED_FAMILY_INCOME_SELF_MAX_ASSURED = 'Life Unlimited Family Income Self Assured Max',
  LIFE_UNLIMITED_FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Life Unlimited Funeral Expenses Self Assured Min',
  LIFE_UNLIMITED_FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Life Unlimited Funeral Expenses Self Assured Max',

  LIFE_UNLIMITED_BASIC_SPOUSE_MIN_ASSURED = 'Life Unlimited Basic Spouse Assured Min',
  LIFE_UNLIMITED_BASIC_SPOUSE_MAX_ASSURED = 'Life Unlimited Basic Spouse Assured Max',
  LIFE_UNLIMITED_ADDITIONAL_SPOUSE_MIN_ASSURED = 'Life Unlimited Additional Spouse Assured Min',
  LIFE_UNLIMITED_ADDITIONAL_SPOUSE_MAX_ASSURED = 'Life Unlimited Additional Spouse Assured Max',
  LIFE_UNLIMITED_ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Life Unlimited Additional Accident Spouse Assured Min',
  LIFE_UNLIMITED_ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Life Unlimited Additional Accident Spouse Assured Max',
  LIFE_UNLIMITED_CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Life Unlimited Critical illness Spouse Assured Min',
  LIFE_UNLIMITED_CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Life Unlimited Critical illness Spouse Assured Max',
  LIFE_UNLIMITED_HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Life Unlimited Hospital Per Day Spouse Assured Min',
  LIFE_UNLIMITED_HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Life Unlimited Hospital Per Day Spouse Assured Max',
  LIFE_UNLIMITED_HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Life Unlimited Hospital Reimbursement Spouse Assured Min',
  LIFE_UNLIMITED_HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Life Unlimited Hospital Reimbursement Spouse Assured Max',
  LIFE_UNLIMITED_FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Life Unlimited Family Income Spouse Assured Min',
  LIFE_UNLIMITED_FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Life Unlimited Family Income Spouse Assured Max',
  LIFE_UNLIMITED_FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Life Unlimited Funeral Expenses Spouse Assured Min',
  LIFE_UNLIMITED_FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Life Unlimited Funeral Expenses Spouse Assured Max',

  LIFE_UNLIMITED_BASIC_DEPENDENTS_MIN_ASSURED = 'Life Unlimited Basic Dependents Assured Min',
  LIFE_UNLIMITED_BASIC_DEPENDENTS_MAX_ASSURED = 'Life Unlimited Basic Dependents Assured Max',
  LIFE_UNLIMITED_ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Life Unlimited Additional Dependents Assured Min',
  LIFE_UNLIMITED_ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Life Unlimited Additional Dependents Assured Max',
  LIFE_UNLIMITED_ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Life Unlimited Additional Accident Dependents Assured Min',
  LIFE_UNLIMITED_ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Life Unlimited Additional Accident Dependents Assured Max',
  LIFE_UNLIMITED_CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Life Unlimited Critical illness Dependents Assured Min',
  LIFE_UNLIMITED_CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Life Unlimited Critical illness Dependents Assured Max',
  LIFE_UNLIMITED_HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Life Unlimited Hospital Per Day Dependents Assured Min',
  LIFE_UNLIMITED_HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Life Unlimited Hospital Per Day Dependents Assured Max',
  LIFE_UNLIMITED_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Life Unlimited Hospital Reimbursement Dependents Assured Min',
  LIFE_UNLIMITED_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Life Unlimited Hospital Reimbursement Dependents Assured Max',
  LIFE_UNLIMITED_FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Life Unlimited Family Income Dependents Assured Min',
  LIFE_UNLIMITED_FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Life Unlimited Family Income Dependents Assured Max',
  LIFE_UNLIMITED_FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Life Unlimited Funeral Expenses Dependents Assured Min',
  LIFE_UNLIMITED_FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Life Unlimited Funeral Expenses Dependents Assured Max',

  LIFE_UNLIMITED_YEARLY_MIN_MODE = 'Life Unlimited Min Yearly Mode',
  LIFE_UNLIMITED_HALF_YEARLY_MIN_MODE = 'Life Unlimited Min Half Yearly Mode',
  LIFE_UNLIMITED_QUARTERLY_MIN_MODE = 'Life Unlimited Min Quarterly Mode',
  LIFE_UNLIMITED_MONTHLY_MIN_MODE = 'Life Unlimited Min Month Mode',

  LIFE_UNLIMITED_BASIC_MULTIPLE = 'Life Unlimited Basic Multiple',
  LIFE_UNLIMITED_ADDITIONAL_MULTIPLE = 'Life Unlimited Additional Multiple',
  LIFE_UNLIMITED_ADDITIONAL_ACCIDENT_MULTIPLE = 'Life Unlimited Additional Accident Multiple',
  LIFE_UNLIMITED_CRITICAL_ILLNESS_MULTIPLE = 'Life Unlimited Critical illness Multiple',
  LIFE_UNLIMITED_HOSPITAL_PER_DAY_MULTIPLE = 'Life Unlimited Hospital Per Day Multiple',
  LIFE_UNLIMITED_HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Life Unlimited Hospital Reimbursement Multiple',
  LIFE_UNLIMITED_FAMILY_INCOME_MULTIPLE = 'Life Unlimited Family Income Multiple',
  LIFE_UNLIMITED_FUNERAL_EXPENSES_MULTIPLE = 'Life Unlimited Funeral Expenses Multiple',

  LIFE_UNLIMITED_BASIC_SELF_RATE = 'Life Unlimited Basic Self Rate',
  LIFE_UNLIMITED_ADDITIONAL_SELF_RATE = 'Life Unlimited Additional Self Rate',
  LIFE_UNLIMITED_ADDITIONAL_ACCIDENT_SELF_RATE = 'Life Unlimited Additional Accident Self Rate',
  LIFE_UNLIMITED_CRITICAL_ILLNESS_SELF_RATE = 'Life Unlimited Critical illness Self Rate',
  LIFE_UNLIMITED_HOSPITAL_PER_DAY_SELF_RATE = 'Life Unlimited Hospital Per Day Self Rate',
  LIFE_UNLIMITED_HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Life Unlimited Hospital Reimbursement Self Rate',
  LIFE_UNLIMITED_FAMILY_INCOME_SELF_RATE = 'Life Unlimited Family Income Self Rate',
  LIFE_UNLIMITED_FUNERAL_EXPENSES_SELF_RATE = 'Life Unlimited Funeral Expenses Self Rate',

  LIFE_UNLIMITED_BASIC_SPOUSE_RATE = 'Life Unlimited Basic Spouse Rate',
  LIFE_UNLIMITED_ADDITIONAL_SPOUSE_RATE = 'Life Unlimited Additional Spouse Rate',
  LIFE_UNLIMITED_ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Life Unlimited Additional Accident Spouse Rate',
  LIFE_UNLIMITED_CRITICAL_ILLNESS_SPOUSE_RATE = 'Life Unlimited Critical illness Spouse Rate',
  LIFE_UNLIMITED_HOSPITAL_PER_DAY_SPOUSE_RATE = 'Life Unlimited Hospital Per Day Spouse Rate',
  LIFE_UNLIMITED_HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Life Unlimited Hospital Reimbursement Spouse Rate',
  LIFE_UNLIMITED_FAMILY_INCOME_SPOUSE_RATE = 'Life Unlimited Family Income Spouse Rate',
  LIFE_UNLIMITED_FUNERAL_EXPENSES_SPOUSE_RATE = 'Life Unlimited Funeral Expenses Spouse Rate',

  LIFE_UNLIMITED_BASIC_DEPENDENTS_RATE = 'Life Unlimited Basic Dependents Rate',
  LIFE_UNLIMITED_ADDITIONAL_DEPENDENTS_RATE = 'Life Unlimited Additional Dependents Rate',
  LIFE_UNLIMITED_ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Life Unlimited Additional Accident Dependents Rate',
  LIFE_UNLIMITED_CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Life Unlimited Critical illness Dependents Rate',
  LIFE_UNLIMITED_HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Life Unlimited Hospital Per Day Dependents Rate',
  LIFE_UNLIMITED_HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Life Unlimited Hospital Reimbursement Dependents Rate',
  LIFE_UNLIMITED_FAMILY_INCOME_DEPENDENTS_RATE = 'Life Unlimited Family Income Dependents Rate',
  LIFE_UNLIMITED_FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Life Unlimited Funeral Expenses Dependents Rate',

  //Dream Saver
  // DREAM_SERVER_BASIC_SELF_MIN_ASSURED = 'Dream Saver Basic Self Assured Min',
  // DREAM_SERVER_BASIC_SELF_MAX_ASSURED = 'Dream Saver Basic Self Assured Max',
  // DREAM_SERVER_ADDITIONAL_SELF_MIN_ASSURED = 'Dream Saver Additional Self Assured Min',
  // DREAM_SERVER_ADDITIONAL_SELF_MAX_ASSURED = 'Dream Saver Additional Self Assured Max',
  // DREAM_SERVER_ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Dream Saver Additional Accident Self Assured Min',
  // DREAM_SERVER_ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Dream Saver Additional Accident Self Assured Max',
  // DREAM_SERVER_CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Dream Saver Critical illness Self Assured Min',
  // DREAM_SERVER_CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Dream Saver Critical illness Self Assured Max',
  // DREAM_SERVER_HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Dream Saver Hospital Per Day Self Assured Min',
  // DREAM_SERVER_HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Dream Saver Hospital Per Day Self Assured Max',
  // DREAM_SERVER_HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Dream Saver Hospital Reimbursement Self Assured Min',
  // DREAM_SERVER_HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Dream Saver Hospital Reimbursement Self Assured Max',
  // DREAM_SERVER_FAMILY_INCOME_SELF_MIN_ASSURED = 'Dream Saver Family Income Self Assured Min',
  // DREAM_SERVER_FAMILY_INCOME_SELF_MAX_ASSURED = 'Dream Saver Family Income Self Assured Max',
  // DREAM_SERVER_FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Dream Saver Funeral Expenses Self Assured Min',
  // DREAM_SERVER_FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Dream Saver Funeral Expenses Self Assured Max',

  // DREAM_SERVER_BASIC_SPOUSE_MIN_ASSURED = 'Dream Saver Basic Spouse Assured Min',
  // DREAM_SERVER_BASIC_SPOUSE_MAX_ASSURED = 'Dream Saver Basic Spouse Assured Max',
  // DREAM_SERVER_ADDITIONAL_SPOUSE_MIN_ASSURED = 'Dream Saver Additional Spouse Assured Min',
  // DREAM_SERVER_ADDITIONAL_SPOUSE_MAX_ASSURED = 'Dream Saver Additional Spouse Assured Max',
  // DREAM_SERVER_ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Dream Saver Additional Accident Spouse Assured Min',
  // DREAM_SERVER_ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Dream Saver Additional Accident Spouse Assured Max',
  // DREAM_SERVER_CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Dream Saver Critical illness Spouse Assured Min',
  // DREAM_SERVER_CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Dream Saver Critical illness Spouse Assured Max',
  // DREAM_SERVER_HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Dream Saver Hospital Per Day Spouse Assured Min',
  // DREAM_SERVER_HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Dream Saver Hospital Per Day Spouse Assured Max',
  // DREAM_SERVER_HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Dream Saver Hospital Reimbursement Spouse Assured Min',
  // DREAM_SERVER_HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Dream Saver Hospital Reimbursement Spouse Assured Max',
  // DREAM_SERVER_FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Dream Saver Family Income Spouse Assured Min',
  // DREAM_SERVER_FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Dream Saver Family Income Spouse Assured Max',
  // DREAM_SERVER_FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Dream Saver Funeral Expenses Spouse Assured Min',
  // DREAM_SERVER_FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Dream Saver Funeral Expenses Spouse Assured Max',

  // DREAM_SERVER_BASIC_DEPENDENTS_MIN_ASSURED = 'Dream Saver Basic Dependents Assured Min',
  // DREAM_SERVER_BASIC_DEPENDENTS_MAX_ASSURED = 'Dream Saver Basic Dependents Assured Max',
  // DREAM_SERVER_ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Dream Saver Additional Dependents Assured Min',
  // DREAM_SERVER_ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Dream Saver Additional Dependents Assured Max',
  // DREAM_SERVER_ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Dream Saver Additional Accident Dependents Assured Min',
  // DREAM_SERVER_ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Dream Saver Additional Accident Dependents Assured Max',
  // DREAM_SERVER_CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Dream Saver Critical illness Dependents Assured Min',
  // DREAM_SERVER_CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Dream Saver Critical illness Dependents Assured Max',
  // DREAM_SERVER_HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Dream Saver Hospital Per Day Dependents Assured Min',
  // DREAM_SERVER_HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Dream Saver Hospital Per Day Dependents Assured Max',
  // DREAM_SERVER_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Dream Saver Hospital Reimbursement Dependents Assured Min',
  // DREAM_SERVER_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Dream Saver Hospital Reimbursement Dependents Assured Max',
  // DREAM_SERVER_FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Dream Saver Family Income Dependents Assured Min',
  // DREAM_SERVER_FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Dream Saver Family Income Dependents Assured Max',
  // DREAM_SERVER_FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Dream Saver Funeral Expenses Dependents Assured Min',
  // DREAM_SERVER_FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Dream Saver Funeral Expenses Dependents Assured Max',

  // DREAM_SERVER_YEARLY_MIN_MODE = 'Dream Saver Min Yearly Mode',
  // DREAM_SERVER_HALF_YEARLY_MIN_MODE = 'Dream Saver Min Half Yearly Mode',
  // DREAM_SERVER_QUARTERLY_MIN_MODE = 'Dream Saver Min Quarterly Mode',
  // DREAM_SERVER_MONTHLY_MIN_MODE = 'Dream Saver Min Month Mode',

  // DREAM_SERVER_BASIC_MULTIPLE = 'Dream Saver Basic Multiple',
  // DREAM_SERVER_ADDITIONAL_MULTIPLE = 'Dream Saver Additional Multiple',
  // DREAM_SERVER_ADDITIONAL_ACCIDENT_MULTIPLE = 'Dream Saver Additional Accident Multiple',
  // DREAM_SERVER_CRITICAL_ILLNESS_MULTIPLE = 'Dream Saver Critical illness Multiple',
  // DREAM_SERVER_HOSPITAL_PER_DAY_MULTIPLE = 'Dream Saver Hospital Per Day Multiple',
  // DREAM_SERVER_HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Dream Saver Hospital Reimbursement Multiple',
  // DREAM_SERVER_FAMILY_INCOME_MULTIPLE = 'Dream Saver Family Income Multiple',
  // DREAM_SERVER_FUNERAL_EXPENSES_MULTIPLE = 'Dream Saver Funeral Expenses Multiple',

  // DREAM_SERVER_BASIC_SELF_RATE = 'Dream Saver Basic Self Rate',
  // DREAM_SERVER_ADDITIONAL_SELF_RATE = 'Dream Saver Additional Self Rate',
  // DREAM_SERVER_ADDITIONAL_ACCIDENT_SELF_RATE = 'Dream Saver Additional Accident Self Rate',
  // DREAM_SERVER_CRITICAL_ILLNESS_SELF_RATE = 'Dream Saver Critical illness Self Rate',
  // DREAM_SERVER_HOSPITAL_PER_DAY_SELF_RATE = 'Dream Saver Hospital Per Day Self Rate',
  // DREAM_SERVER_HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Dream Saver Hospital Reimbursement Self Rate',
  // DREAM_SERVER_FAMILY_INCOME_SELF_RATE = 'Dream Saver Family Income Self Rate',
  // DREAM_SERVER_FUNERAL_EXPENSES_SELF_RATE = 'Dream Saver Funeral Expenses Self Rate',

  // DREAM_SERVER_BASIC_SPOUSE_RATE = 'Dream Saver Basic Spouse Rate',
  // DREAM_SERVER_ADDITIONAL_SPOUSE_RATE = 'Dream Saver Additional Spouse Rate',
  // DREAM_SERVER_ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Dream Saver Additional Accident Spouse Rate',
  // DREAM_SERVER_CRITICAL_ILLNESS_SPOUSE_RATE = 'Dream Saver Critical illness Spouse Rate',
  // DREAM_SERVER_HOSPITAL_PER_DAY_SPOUSE_RATE = 'Dream Saver Hospital Per Day Spouse Rate',
  // DREAM_SERVER_HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Dream Saver Hospital Reimbursement Spouse Rate',
  // DREAM_SERVER_FAMILY_INCOME_SPOUSE_RATE = 'Dream Saver Family Income Spouse Rate',
  // DREAM_SERVER_FUNERAL_EXPENSES_SPOUSE_RATE = 'Dream Saver Funeral Expenses Spouse Rate',

  // DREAM_SERVER_BASIC_DEPENDENTS_RATE = 'Dream Saver Basic Dependents Rate',
  // DREAM_SERVER_ADDITIONAL_DEPENDENTS_RATE = 'Dream Saver Additional Dependents Rate',
  // DREAM_SERVER_ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Dream Saver Additional Accident Dependents Rate',
  // DREAM_SERVER_CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Dream Saver Critical illness Dependents Rate',
  // DREAM_SERVER_HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Dream Saver Hospital Per Day Dependents Rate',
  // DREAM_SERVER_HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Dream Saver Hospital Reimbursement Dependents Rate',
  // DREAM_SERVER_FAMILY_INCOME_DEPENDENTS_RATE = 'Dream Saver Family Income Dependents Rate',
  // DREAM_SERVER_FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Dream Saver Funeral Expenses Dependents Rate',

  //Jeevitha Thilina
  THILINA_BASIC_SELF_MIN_ASSURED = 'Jeevitha Thilina Basic Self Assured Min',
  THILINA_BASIC_SELF_MAX_ASSURED = 'Jeevitha Thilina Basic Self Assured Max',
  THILINA_ADDITIONAL_SELF_MIN_ASSURED = 'Jeevitha Thilina Additional Self Assured Min',
  THILINA_ADDITIONAL_SELF_MAX_ASSURED = 'Jeevitha Thilina Additional Self Assured Max',
  THILINA_ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Jeevitha Thilina Additional Accident Self Assured Min',
  THILINA_ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Jeevitha Thilina Additional Accident Self Assured Max',
  THILINA_CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Jeevitha Thilina Critical illness Self Assured Min',
  THILINA_CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Jeevitha Thilina Critical illness Self Assured Max',
  THILINA_HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Jeevitha Thilina Hospital Per Day Self Assured Min',
  THILINA_HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Jeevitha Thilina Hospital Per Day Self Assured Max',
  THILINA_HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Jeevitha Thilina Hospital Reimbursement Self Assured Min',
  THILINA_HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Jeevitha Thilina Hospital Reimbursement Self Assured Max',
  THILINA_FAMILY_INCOME_SELF_MIN_ASSURED = 'Jeevitha Thilina Family Income Self Assured Min',
  THILINA_FAMILY_INCOME_SELF_MAX_ASSURED = 'Jeevitha Thilina Family Income Self Assured Max',
  THILINA_FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Jeevitha Thilina Funeral Expenses Self Assured Min',
  THILINA_FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Jeevitha Thilina Funeral Expenses Self Assured Max',

  THILINA_BASIC_SPOUSE_MIN_ASSURED = 'Jeevitha Thilina Basic Spouse Assured Min',
  THILINA_BASIC_SPOUSE_MAX_ASSURED = 'Jeevitha Thilina Basic Spouse Assured Max',
  THILINA_ADDITIONAL_SPOUSE_MIN_ASSURED = 'Jeevitha Thilina Additional Spouse Assured Min',
  THILINA_ADDITIONAL_SPOUSE_MAX_ASSURED = 'Jeevitha Thilina Additional Spouse Assured Max',
  THILINA_ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Jeevitha Thilina Additional Accident Spouse Assured Min',
  THILINA_ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Jeevitha Thilina Additional Accident Spouse Assured Max',
  THILINA_CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Jeevitha Thilina Critical illness Spouse Assured Min',
  THILINA_CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Jeevitha Thilina Critical illness Spouse Assured Max',
  THILINA_HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Jeevitha Thilina Hospital Per Day Spouse Assured Min',
  THILINA_HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Jeevitha Thilina Hospital Per Day Spouse Assured Max',
  THILINA_HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Jeevitha Thilina Hospital Reimbursement Spouse Assured Min',
  THILINA_HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Jeevitha Thilina Hospital Reimbursement Spouse Assured Max',
  THILINA_FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Jeevitha Thilina Family Income Spouse Assured Min',
  THILINA_FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Jeevitha Thilina Family Income Spouse Assured Max',
  THILINA_FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Jeevitha Thilina Funeral Expenses Spouse Assured Min',
  THILINA_FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Jeevitha Thilina Funeral Expenses Spouse Assured Max',

  THILINA_BASIC_DEPENDENTS_MIN_ASSURED = 'Jeevitha Thilina Basic Dependents Assured Min',
  THILINA_BASIC_DEPENDENTS_MAX_ASSURED = 'Jeevitha Thilina Basic Dependents Assured Max',
  THILINA_ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Jeevitha Thilina Additional Dependents Assured Min',
  THILINA_ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Jeevitha Thilina Additional Dependents Assured Max',
  THILINA_ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Jeevitha Thilina Additional Accident Dependents Assured Min',
  THILINA_ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Jeevitha Thilina Additional Accident Dependents Assured Max',
  THILINA_CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Jeevitha Thilina Critical illness Dependents Assured Min',
  THILINA_CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Jeevitha Thilina Critical illness Dependents Assured Max',
  THILINA_HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Jeevitha Thilina Hospital Per Day Dependents Assured Min',
  THILINA_HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Jeevitha Thilina Hospital Per Day Dependents Assured Max',
  THILINA_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Jeevitha Thilina Hospital Reimbursement Dependents Assured Min',
  THILINA_HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Jeevitha Thilina Hospital Reimbursement Dependents Assured Max',
  THILINA_FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Jeevitha Thilina Family Income Dependents Assured Min',
  THILINA_FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Jeevitha Thilina Family Income Dependents Assured Max',
  THILINA_FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Jeevitha Thilina Funeral Expenses Dependents Assured Min',
  THILINA_FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Jeevitha Thilina Funeral Expenses Dependents Assured Max',

  THILINA_YEARLY_MIN_MODE = 'Jeevitha Thilina Min Yearly Mode',
  THILINA_HALF_YEARLY_MIN_MODE = 'Jeevitha Thilina Min Half Yearly Mode',
  THILINA_QUARTERLY_MIN_MODE = 'Jeevitha Thilina Min Quarterly Mode',
  THILINA_MONTHLY_MIN_MODE = 'Jeevitha Thilina Min Month Mode',

  THILINA_BASIC_MULTIPLE = 'Jeevitha Thilina Basic Multiple',
  THILINA_ADDITIONAL_MULTIPLE = 'Jeevitha Thilina Additional Multiple',
  THILINA_ADDITIONAL_ACCIDENT_MULTIPLE = 'Jeevitha Thilina Additional Accident Multiple',
  THILINA_CRITICAL_ILLNESS_MULTIPLE = 'Jeevitha Thilina Critical illness Multiple',
  THILINA_HOSPITAL_PER_DAY_MULTIPLE = 'Jeevitha Thilina Hospital Per Day Multiple',
  THILINA_HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Jeevitha Thilina Hospital Reimbursement Multiple',
  THILINA_FAMILY_INCOME_MULTIPLE = 'Jeevitha Thilina Family Income Multiple',
  THILINA_FUNERAL_EXPENSES_MULTIPLE = 'Jeevitha Thilina Funeral Expenses Multiple',

  THILINA_BASIC_SELF_RATE = 'Jeevitha Thilina Basic Self Rate',
  THILINA_ADDITIONAL_SELF_RATE = 'Jeevitha Thilina Additional Self Rate',
  THILINA_ADDITIONAL_ACCIDENT_SELF_RATE = 'Jeevitha Thilina Additional Accident Self Rate',
  THILINA_CRITICAL_ILLNESS_SELF_RATE = 'Jeevitha Thilina Critical illness Self Rate',
  THILINA_HOSPITAL_PER_DAY_SELF_RATE = 'Jeevitha Thilina Hospital Per Day Self Rate',
  THILINA_HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Jeevitha Thilina Hospital Reimbursement Self Rate',
  THILINA_FAMILY_INCOME_SELF_RATE = 'Jeevitha Thilina Family Income Self Rate',
  THILINA_FUNERAL_EXPENSES_SELF_RATE = 'Jeevitha Thilina Funeral Expenses Self Rate',

  THILINA_BASIC_SPOUSE_RATE = 'Jeevitha Thilina Basic Spouse Rate',
  THILINA_ADDITIONAL_SPOUSE_RATE = 'Jeevitha Thilina Additional Spouse Rate',
  THILINA_ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Jeevitha Thilina Additional Accident Spouse Rate',
  THILINA_CRITICAL_ILLNESS_SPOUSE_RATE = 'Jeevitha Thilina Critical illness Spouse Rate',
  THILINA_HOSPITAL_PER_DAY_SPOUSE_RATE = 'Jeevitha Thilina Hospital Per Day Spouse Rate',
  THILINA_HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Jeevitha Thilina Hospital Reimbursement Spouse Rate',
  THILINA_FAMILY_INCOME_SPOUSE_RATE = 'Jeevitha Thilina Family Income Spouse Rate',
  THILINA_FUNERAL_EXPENSES_SPOUSE_RATE = 'Jeevitha Thilina Funeral Expenses Spouse Rate',

  THILINA_BASIC_DEPENDENTS_RATE = 'Jeevitha Thilina Basic Dependents Rate',
  THILINA_ADDITIONAL_DEPENDENTS_RATE = 'Jeevitha Thilina Additional Dependents Rate',
  THILINA_ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Jeevitha Thilina Additional Accident Dependents Rate',
  THILINA_CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Jeevitha Thilina Critical illness Dependents Rate',
  THILINA_HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Jeevitha Thilina Hospital Per Day Dependents Rate',
  THILINA_HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Jeevitha Thilina Hospital Reimbursement Dependents Rate',
  THILINA_FAMILY_INCOME_DEPENDENTS_RATE = 'Jeevitha Thilina Family Income Dependents Rate',
  THILINA_FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Jeevitha Thilina Funeral Expenses Dependents Rate',

  //Quotation Report
  PRODUCT_JCA01_TABLE_1 = 'Janashakthi Cash Advance Table 1',
  PRODUCT_NJU4_TABLE_1 = 'Janashakthi Life Unlimited Table 1',
  PRODUCT_JDS_TABLE_1 = 'Janashakthi Dream Saver Table 1',
  PRODUCT_NGT02_TABLE_1 = 'Family Plus Table 1',
  PRODUCT_NGT01_TABLE_1 = 'Jeevitha Vardhana Table 1',
  PRODUCT_NSPS_TABLE_1 = 'Shilpashakthi Table 1',
  PRODUCT_NSW_TABLE_1 = 'Suwashakthi Table 1',
  PRODUCT_LI4_TABLE_1 = 'Janashakthi Investment Table 1',
  PRODUCT_LSP_TABLE_1 = 'Swarnashakthi Table 1',
  PRODUCT_NJEV_TABLE_1 = 'Jeevitha Thilina Table 1',
  PRODUCT_LIF1_TABLE_1 = 'Life Investment Flexi 1 Table 1',

  PRODUCT_JCA01_TABLE_2 = 'Janashakthi Cash Advance Table 2',
  PRODUCT_NJU4_TABLE_2 = 'Janashakthi Life Unlimited Table 2',
  PRODUCT_JDS_TABLE_2 = 'Janashakthi Dream Saver Table 2',
  PRODUCT_NGT02_TABLE_2 = 'Family Plus Table 2',
  PRODUCT_NGT01_TABLE_2 = 'Jeevitha Vardhana Table 2',
  PRODUCT_NSPS_TABLE_2 = 'Shilpashakthi Table 2',
  PRODUCT_NSW_TABLE_2 = 'Suwashakthi Table 2',
  PRODUCT_LI4_TABLE_2 = 'Janashakthi Investment Table 2',
  PRODUCT_LSP_TABLE_2 = 'Swarnashakthi Table 2',
  PRODUCT_NJEV_TABLE_2 = 'Jeevitha Thilina Table 2',
  PRODUCT_LIF1_TABLE_2 = 'Life Investment Flexi 1 Table 2',

  TERMS = 'QUOTATION TERMS',

  //Product References
  PRODUCT_JCA01 = 'Janashakthi Cash Advance',
  PRODUCT_NJU4 = 'Janashakthi Life Unlimited',
  PRODUCT_JDS = 'Janashakthi Dream Saver',
  PRODUCT_NGT02 = 'Family Plus',
  PRODUCT_NGT01 = 'Jeevitha Vardhana',
  PRODUCT_NSPS = 'Shilpashakthi',
  PRODUCT_NSW = 'Suwashakthi',
  PRODUCT_LI4 = 'Janashakthi Investment',
  PRODUCT_LSP = 'Swarnashakthi',
  PRODUCT_NJEV = 'Jeevitha Thilina',
  PRODUCT_LIF1 = 'Life Investment Flexi 1',
  REPORTS = 'Quotation Report',

  //HRCL Cover
  HRCL_COVER = 'HRCL Cover',

  //Age Configurations For PRoduct
  PRODUCT_JCA01_AGE = 'JCA01 Age Setup',
  PRODUCT_NJU4_AGE = 'NJU4 Age Setup',
  PRODUCT_JDS_AGE = 'JDS Age Setup',
  PRODUCT_NGT02_AGE = 'NGT02 Age Setup',
  PRODUCT_NGT01_AGE = 'NGT01 Age Setup',
  PRODUCT_NSPS_AGE = 'NSPS Age Setup',
  PRODUCT_NSW_AGE = 'NSW Age Setup',
  PRODUCT_LI4_AGE = 'LI4 Age Setup',
  PRODUCT_LSP_AGE = 'LSP Age Setup',
  PRODUCT_NJEV_AGE = 'NJEV Age Setup',
  PRODUCT_LIF1_AGE = 'LIF1 Age Setup',

  //Expiration Setup
  EXPIRATION_SETUP = 'Expiration Setup',
  EXPIRATION_SUSPECT_TO_PROSPECT = 'Suspect To Prospect',
}
