import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import { PhoneNumberRequestDTO } from '@shared/dtos';
import {
  CommonResponseDTO,
  IBaseEntity,
  IJanashakthiClaimsDetailsResponse,
  IJanashakthiPolicy,
  IJanashakthiPremiumResponse,
  IJanashakthiResponseWrapper,
  IPayment,
  IPhoneNumber,
  IPolicy,
  IPolicyResponse,
  IQuotationPremium,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

export interface PaymentValidateOtpRequestDTO {
  reference: string;
  phone_number: PhoneNumberRequestDTO;
}

export class PaymentFilteredRequestDTO {
  reference: string;
  otp: string;
  phone_number: IPhoneNumber;
}

export interface PaymentGenerateOTPResponseDTO extends IBaseEntity {
  id: string;
}

export interface PaymentVerifyOTPResponseDTO extends IBaseEntity {
  isVerified: boolean;
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class PoliciesService {
  constructor(private http: HttpClient) {}

  createNewPolicy(data: IPolicy): Observable<CommonResponseDTO<IPolicy>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_ADD_NEW,
    });
    return this.http.post<CommonResponseDTO<IPolicy>>(url, data);
  }

  updatePolicy(
    data: IPolicy,
    id: string
  ): Observable<CommonResponseDTO<IPolicy>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_UPDATE_ONE,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPolicy>>(url, data);
  }

  // quotation flow
  public getQuotationById(
    id: string,
    params?: HttpParams
  ): Observable<CommonResponseDTO<IPolicy>> {
    const url = generateURL({
      endpoint: ENDPOINTS.LEADS_QUOTATION_GET,
      params: { id },
    });
    return params
      ? this.http.get<CommonResponseDTO<IPolicy>>(url, { params })
      : this.http.get<CommonResponseDTO<IPolicy>>(url);
  }

  public getQuotationByIdForLeads(
    id: string
  ): Observable<CommonResponseDTO<IPolicy[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.LEADS_QUOTATION_GET_LEAD,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<IPolicy[]>>(url);
  }

  public updateQuotation(
    needAnalysis: IPolicy
  ): Observable<CommonResponseDTO<IPolicy>> {
    const url = generateURL({
      endpoint: ENDPOINTS.LEADS_QUOTATION_UPDATE,
    });
    return this.http.post<CommonResponseDTO<IPolicy>>(url, needAnalysis);
  }

  getAllJanashakthiPolicies(
    id: string
  ): Observable<CommonResponseDTO<IJanashakthiPolicy[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_JANASHAKTHI_GET,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<IJanashakthiPolicy[]>>(url);
  }

  public updateAmendmentQuotation(
    needAnalysis: IPolicy
  ): Observable<CommonResponseDTO<IPolicy>> {
    const url = generateURL({
      endpoint: ENDPOINTS.LEADS_QUOTATION_AMENDMENT_UPDATE,
    });
    return this.http.patch<CommonResponseDTO<IPolicy>>(url, needAnalysis);
  }
  // getPremiumCalculationData(
  //   data: ProductInformation
  // ): Observable<CommonResponseDTO<PremiumCalculationData>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_PREMIUM_CALCULATION,
  //   });
  //   return this.http.post<CommonResponseDTO<PremiumCalculationData>>(url, data);
  // }

  // getAllProductsFromBOMS(): Observable<
  //   CommonResponseDTO<IProductExternalSystems[]>
  // > {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_GET_ALL_BOMS_PRODUCTS,
  //   });
  //   return this.http.get<CommonResponseDTO<IProductExternalSystems[]>>(url);
  // }

  // getAllPartnersFromBOMS(): Observable<CommonResponseDTO<any[]>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_GET_ALL_BOMS_PARTNERS,
  //   });
  //   return this.http.get<CommonResponseDTO<any[]>>(url);
  // }

  // // REQUIRED REFERENCE CATEGORIES ENUM NEED TO ADD TO THE CONTROLLER
  // getReferencesFromBOMS(): Observable<CommonResponseDTO<IReferenceResponse[]>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_GET_BOMS_REFERENCES,
  //   });
  //   return this.http.get<CommonResponseDTO<IReferenceResponse[]>>(url);
  // }

  getAllPolicies(
    params: HttpParams
  ): Observable<CommonResponseDTO<IPolicyResponse[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_GET_ALL,
    });
    return this.http.get<CommonResponseDTO<IPolicyResponse[]>>(url, {
      params,
    });
  }

  getPremium(
    requestBody: IQuotationPremium
  ): Observable<
    CommonResponseDTO<IJanashakthiResponseWrapper<IJanashakthiPremiumResponse>>
  > {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_PREMIUM,
    });
    return this.http.post<
      CommonResponseDTO<
        IJanashakthiResponseWrapper<IJanashakthiPremiumResponse>
      >
    >(url, requestBody);
  }

  addQuickQuote(
    requestBody: IQuotationPremium
  ): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.LEADS_QUICK_QUOTATION,
    });
    return this.http.post<CommonResponseDTO<IPolicyResponse>>(url, requestBody);
  }

  getClaims(
    id: string
  ): Observable<CommonResponseDTO<IJanashakthiClaimsDetailsResponse[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_CLAIMS,
      params: { id },
    });
    return this.http.get<
      CommonResponseDTO<IJanashakthiClaimsDetailsResponse[]>
    >(url);
  }

  getPolicy(id: string): Observable<CommonResponseDTO<IPolicyResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_GET_ONE,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<IPolicyResponse>>(url);
  }

  PolicyAgeValidate(id: string): Observable<any> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_AGE_VALIDATE,
      params: { id },
    });

    return this.http.get<any>(url);
  }

  getAmendments(params: HttpParams): Observable<CommonResponseDTO<IPolicy[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.POLICIES_GET_AMENDMENT,
    });
    return this.http.get<CommonResponseDTO<IPolicy[]>>(url, { params });
  }

  // addProposal(
  //   data: ProposalInformation,
  //   id: string
  // ): Observable<CommonResponseDTO<IPolicy>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_ADD_PROPOSAL,
  //     params: { id },
  //   });
  //   return this.http.patch<CommonResponseDTO<IPolicy>>(url, data);
  // }

  // cancelProposal(id: string): Observable<CommonResponseDTO<IPolicyResponse>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_CANCEL_PROPOSAL,
  //     params: { id },
  //   });
  //   return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, {});
  // }

  // verifyReturnDocument(
  //   id: string,
  //   data: ProposalDocumentInformation
  // ): Observable<CommonResponseDTO<IPolicyResponse>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_VERIFY_PROPOSAL_DOCUMENTS,
  //     params: { id },
  //   });
  //   return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, { data });
  // }

  // proposalDocumentsResubmission(
  //   id: string,
  //   data: ProposalDocumentInformation[]
  // ): Observable<CommonResponseDTO<IPolicyResponse>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_PROPOSAL_RESUBMISSION,
  //     params: { id },
  //   });
  //   return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, { data });
  // }

  // proposalRaisedDocumentsSubmission(
  //   id: string,
  //   data: ProposalDocumentInformation[]
  // ): Observable<CommonResponseDTO<IPolicyResponse>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_PROPOSAL_RAISED_DOCUMENTS_SUBMISSION,
  //     params: { id },
  //   });
  //   return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, { data });
  // }

  // proposalDocumentsFinalVerification(
  //   id: string
  // ): Observable<CommonResponseDTO<IPolicyResponse>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_VERIFY_PROPOSAL_DOCUMENTS_FINAL,
  //     params: { id },
  //   });
  //   return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, {});
  // }

  // resubmitQuotationRequest(
  //   data: IResubmitQuotationRequest,
  //   id: string
  // ): Observable<CommonResponseDTO<IPolicyResponse>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_RESUBMIT,
  //     params: { id },
  //   });

  //   return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, data);
  // }

  // requestCoverNote(
  //   id: string,
  //   data: { reason: string }
  // ): Observable<CommonResponseDTO<IPolicyResponse>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_PROPOSAL_REQUEST_COVER_NOTE,
  //     params: { id },
  //   });

  //   return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, data);
  // }

  // deletePolicy(id: string): Observable<CommonResponseDTO<IPolicyResponse>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_DELETE_ONE,
  //     params: { id },
  //   });
  //   return this.http.delete<CommonResponseDTO<IPolicyResponse>>(url);
  // }

  // restorePolicy(id: string): Observable<CommonResponseDTO<IPolicyResponse>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_RESTORE_ONE,
  //     params: { id },
  //   });
  //   return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, {});
  // }

  // shareToCustomerLink(
  //   data: PolicyModuleShareToCustomerLink
  // ): Observable<CommonResponseDTO<void>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_SHARE_TO_CUSTOMER_LINK,
  //   });
  //   return this.http.post<CommonResponseDTO<void>>(url, data);
  // }

  // requestAmendment(
  //   id: string,
  //   data: { amendment_type: AMENDMENT_TYPES; amendment_remark: string }
  // ): Observable<CommonResponseDTO<IPolicyResponse>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_REQUEST_AMENDMENT,
  //     params: { id },
  //   });

  //   return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, data);
  // }

  // cancelAmendment(id: string): Observable<CommonResponseDTO<IPolicyResponse>> {
  //   const url = generateURL({
  //     endpoint: ENDPOINTS.POLICIES_CANCEL_AMENDMENT,
  //     params: { id },
  //   });
  //   return this.http.patch<CommonResponseDTO<IPolicyResponse>>(url, {});
  // }

  // // COMMON FUNC FOR BOMS REF GETTER
  // extractValueFromKey(
  //   references: IReferenceResponse[],
  //   key: string,
  //   fieldType: string
  // ) {
  //   const val = references.find((ref) => ref._id === key)?.reference || [];

  //   return (
  //     val
  //       ?.find((ref) => ref?.field?.name?.toUpperCase()?.trim() === fieldType)
  //       ?.value?.toString() || ''
  //   );
  // }

  // getListValueFromReference = (
  //   reference: IConfigurableFieldValueResponse[],
  //   type: string
  // ) => {
  //   return (
  //     reference
  //       ?.find((ref) => ref?.field?.name?.toUpperCase()?.trim() === type)
  //       ?.value?.toString() || ''
  //   );
  // };
  public addPayment(
    payment: IPayment,
    id: string
  ): Observable<CommonResponseDTO<IPayment>> {
    const url = generateURL({
      endpoint: ENDPOINTS.PAYMENT_SUBMIT,
      params: { id },
    });
    return this.http.post<CommonResponseDTO<IPayment>>(url, payment);
  }

  public updatePayment(id: string): Observable<CommonResponseDTO<IPayment>> {
    const url = generateURL({
      endpoint: ENDPOINTS.PAYMENT_UPDATE,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IPayment>>(url, {});
  }

  //get payment by proposal_id
  public getPayment(
    proposal_id: string
  ): Observable<CommonResponseDTO<IPayment>> {
    const id = proposal_id;
    const url = generateURL({
      endpoint: ENDPOINTS.PAYMENT_GET,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<IPayment>>(url);
  }

  public generateOTP(
    body: PaymentValidateOtpRequestDTO
  ): Observable<CommonResponseDTO<PaymentGenerateOTPResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.PAYMENT_SEND_OTP,
    });
    return this.http.post<CommonResponseDTO<PaymentGenerateOTPResponseDTO>>(
      url,
      body
    );
  }

  public verifyOTP(
    body: PaymentFilteredRequestDTO
  ): Observable<CommonResponseDTO<PaymentVerifyOTPResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.PAYMENT_VALIDATE_OTP,
    });
    return this.http.post<CommonResponseDTO<PaymentVerifyOTPResponseDTO>>(
      url,
      body
    );
  }

  public resendOTP(
    id: string
  ): Observable<CommonResponseDTO<PaymentGenerateOTPResponseDTO>> {
    const url = generateURL({
      endpoint: ENDPOINTS.PAYMENT_RESEND_OTP,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<PaymentGenerateOTPResponseDTO>>(url);
  }

  public reTryPayment(id: string): Observable<CommonResponseDTO<IPayment>> {
    const url = generateURL({
      endpoint: ENDPOINTS.PAYMENT_RETRY,
      params: { id },
    });
    return this.http.put<CommonResponseDTO<IPayment>>(url, {});
  }

  public getUtilization(id: string): Observable<CommonResponseDTO<any>> {
    const url = generateURL({
      endpoint: ENDPOINTS.PAYMENT_UTILIZATION,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<any>>(url);
  }
}
