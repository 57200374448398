//TODO:@ranul ADD COMMENTS
// add the angular component selectors as the type
export enum WIDGET_TYPES {
  COUNT = 'app-count-widget',
  LINE = 'app-line-chart',
  BAR = 'app-bar-chart',
  DONUT = 'app-donut-widget',
  NUMBER_CARD = 'app-number-card-widget',
  GROUP_BAR = 'app-group-bar-chart',
  PROGRESS_BAR = 'app-progress-bar-widget',
}

export enum WIDGET_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum WIDGET_CATEGORIES {
  LOGS = 'LOGS',
  DRIVES = 'DRIVES',
  MODULES = 'MODULES',
  CONNECTED_APPS = 'CONNECTED_APPS',
  CONNECTIONS = 'CONNECTIONS',
  IDENTITIES = 'IDENTITIES',
  MESSAGES = 'MESSAGES',
  NOTICES = 'NOTICES',
  PERMISSIONS = 'PERMISSIONS',
  ROLES = 'ROLES',
  PRIVILEGES = 'PRIVILEGES',
  REFERENCES = 'REFERENCES',
  KNOWLEDGEBASE = 'KNOWLEDGEBASE',
  CLAIMS = 'CLAIMS',
  RECEIPTS = 'RECEIPTS',
  ACTIVITIES = 'ACTIVITIES',
  AGENTS = 'AGENTS',
  LEADS = 'LEADS',
  ENDORSEMENTS = 'ENDORSEMENTS',
  INVOICES = 'INVOICES',
  COVER_NOTES = 'COVER_NOTES',
  POLICIES = 'POLICIES',
  ENDPOINTS = 'ENDPOINTS',
  MAPPINGS = 'MAPPINGS',
  STORAGE = 'STORAGE',
  TEST = 'TEST',
  TARGETS = 'TARGETS',
  ACHIEVEMENTS = 'ACHIEVEMENTS',
  PAYOUTS = 'PAYOUTS',
  RECRUITMENT = 'RECRUITMENT',
  REFERRERS = 'REFERRERS',
  CUSTOMERS = 'CUSTOMERS',
  PROMOTIONS = 'PROMOTIONS',
}

export enum WIDGET_KEYS {
  CONNECTED_APPS_COUNT = 'CONNECTED_APPS_COUNT',
  NOTICES_TYPE_COUNT = 'NOTICES_TYPE_COUNT',
  KNOWLEDGEBASE_TYPE_COUNT = 'KNOWLEDGEBASE_TYPE_COUNT',
  ROLES_TYPE_COUNT = 'ROLES_TYPE_COUNT',
  REFERENCES_COUNT = 'REFERENCES_COUNT',
  PRIVILEGES_COUNT = 'PRIVILEGES_COUNT',
  MESSAGES_TYPE_COUNT = 'MESSAGES_TYPE_COUNT',
  IDENTITIES_TYPE_COUNT = 'IDENTITIES_TYPE_COUNT',
  NOTICES_COUNT = 'NOTICES_COUNT',
  DRIVES_COUNT = 'DRIVES_COUNT',
  MESSAGES_COUNT = 'MESSAGES_COUNT',
  ROLES_COUNT = 'ROLES_COUNT',
  PERMISSION_COUNT = 'PERMISSION_COUNT',
  IDENTITIES_COUNT = 'IDENTITIES_COUNT',
  MODULES_COUNT = 'MODULES_COUNT',
  TEST_APPS_COUNT = 'TEST_APPS_COUNT',
  IDENTITIES_UNVERIFIED_COUNT = 'IDENTITIES_UNVERIFIED_COUNT',
  IDENTITIES_VERIFIED_COUNT = 'IDENTITIES_VERIFIED_COUNT',
  LOGS_COUNT = 'LOGS_COUNT',
  NOTICES_REPORT = 'NOTICES_REPORT',
  CLAIMS_TYPE_COUNT = 'CLAIMS_TYPE_COUNT',
  CLAIMS_OVERALL_COUNT = 'CLAIMS_OVERALL_COUNT',
  CLAIMS_CLASSES_OVERALL_COUNT = 'CLAIMS_CLASSES_OVERALL_COUNT',
  ACTIVITIES_TYPE_COUNT = 'ACTIVITIES_TYPE_COUNT',
  ACTIVITIES_MONTHLY_INDIVIDUAL_COUNT = 'ACTIVITIES_MONTHLY_INDIVIDUAL_COUNT',
  ACTIVITIES_WEEKLY_SCHEDULED_COUNT = 'ACTIVITIES_WEEKLY_SCHEDULED_COUNT',
  AGENTS_TYPE_COUNT = 'AGENTS_TYPE_COUNT',
  LEADS_TYPE_COUNT = 'LEADS_TYPE_COUNT',
  LEADS_CUSTOMER_TYPE_COUNT = 'LEADS_CUSTOMER_TYPE_COUNT',
  LEADS_CUSTOMER_SELF_COUNT = 'LEADS_CUSTOMER_SELF_COUNT',
  LEADS_RECRUITMENT_SELF_COUNT = 'LEADS_RECRUITMENT_SELF_COUNT',
  LEADS_SELF_COUNT = 'LEADS_SELF_COUNT',
  LEADS_OVERALL_COUNT = 'LEADS_OVERALL_COUNT',
  LEADS_OVERALL_CUSTOMER_COUNT = 'LEADS_OVERALL_CUSTOMER_COUNT',
  LEADS_OVERALL_RECRUITMENT_COUNT = 'LEADS_OVERALL_RECRUITMENT_COUNT',
  LEADS_OVERALL_INACTIVE_COUNT = 'LEADS_OVERALL_INACTIVE_COUNT',
  LEADS_SELF_INACTIVE_COUNT = 'LEADS_SELF_INACTIVE_COUNT',
  ENDORSEMENTS_TYPE_COUNT = 'ENDORSEMENTS_TYPE_COUNT',
  ENDORSEMENTS_TYPE_SELF_COUNT = 'ENDORSEMENTS_TYPE_SELF_COUNT',
  ENDORSEMENTS_SELF_COUNT = 'ENDORSEMENTS_SELF_COUNT',
  ENDORSEMENTS_OVERALL_COUNT = 'ENDORSEMENTS_OVERALL_COUNT',
  INVOICES_TYPE_COUNT = 'INVOICES_TYPE_COUNT',
  INVOICES_OVERALL_PENDING_COUNT = 'INVOICES_OVERALL_PENDING_COUNT',
  COVER_NOTES_TYPE_COUNT = 'COVER_NOTES_TYPE_COUNT',
  COVER_NOTES_TYPE_SELF_COUNT = 'COVER_NOTES_TYPE_SELF_COUNT',
  COVER_NOTES_OVERALL_COUNT = 'COVER_NOTES_OVERALL_COUNT',
  COVER_NOTES_OVERALL_SELF = 'COVER_NOTES_OVERALL_SELF',
  POLICIES_STATUS_OVERALL = 'POLICIES_STATUS_OVERALL',
  POLICIES_STATUS_SELF = 'POLICIES_STATUS_SELF',
  POLICIES_OVERALL_COUNT = 'POLICIES_OVERALL_COUNT',
  POLICIES_SELF_COUNT = 'POLICIES_SELF_COUNT',
  POLICIES_CLASS_OVERALL = 'POLICIES_CLASS_OVERALL',
  POLICIES_CLASS_MONTHLY_OVERALL = 'POLICIES_CLASS_MONTHLY_OVERALL',
  POLICIES_CLASS_SELF = 'POLICIES_CLASS_SELF',
  POLICIES_CLASS_MONTHLY_SELF = 'POLICIES_CLASS_MONTHLY_SELF',
  RECEIPTS_SELF_COUNT = 'RECEIPTS_SELF_COUNT',
  ENDPOINTS_COUNTS = 'ENDPOINTS_COUNTS',
  MAPPINGS_COUNTS = 'MAPPINGS_COUNTS',
  QUOTA_SYSTEM = 'QUOTA_SYSTEM',
  QUOTA_CONNECTED_APPS = 'QUOTA_CONNECTED_APPS',
  TOTAL_EARNINGS = 'TOTAL_EARNINGS',
  TOTAL_PAYOUTS = 'TOTAL_PAYOUTS',
  TOTAL_PAYOUTS_LAST_MONTHS = 'TOTAL_PAYOUTS_LAST_MONTHS',
  TOTAL_COMMISSIONS = 'TOTAL_COMMISSIONS',
  TOTAL_COMMISSIONS_TEAM = 'TOTAL_COMMISSIONS_TEAM',
  TOTAL_COMMISSIONS_PAYOUTS = 'TOTAL_COMMISSIONS_PAYOUTS',
  YEAR_TO_DATE_COMMISSION_EARNINGS = 'YEAR_TO_DATE_COMMISSION_EARNINGS',
  YEAR_TO_DATE_INCENTIVE_EARNINGS = 'YEAR_TO_DATE_INCENTIVE_EARNINGS',
  PAYOUTS_IN_QUEUE_COUNT = 'PAYOUTS_IN_QUEUE_COUNT',
  PAYOUTS_PENDING_APPROVAL_COUNT = 'PAYOUTS_PENDING_APPROVAL_COUNT',
  PAYOUTS_CANCELLATION_REQUESTED_COUNT = 'PAYOUTS_CANCELLATION_REQUESTED_COUNT',
  AGENT_COUNT_OVERALL = 'AGENT_COUNT_OVERALL',
  RECRUITMENT_STATUS_OVERALL = 'RECRUITMENT_STATUS_OVERALL',
  RECRUITMENT_STATUS_SELF = 'RECRUITMENT_STATUS_SELF',
  RECRUITMENT_OVERALL_COUNT = 'RECRUITMENT_OVERALL_COUNT',
  RECRUITMENT_SELF_COUNT = 'RECRUITMENT_SELF_COUNT',
  REFERRERS_ANALYTICS = 'REFERRERS_ANALYTICS',
  PROMOTIONS_ANALYTICS = 'PROMOTIONS_ANALYTICS',
  CUSTOMERS_SELF_COUNT = 'CUSTOMERS_SELF_COUNT',
  CUSTOMERS_OVERALL_COUNT = 'CUSTOMERS_OVERALL_COUNT',
  USER_QUOTA = 'USER_QUOTA',
  TEST_GROUP_BAR = 'TEST_GROUP_BAR', // This is for group bar chart test
}
