import * as moment from 'moment';

import { AGE_CALCULATION_OPTION } from '@shared/constants';
import { GENDERS } from '@shared/interfaces';

export interface NICAnalyzerResponse {
  success: boolean;
  data:
    | {
        gender: GENDERS;
        year: string;
        month: string;
        date: string;
      }
    | undefined;
}

export function analyzeNIC(NICNo: string): NICAnalyzerResponse {
  let daysOfYear = 0;
  let yearInt = 0;
  let gender = undefined;
  //const isLeapYear = false;

  // if (
  //   (NICNo.length !== 10 && NICNo.length !== 12) ||
  //   (NICNo.length === 10 && typeof NICNo.substring(0, 9) === 'number') ||
  //   (NICNo.length === 12 && typeof NICNo === 'number')
  // ) {
  //   return {
  //     success: false,
  //     data: undefined,
  //   };
  // }

  if (NICNo.length !== 10 && NICNo.length !== 12) {
    return {
      success: false,
      data: undefined,
    };
  } else {
    const onlyContainsNumbers = (str: string): boolean => {
      const checkStr = '0123456789';
      for (let i = 0; i < str.length; i++) {
        if (!checkStr.includes(str[i])) {
          return false;
        }
      }
      return true;
    };

    if (
      (NICNo.length === 10 && !onlyContainsNumbers(NICNo.substring(0, 9))) ||
      (NICNo.length === 10 &&
        NICNo.slice(-1)[0].toUpperCase() !== 'V' &&
        NICNo.slice(-1)[0].toUpperCase() !== 'X') ||
      (NICNo.length === 12 && !onlyContainsNumbers(NICNo))
    ) {
      return {
        success: false,
        data: undefined,
      };
    }
  }

  // Year
  if (NICNo.length === 10) {
    yearInt = 1900 + parseInt(NICNo.substring(0, 2), 10);
    daysOfYear = parseInt(NICNo.substring(2, 5), 10);
  } else {
    yearInt = parseInt(NICNo.substring(0, 4), 10);
    daysOfYear = parseInt(NICNo.substring(4, 7), 10);
  }

  const daysInTheBornYear = moment()
    .set('year', yearInt)
    .endOf('year')
    .dayOfYear();

  //isLeapYear = yearInt % 4 === 0;

  // Gender
  if (daysOfYear > 500) {
    gender = GENDERS.FEMALE;
    daysOfYear = daysOfYear - 500;
  } else {
    gender = GENDERS.MALE;
  }

  // Day Digit Validation
  if (daysOfYear < 1 || daysOfYear > daysInTheBornYear) {
    return {
      success: false,
      data: undefined,
    };
  }

  const birthdayMoment = moment()
    .set('year', yearInt)
    .set('dayOfYear', daysOfYear);
  const birthdayMonthStart = moment(birthdayMoment).startOf('month');

  let monthInt = birthdayMoment.month() + 1;
  let dateInt = Math.floor(
    birthdayMoment.diff(birthdayMonthStart, 'days', true)
  );

  switch (true) {
    case daysOfYear > 335:
      dateInt = daysOfYear - 335;
      monthInt = 12;
      break;
    case daysOfYear > 305:
      dateInt = daysOfYear - 305;
      monthInt = 11;
      break;
    case daysOfYear > 274:
      dateInt = daysOfYear - 274;
      monthInt = 10;
      break;
    case daysOfYear > 244:
      dateInt = daysOfYear - 244;
      monthInt = 9;
      break;
    case daysOfYear > 213:
      dateInt = daysOfYear - 213;
      monthInt = 8;
      break;
    case daysOfYear > 182:
      dateInt = daysOfYear - 182;
      monthInt = 7;
      break;
    case daysOfYear > 152:
      dateInt = daysOfYear - 152;
      monthInt = 6;
      break;
    case daysOfYear > 121:
      dateInt = daysOfYear - 121;
      monthInt = 5;
      break;
    case daysOfYear > 91:
      dateInt = daysOfYear - 91;
      monthInt = 4;
      break;
    case daysOfYear > 60:
      dateInt = daysOfYear - 60;
      monthInt = 3;
      break;
    case daysOfYear < 32:
      dateInt = daysOfYear;
      monthInt = 1;
      break;
    case daysOfYear > 31:
      dateInt = daysOfYear - 31;
      monthInt = 2;
      break;
  }

  // if (daysOfYear === daysInTheBornYear || isLeapYear) {
  //   dateInt = dateInt + 1;
  // }
  //dateInt === 0 && (dateInt = 1);

  return {
    success: true,
    data: {
      gender,
      date: (dateInt < 10 ? '0' : '') + dateInt,
      month: (monthInt < 10 ? '0' : '') + monthInt,
      year: yearInt.toString(),
    },
  };
}

export function oldToNewNIC(NICNo: string) {
  if (NICNo.length < 12) {
    const year = 1900 + parseInt(NICNo.substring(0, 2), 10);
    const days = parseInt(NICNo.substring(2, 5), 10);
    const serial = parseInt(NICNo.substring(5, 9), 10);
    return `${year}${days}0${serial}`;
  } else {
    return NICNo;
  }
}

export function getAge(
  dob: Date,
  age_cal_option: AGE_CALCULATION_OPTION,
  effective_date?: Date
): number {
  let age = 0;

  const date1 = effective_date
    ? new Date(effective_date)
    : new Date(Date.now());
  const date2 = new Date(dob);
  age = date1.getFullYear() - date2.getFullYear();

  if (age_cal_option === AGE_CALCULATION_OPTION.NEAREST_BIRTHDAY) {
    const thisYearDate = date2;
    const nextYearDate = date2;
    thisYearDate.setFullYear(date1.getFullYear());
    const gap_1 = Math.abs(date1.getTime() - thisYearDate.getTime());

    nextYearDate.setFullYear(date1.getFullYear() + 1);
    const gap_2 = Math.abs(nextYearDate.getTime() - date1.getTime());

    if (gap_1 > gap_2) {
      age = age + 1;
    }
  } else if (age_cal_option === AGE_CALCULATION_OPTION.NEXT_BIRTHDAY) {
    if (
      date1.getMonth() > date2.getMonth() ||
      (date1.getMonth() == date2.getMonth() &&
        date1.getDate() >= date2.getDate())
    ) {
      age = age + 1;
    } else if (date1.getMonth() == date2.getMonth()) {
      if (date1.getDate() >= date2.getDate()) {
        age = age + 1;
      }
    }
  }

  return age;
}
