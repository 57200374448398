import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[fourDigitNumber]',
  providers: [DecimalPipe],
})
export class FourDigitNumberDirective implements OnInit {
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  private regex = /^[0-9]*$/g;
  inputElement: HTMLInputElement;
  constructor(private el: ElementRef, private model: NgControl) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): any {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      ((e.key === 'a' || e.code === 'KeyA') && e.ctrlKey === true) || // Allow: Ctrl+A
      ((e.key === 'c' || e.code === 'KeyC') && e.ctrlKey === true) || // Allow: Ctrl+C
      ((e.key === 'v' || e.code === 'KeyV') && e.ctrlKey === true) || // Allow: Ctrl+V
      ((e.key === 'x' || e.code === 'KeyX') && e.ctrlKey === true) || // Allow: Ctrl+X
      ((e.key === 'a' || e.code === 'KeyA') && e.metaKey === true) || // Allow: Cmd+A (Mac)
      ((e.key === 'c' || e.code === 'KeyC') && e.metaKey === true) || // Allow: Cmd+C (Mac)
      ((e.key === 'v' || e.code === 'KeyV') && e.metaKey === true) || // Allow: Cmd+V (Mac)
      ((e.key === 'x' || e.code === 'KeyX') && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }
    const newValue = this.forecastValue(e.key);
    if (!newValue.toString().match(this.regex)) {
      e.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput() {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let value = inputElement.value;
    value = value.replace(/[^\d]/g, ''); // Remove non-digit characters
    value = value.slice(0, 4); // Limit to four digits
    inputElement.value = value;
    this.model.control.setValue(value, { emitEvent: true });
  }

  ngOnInit() {
    const value = this.el.nativeElement.value;
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const val = this.transform(value);
    inputElement.value = val;
    this.model.control.setValue(val, { emitEvent: true });
  }

  transform(value: any): string {
    value = value.replace(/[^\d]/g, ''); // Remove non-digit characters
    return value.slice(0, 4); // Limit to four digits
  }

  private forecastValue(key: string): string {
    const selectionStart = this.inputElement.selectionStart ?? 0;
    const selectionEnd = this.inputElement.selectionEnd ?? 0;
    const oldValue = this.inputElement.value;
    let newValue =
      oldValue.substring(0, selectionStart) +
      key +
      oldValue.substring(selectionEnd);

    newValue = newValue.replace(/[^\d]/g, ''); // Remove non-digit characters
    return newValue.slice(0, 4); // Limit to four digits
  }
}
