import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { isArray, isEqual } from 'lodash';
import { firstValueFrom, Observable, Subject, Subscription } from 'rxjs';

import { SELECT_ALL_ELEMENT } from './constants';
import { ELAutocompleteElement } from './types';

type AppearanceTypes = 'legacy' | 'standard' | 'fill' | 'outline';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'el-autocomplete',
  templateUrl: 'el-autocomplete.component.html',
  styleUrls: ['el-autocomplete.component.scss'],
})
export class ElAutocompleteComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label: string;
  @Input() options: ELAutocompleteElement[] = [];
  @Input() selected: string | string[];
  @Input() appearance: AppearanceTypes = 'outline';
  @Input() loaderText: string;
  @Input() dropdownPlaceholder = 'Filter';
  @Input() optionsEmpty = 'No Options';
  @Input() customError: string;
  @Input() placeHolder = '';
  @Input() freeTextEnabled = false;
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() hideError = false;
  @Input() hideTheAllOption = false;
  @Input() multiSelect = false;
  @Input() optionItemDisabled = false;
  @Input() displayOnlyMetaFunctionalities = false;
  @Input() isFieldsLoading = false;
  @Input() clearEvent: Observable<void>;
  @Output() itemsSelected = new EventEmitter<
    ELAutocompleteElement | ELAutocompleteElement[]
  >();
  @Output() focusOn = new EventEmitter<void>();
  @Output() focusOff = new EventEmitter<void>();

  @ViewChild('autocompleteInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  @ViewChild('innerInput') inputElement: ElementRef;

  allClearEventsSubscription: Subscription;

  textInput = new FormControl();
  filterInput = new FormControl();

  selectedOptions: ELAutocompleteElement[] = [];
  filteredOptions: Observable<ELAutocompleteElement[]>;
  filteredOptionsArray: ELAutocompleteElement[];
  freeText = '';

  selectAll = false;

  private onDestroy$ = new Subject();

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.allClearEventsSubscription = this.clearEvent?.subscribe(() =>
      this.clearSearchInput()
    );
    this.initializePreSelectedOptions();
    this.initializeFilteredArray();
    if (this.required) {
      this.textInput.setValidators(Validators.required);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const allOptionsChanges = changes['options'];
    if (
      allOptionsChanges &&
      !isEqual(allOptionsChanges.currentValue, allOptionsChanges.previousValue)
    ) {
      this.initializeFilteredArray();
      this.initializePreSelectedOptions();
    }

    const selectedOptionsChanges = changes['selected'];
    if (selectedOptionsChanges) {
      const { currentValue, previousValue } = selectedOptionsChanges;

      if (!currentValue) {
        this.textInput.setValue('');
        this.textInput.markAsUntouched();
        this.textInput.setErrors(null);
      } else if (!isEqual(currentValue, previousValue)) {
        this.initializePreSelectedOptions();
      }
    }

    if (this.disabled) {
      this.textInput.disable();
    } else {
      this.textInput.enable();
    }

    if (this.customError) {
      this.textInput.setErrors({ customError: true });
    }

    if (this.required) {
      this.textInput.setValidators(Validators.required);
    } else {
      this.textInput.removeValidators(Validators.required);
    }
  }

  initializePreSelectedOptions() {
    this.options.forEach((option) => {
      option.selected = false;
    });

    if (this.multiSelect && isArray(this.selected) && this.selected?.length) {
      this.selected.forEach((selectedOption) => {
        const existingOptions = this.options.reduce(
          (indexesArray, option, index) => {
            if (option.value === selectedOption) indexesArray.push(index);

            return indexesArray;
          },
          [] as number[]
        );

        if (existingOptions.length) {
          existingOptions.forEach((existingOption) =>
            this.onSelect(this.options[existingOption])
          );
        } else {
          const newOption = { value: selectedOption, selected: false };

          this.options.push(newOption);
          this.onSelect(newOption);
        }
      });
    } else if (
      !this.multiSelect &&
      !isArray(this.selected) &&
      this.selected?.toString()?.length
    ) {
      const existingOptionIndex = this.options.findIndex(
        (option) => option.value === this.selected.toString()
      );

      if (existingOptionIndex > -1) {
        this.onSelect(this.options[existingOptionIndex]);
      } else {
        const newOption = { value: this.selected };
        this.options.push(newOption);
        this.onSelect(newOption);
      }
    }
  }

  ngOnDestroy() {
    this.allClearEventsSubscription?.unsubscribe();
    window.removeEventListener('scroll', this.scrollEvent, true);
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  private scrollEvent = (): void => {
    if (this.autoComplete.panelOpen) {
      this.autoComplete.updatePosition();
    }
  };

  private initializeFilteredArray() {
    this.filteredOptions = this.filterInput.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      startWith(''),
      map((value) => {
        const filterValue: string = value?.toString()?.toLowerCase();

        if (!filterValue?.length) return this.options;

        return this.options.filter((option) =>
          (option.displayValue ?? option.value)
            ?.toLowerCase()
            ?.includes(filterValue)
        );
      })
    );

    this.filteredOptions
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((options) => {
        this.filteredOptionsArray = options;
        this.selectAll = this.isAllSelected(options);
      });
  }

  private clearSearchInput() {
    this.textInput.setValue('');
    this.filterInput.setValue('');
    this.selectedOptions = [];
    this.itemsSelected.emit();
  }

  private afterSelect() {
    this.freeText = '';
    this.textInput.setValue(
      this.selectedOptions
        .map((item) => item.displayValue ?? item.value)
        .join(', ')
    );
  }

  onClick() {
    if (!this.disabled) {
      if (!this.freeTextEnabled && this.inputElement.nativeElement) {
        setTimeout(() => {
          this.autoComplete?.openPanel();
          this.inputElement?.nativeElement?.focus();
        }, 0);
      }
    }
  }

  onSelect(option: ELAutocompleteElement) {
    if (!this.multiSelect) {
      if (
        !this.optionItemDisabled ||
        (this.optionItemDisabled && !option?.disabled)
      ) {
        this.selectedOptions = [{ ...option }];
        this.textInput.setValue(option.displayValue ?? option.value);
        this.itemsSelected.emit(option);
      } else {
        const obj: ELAutocompleteElement = {
          value: '',
        };
        this.textInput.setValue('');
        this.itemsSelected.emit(obj);
      }
    } else {
      if (!this.optionItemDisabled) {
        option.selected = !option.selected;
        if (option.selected) {
          if (!this.selectedOptions.some((opt) => opt.value === option.value)) {
            this.selectedOptions.push(option);
          }
        } else {
          const index = this.selectedOptions.findIndex(
            (_item) => _item.value === option.value
          );
          this.selectedOptions.splice(index, 1);
          option.selected = false;
        }
        this.itemsSelected.emit(this.selectedOptions);
      } else {
        if (!option?.disabled) {
          option.selected = !option.selected;
          if (option.selected) {
            if (
              !this.selectedOptions.some((opt) => opt.value === option.value)
            ) {
              this.selectedOptions.push(option);
            }
          } else {
            const index = this.selectedOptions.findIndex(
              (_item) => _item.value === option.value
            );
            this.selectedOptions.splice(index, 1);
            option.selected = false;
          }
          this.itemsSelected.emit(this.selectedOptions);
        }
      }
    }
    this.afterSelect();
  }

  optionPanelOpen() {
    if (!this.disabled) {
      if (this.multiSelect) {
        this.autoComplete.openPanel();
      }
    }
  }

  onSelectAll() {
    if (!this.multiSelect) {
      this.filterClear();
      this.itemsSelected.emit(SELECT_ALL_ELEMENT);
    } else {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.filteredOptionsArray.forEach((option) => {
          if (!option.selected) {
            option.selected = !option.selected;
            this.selectedOptions.push(option);
          }
        });
      } else {
        this.filteredOptionsArray.forEach((option) => {
          if (option.selected) {
            option.selected = !option.selected;
            const index = this.selectedOptions.findIndex(
              (_item) => _item.value === option.value
            );
            this.selectedOptions.splice(index, 1);
          }
        });
      }
      this.itemsSelected.emit(this.selectedOptions);
      this.autoComplete.openPanel();
      this.afterSelect();
    }
  }

  onFreeTextInput(value: string) {
    if (this.freeTextEnabled) {
      let currentInputText = value;
      if (this.selectedOptions.length === 0) {
        if (currentInputText[0] === ',' || currentInputText[0] === ' ') {
          currentInputText = '';
        }
        this.freeText = currentInputText;
      } else {
        const currentSelectedItemsText = this.selectedOptions
          .map((item) => item.value)
          .join(', ');
        if (currentSelectedItemsText.length > currentInputText.length) {
          if (currentInputText === '') {
            currentInputText = value;
          } else {
            currentInputText = value; // was currentInputText = currentSelectedItemsText without inner if condition
            this.freeText = '';
          }
        } else {
          if (currentInputText.length === currentSelectedItemsText.length + 1) {
            if (this.multiSelect) {
              if (
                currentInputText[currentSelectedItemsText.length] === ',' ||
                currentInputText[currentSelectedItemsText.length] === ' '
              ) {
                currentInputText = currentSelectedItemsText + ', ';

                this.freeText = '';
              } else {
                currentInputText =
                  currentSelectedItemsText +
                  ', ' +
                  currentInputText[currentSelectedItemsText.length];
                this.freeText = currentInputText.slice(
                  currentSelectedItemsText.length + 2
                );
              }
            } else {
              currentInputText = value;
              this.freeText = '';
            }
          } else if (
            currentInputText.length ===
            currentSelectedItemsText.length + 2
          ) {
            currentInputText = value;
            // currentInputText = currentSelectedItemsText;
            this.freeText = '';
          } else {
            currentInputText = value;
            this.freeText = '';
            // this.freeText = currentInputText.slice(currentSelectedItemsText.length + 2);
          }
        }
      }
      this.itemsSelected.emit({ value: currentInputText });
      this.textInput.setValue(currentInputText);
    }
  }

  onFreeTextSelect() {
    if (this.freeText.length !== 0) {
      // const existingOption = this.optionCheck(this.options, this.freeText);
      // const existingSelectedOption = this.optionCheck(this.selectedOptions, this.freeText);
      // if (!(existingOption || existingSelectedOption)) {
      //     const newOption: ELAutocompleteElement = { value: this.freeText };
      //     if (this.multiSelect) {
      //         newOption.selected = false;
      //     }
      //     if (existingOption) {
      //         if (!existingSelectedOption) {
      //             this.onSelect(newOption);
      //         } else {
      //             this.afterSelect();
      //         }
      //     } else {
      //         this.options.push(newOption);
      //         this.filterClear();
      //         this.onSelect(newOption);
      //     }
      // } else {
      //     this.afterSelect();
      // }
    }
  }

  isAllSelected(options: ELAutocompleteElement[]): boolean {
    if (options.length <= this.selectedOptions.length) {
      let count = 0;
      for (const filteredOption of options) {
        for (const selectedOption of this.selectedOptions) {
          if (filteredOption.value === selectedOption.value) {
            count += 1;
          }
        }
      }
      if (count === options.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // optionCheck(options: ELAutocompleteElement[], value: string): boolean {
  //   return options.some((option) => option.value === value);
  // }

  filterClear() {
    this.filterInput.setValue('');
    this.initializeFilteredArray();
  }

  focusChanged(focused: boolean, autocomplete: MatAutocomplete) {
    if (focused || autocomplete.isOpen) {
      this.focusOn.emit();
    } else {
      this.focusOff.emit();
    }

    if (!focused && autocomplete.isOpen) {
      firstValueFrom(autocomplete.closed).then(() => {
        this.focusOff.emit();
      });
    }
  }
}
