import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[dotSeparatedWithTwoDecimals]',
  providers: [DecimalPipe],
})
export class DotSeparatedWithTwoDecimalDirective implements OnInit {
  constructor(private el: ElementRef, private model: NgControl) {}

  @HostListener('input', ['$event']) onInput() {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const value = inputElement.value;
    inputElement.value = this.transform(value);
  }

  ngOnInit() {
    const value = this.el.nativeElement.value;
    const inputElement = this.el.nativeElement as HTMLInputElement;
    inputElement.value = this.transform(value);
  }

  transform(value: any): string {
    value = value.replace(/[^\d.]/g, '');

    const [integerPart, decimalPart] = value.split('.');

    if (decimalPart !== undefined) {
      const formattedDecimalPart = decimalPart.slice(0, 2);
      value = `${integerPart}.${formattedDecimalPart}`;
    } else {
      value = integerPart;
    }

    this.model.control.setValue(value, { emitEvent: true });
    return value;
  }
}
