import { REQUEST_TYPES } from '@shared/constants';

interface EndpointResponse {
  body: any;
  description: string;
  statusCode: number;
}

export interface EndpointData {
  title: string;
  method: REQUEST_TYPES;
  url: string;
  headers?: { key: string; value: string }[];
  body?: any;
  responses?: EndpointResponse[];
  parameters?: any;
  pathParamInfo?: { param: string; description: string }[];
}

export const setPublicAPIDocumentationArray = (data: EndpointData[]) => {
  const getExtraInfoRemoved = (data: any, skipExample = false) => {
    if (!data) return null;

    const res: any = {};
    Object.entries(data).forEach(([key, value]: [any, any]) => {
      if (
        !skipExample &&
        (value.example || value.example === false || value.example === null)
      ) {
        res[key] = value.example;
      } else if (typeof value === 'object') {
        if (value.type === 'array') {
          res[key] = [getExtraInfoRemoved(value).items];
        } else {
          res[key] = getExtraInfoRemoved(value);
        }
      } else {
        res[key] = value;
      }
    });
    return res;
  };

  return data.map((endpoint) => {
    const contentTypeHeader = endpoint.headers?.find(
      (header) => header.key === 'content-type'
    );
    const skipExample = contentTypeHeader?.value === 'multipart/form-data';
    return {
      ...endpoint,
      body: endpoint.body
        ? JSON.stringify(
            getExtraInfoRemoved(endpoint.body, skipExample),
            null,
            2
          )
        : null,
      responses: endpoint.responses?.map((_response) => {
        if (_response?.body) {
          return {
            ..._response,
            body: JSON.stringify(getExtraInfoRemoved(_response.body), null, 2),
          };
        }
        return _response;
      }),
    };
  });
};

export function isJson(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function addParamsToURL(
  url: string,
  params: Record<string, string>
): string {
  const formattedURL = new URL(url);

  Object.entries(params)?.forEach((param) => {
    const [key, value] = param;
    formattedURL.searchParams.append(key, value);
  });

  return formattedURL.toString();
}

export function convertPhoneNumberToDesiredFormat(
  numberString: string
): string {
  // Remove any spaces from the input string
  const number = numberString.replace(/\s/g, '');

  // Check if the string is at least 5 characters long
  if (number.length >= 5) {
    // firstTwo and asterisks and lastThree;
    return (
      number.substring(0, 2) +
      '*'.repeat(number.length - 5) +
      number.substring(number.length - 3)
    );
  } else {
    // If the string is too short, return it as-is
    return number;
  }
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => reject(error);
  });
}

export function getAgeFromDOB(birthDate: Date): number {
  const currentDate = new Date();

  const birthYear = birthDate.getFullYear();
  const birthMonth = birthDate.getMonth();
  const birthDay = birthDate.getDate();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  let age = currentYear - birthYear;

  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDay < birthDay)
  ) {
    age--;
  }

  return age;
}
