import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';

import { ENDPOINTS, PREDEFINED_REFERENCE_CATEGORIES } from '@shared/constants';
import {
  CommonResponseDTO,
  ICityReference,
  IContactAddress,
  ICountryReference,
  IDistrictReference,
  IReference,
  IStateReference,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

export interface IAddressData {
  country: IReference<ICountryReference>;
  state: IReference<IStateReference>;
  district: IReference<IDistrictReference>;
  city: IReference<ICityReference>;
}

@Injectable({ providedIn: 'root' })
export class AddressService {
  private countryData = new BehaviorSubject<{
    [id: string]: IReference<ICountryReference>;
  }>({});
  private stateData = new BehaviorSubject<{
    [id: string]: IReference<IStateReference>;
  }>({});
  private districtData = new BehaviorSubject<{
    [id: string]: IReference<IDistrictReference>;
  }>({});
  private cityData = new BehaviorSubject<{
    [id: string]: IReference<ICityReference>;
  }>({});

  constructor(private http: HttpClient) {}

  private async getAddressData({
    countryId,
    stateId,
    districtId,
    cityId,
  }: {
    countryId: string;
    stateId: string;
    districtId: string;
    cityId: string;
  }): Promise<IAddressData & { reject?: boolean }> {
    const currentCountry: IReference<ICountryReference> =
      await this.getCurrentCountry(countryId);
    const currentState: IReference<IStateReference> =
      await this.getCurrentState(stateId);
    const currentDistrict: IReference<IDistrictReference> =
      await this.getCurrentDistrict(districtId);
    const currentCity: IReference<ICityReference> = await this.getCurrentCity(
      cityId
    );

    return new Promise((resolve) => {
      forkJoin({
        country: currentCountry?._id
          ? this.getCurrentCountry(countryId)
          : this.getCountry(countryId),
        state: currentState?._id
          ? this.getCurrentState(stateId)
          : this.getState(stateId),
        district: currentDistrict?._id
          ? this.getCurrentDistrict(districtId)
          : this.getDistrict(districtId),
        city: currentCity?._id
          ? this.getCurrentCity(cityId)
          : this.getCity(cityId),
      }).subscribe({
        next: (res) => {
          resolve({
            country: res.country,
            state: res.state,
            district: res.district,
            city: res.city,
            reject: false,
          });
        },
        error: () => {
          resolve({
            country: undefined,
            state: undefined,
            district: undefined,
            city: undefined,
            reject: true,
          });
        },
      });
    });
  }

  async bindAddressData(address: IContactAddress): Promise<IContactAddress> {
    if (
      address.address.countryData &&
      address.address.stateData &&
      address.address.districtData &&
      address.address.cityData
    ) {
      return address;
    }

    const updatedAddress: IAddressData & { reject?: boolean } =
      await this.getAddressData({
        countryId: address.address.country,
        stateId: address.address.state,
        districtId: address.address.district,
        cityId: address.address.city,
      });

    address.address.countryData = updatedAddress.country;
    address.address.stateData = updatedAddress.state;
    address.address.districtData = updatedAddress.district;
    address.address.cityData = updatedAddress.city;

    return address;
  }

  async getCurrentCountry(
    countryId: string
  ): Promise<IReference<ICountryReference>> {
    const currentCountry = this.countryData.value;

    return currentCountry[countryId];
  }

  async getCurrentState(stateId: string): Promise<IReference<IStateReference>> {
    const currentState = this.stateData.value;

    return currentState[stateId];
  }

  async getCurrentDistrict(
    districtId: string
  ): Promise<IReference<IDistrictReference>> {
    const currentDIstrict = this.districtData.value;

    return currentDIstrict[districtId];
  }

  async getCurrentCity(cityId: string): Promise<IReference<ICityReference>> {
    const currentCity = this.cityData.value;

    return currentCity[cityId];
  }

  async getCountry(countryId: string): Promise<IReference<ICountryReference>> {
    return new Promise((resolve, reject) => {
      const currentCountry = this.countryData.value;
      const url = generateURL({
        endpoint: ENDPOINTS.PREDEFINED_REFERENCES_GET_VALUE,
      });

      this.http
        .get<CommonResponseDTO<IReference<ICountryReference>>>(url, {
          params: {
            refType: PREDEFINED_REFERENCE_CATEGORIES.COUNTRIES,
            refId: countryId,
          },
        })
        .subscribe({
          next: (res) => {
            currentCountry[res.data._id?.toString()] = res.data;
            this.countryData.next(currentCountry);
            resolve(res.data);
          },
          error: reject,
        });
    });
  }

  async getState(stateId: string): Promise<IReference<IStateReference>> {
    return new Promise((resolve, reject) => {
      const currentState = this.stateData.value;
      const url = generateURL({
        endpoint: ENDPOINTS.PREDEFINED_REFERENCES_GET_VALUE,
      });

      this.http
        .get<CommonResponseDTO<IReference<IStateReference>>>(url, {
          params: {
            refType: PREDEFINED_REFERENCE_CATEGORIES.STATES,
            refId: stateId,
          },
        })
        .subscribe({
          next: (res) => {
            currentState[res.data._id.toString()] = res.data;
            this.stateData.next(currentState);
            resolve(res.data);
          },
          error: reject,
        });
    });
  }

  async getDistrict(
    districtId: string
  ): Promise<IReference<IDistrictReference>> {
    return new Promise((resolve, reject) => {
      const currentDistrict = this.districtData.value;
      const url = generateURL({
        endpoint: ENDPOINTS.PREDEFINED_REFERENCES_GET_VALUE,
      });

      this.http
        .get<CommonResponseDTO<IReference<IDistrictReference>>>(url, {
          params: {
            refType: PREDEFINED_REFERENCE_CATEGORIES.DISTRICTS,
            refId: districtId,
          },
        })
        .subscribe({
          next: (res) => {
            currentDistrict[res.data._id.toString()] = res.data;
            this.districtData.next(currentDistrict);
            resolve(res.data);
          },
          error: reject,
        });
    });
  }

  async getCity(cityId: string): Promise<IReference<ICityReference>> {
    return new Promise((resolve, reject) => {
      const currentCity = this.cityData.value;
      const url = generateURL({
        endpoint: ENDPOINTS.PREDEFINED_REFERENCES_GET_VALUE,
      });

      this.http
        .get<CommonResponseDTO<IReference<ICityReference>>>(url, {
          params: {
            refType: PREDEFINED_REFERENCE_CATEGORIES.CITIES,
            refId: cityId,
          },
        })
        .subscribe({
          next: (res) => {
            currentCity[res.data._id?.toString()] = res.data;
            this.cityData.next(currentCity);
            resolve(res.data);
          },
          error: reject,
        });
    });
  }
}
