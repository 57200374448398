import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[commaSeparatedWithTwoDecimal]',
  providers: [DecimalPipe],
})
export class CommaSeparatedWithTwoDecimalsDirective implements OnInit {
  inputElement: HTMLInputElement;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  private regex = /^[,.0-9]*$/g;

  constructor(
    private el: ElementRef,
    private model: NgControl,
    private decimal: DecimalPipe
  ) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): any {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      ((e.key === 'a' || e.code === 'KeyA') && e.ctrlKey === true) || // Allow: Ctrl+A
      ((e.key === 'c' || e.code === 'KeyC') && e.ctrlKey === true) || // Allow: Ctrl+C
      ((e.key === 'v' || e.code === 'KeyV') && e.ctrlKey === true) || // Allow: Ctrl+V
      ((e.key === 'x' || e.code === 'KeyX') && e.ctrlKey === true) || // Allow: Ctrl+X
      ((e.key === 'a' || e.code === 'KeyA') && e.metaKey === true) || // Allow: Cmd+A (Mac)
      ((e.key === 'c' || e.code === 'KeyC') && e.metaKey === true) || // Allow: Cmd+C (Mac)
      ((e.key === 'v' || e.code === 'KeyV') && e.metaKey === true) || // Allow: Cmd+V (Mac)
      ((e.key === 'x' || e.code === 'KeyX') && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }

    const newValue = this.forecastValue(e.key);

    if (!newValue.toString().match(this.regex)) {
      e.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onEvent() {
    const value = this.el.nativeElement.value;
    this.model.control.setValue(this.transform(value), { emitEvent: true });
  }

  ngOnInit() {
    const value = this.el.nativeElement.value;
    if (value.toString().match(this.regex)) {
      this.model.control.setValue(this.transform(value), { emitEvent: true });
    }
  }

  private transform(value: any) {
    const parts = value.toString().split('.');
    parts[0] = this.decimal.transform(
      (parts[0] as string).replace(/,/g, '').substring(0, 14)
    );

    if (Number(parts[1]) >= 0) {
      const lastDecimal = parts[1][1] ?? '';
      parts[1] = parts[1].toString().substring(0, 1);
      return `${parts[0]}.${parts[1]}${lastDecimal}`;
    }
    return parts[0];
  }

  private forecastValue(key: string): string {
    const selectionStart = this.inputElement.selectionStart ?? 0;
    const selectionEnd = this.inputElement.selectionEnd ?? 0;
    const oldValue = this.inputElement.value;
    return (
      oldValue.substring(0, selectionStart) +
      key +
      oldValue.substring(selectionEnd)
    );
  }
}
