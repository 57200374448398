import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, Toast, ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { ProgressToastComponent } from '../modules/shared/components/progress.toast/progress-toast.component';

export enum SUCCESS_TYPES {
  CUSTOM = 'CUSTOM',
  ADDED = 'ADDED',
  BULK_ADDED = 'BULK_ADDED',
  PROFILE_PIC_ADDED = 'PROFILE_PIC_ADDED',
  CONNECTION_DELETED = 'CONNECTION_DELETED',
  DELETED = 'DELETED',
  RESTORED = 'RESTORED',
  RECOVERED = 'RECOVERED',
  VERIFIED = 'VERIFIED',
  GENERATED = 'GENERATED',
  COPIED = 'COPIED',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  CREATED = 'CREATED',
  RESET = 'RESET',
  BLOCKED = 'BLOCKED',
  UNBLOCKED = 'UNBLOCKED',
  SENT = 'SENT',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  UPLOADED = 'UPLOADED',
  SAVED = 'SAVED',
  DOWNLOADED = 'DOWNLOADED',
  ARCHIVED = 'ARCHIVED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  SHOWN = 'SHOWN',
  HIDDEN = 'HIDDEN',
  MOVED = 'MOVED',
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  RENAMED = 'RENAMED',
  MARKED = 'MARKED',
  UNMARKED = 'UNMARKED',
  UPDATED = 'UPDATED',
  CONFIGURATION_UPLOADED = 'CONFIGURATION_UPLOADED',
  EXTENDED = 'EXTENDED',
  CONFIGURABLE = 'CONFIGURABLE',
  NOT_CONFIGURABLE = 'NOT_CONFIGURABLE',
  STOPPED = 'STOPPED',
  ACTIVITY_DELETED = 'ACTIVITY_DELETED',
  ACTIVITY_RESCHEDULE = 'ACTIVITY_RESCHEDULE',
  ACTIVITY_EDIT = 'ACTIVITY_EDIT',
  ACTIVITY_COMPLETE = 'ACTIVITY_COMPLETE',
  ACTIVITY_ADDED = 'ACTIVITY_ADDED',
  AGENT_REASSIGNED = 'AGENT_REASSIGNED',
  PRIVILEGE_CREATED = 'PRIVILEGE_CREATED',
  PRIVILEGE_UPDATED = 'PRIVILEGE_UPDATED',
  IMPERSONATE = 'IMPERSONATE',
  IMPERSONATE_END = 'IMPERSONATE_END',
  TARGET_UPDATED = 'TARGET_UPDATED',
  COMPLETED = 'COMPLETED',
  CALCULATED = 'CALCULATED',
  ACHIEVEMENT_CREATED = 'ACHIEVEMENT_CREATED',
  PAYOUT_APPROVED = 'PAYOUT_APPROVED',
  LAYOUT_CHANGED = 'LAYOUT_CHANGED',
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  public info(message: string, title?: string): void {
    const config: Partial<IndividualConfig> = {
      positionClass: 'toast-bottom-center',
    };

    this.toastr.info(message, title, config);
  }

  public success(
    type: SUCCESS_TYPES,
    entity?: string,
    message?: string,
    title?: string
  ): void {
    let formattedMessage: string, formattedTitle: string | undefined;
    switch (type) {
      case SUCCESS_TYPES.ADDED:
        formattedMessage = entity
          ? this.translate.instant('services.snackbar.success-with-entity.add')
          : this.translate.instant('services.snackbar.success.add');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.BULK_ADDED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.bulk-add'
            )
          : this.translate.instant('services.snackbar.success.bulk-add');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.PROFILE_PIC_ADDED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.profile-pic-add'
            )
          : this.translate.instant('services.snackbar.success.profile-pic-add');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.CONNECTION_DELETED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.connection-delete'
            )
          : this.translate.instant(
              'services.snackbar.success.connection-delete'
            );
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.DELETED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.delete'
            )
          : this.translate.instant('services.snackbar.success.delete');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.COMPLETED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.complete'
            )
          : this.translate.instant('services.snackbar.success.complete');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.CALCULATED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.calculate'
            )
          : this.translate.instant('services.snackbar.success.calculate');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.RESTORED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.restore'
            )
          : this.translate.instant('services.snackbar.success.restore');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.RECOVERED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.recover'
            )
          : this.translate.instant('services.snackbar.success.recover');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.VERIFIED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.verify'
            )
          : this.translate.instant('services.snackbar.success.verify');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.GENERATED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.generate'
            )
          : this.translate.instant('services.snackbar.success.generate');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.COPIED:
        formattedMessage = entity
          ? this.translate.instant('services.snackbar.success-with-entity.copy')
          : this.translate.instant('services.snackbar.success.copy');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.CONNECTED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.connect'
            )
          : this.translate.instant('services.snackbar.success.connect');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.DISCONNECTED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.disconnect'
            )
          : this.translate.instant('services.snackbar.success.disconnect');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.CREATED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.create'
            )
          : this.translate.instant('services.snackbar.success.create');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.RESET:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.reset'
            )
          : this.translate.instant('services.snackbar.success.reset');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.BLOCKED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.block'
            )
          : this.translate.instant('services.snackbar.success.block');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.UNBLOCKED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.unblock'
            )
          : this.translate.instant('services.snackbar.success.unblock');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.SENT:
        formattedMessage = entity
          ? this.translate.instant('services.snackbar.success-with-entity.send')
          : this.translate.instant('services.snackbar.success.send');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.ENABLED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.enable'
            )
          : this.translate.instant('services.snackbar.success.enable');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.DISABLED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.disable'
            )
          : this.translate.instant('services.snackbar.success.disable');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.UPLOADED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.upload'
            )
          : this.translate.instant('services.snackbar.success.upload');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.CONFIGURATION_UPLOADED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.configuration-upload'
            )
          : this.translate.instant(
              'services.snackbar.success.configuration-upload'
            );
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.SAVED:
        formattedMessage = entity
          ? this.translate.instant('services.snackbar.success-with-entity.save')
          : this.translate.instant('services.snackbar.success.save');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.DOWNLOADED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.download'
            )
          : this.translate.instant('services.snackbar.success.download');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.ARCHIVED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.archive'
            )
          : this.translate.instant('services.snackbar.success.archive');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.PUBLISHED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.publish'
            )
          : this.translate.instant('services.snackbar.success.publish');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.UNPUBLISHED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.unpublish'
            )
          : this.translate.instant('services.snackbar.success.unpublish');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.SHOWN:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.shown'
            )
          : this.translate.instant('services.snackbar.success.shown');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.HIDDEN:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.hidden'
            )
          : this.translate.instant('services.snackbar.success.hidden');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.MOVED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.moved'
            )
          : this.translate.instant('services.snackbar.success.moved');
        formattedTitle = undefined;
        break;
      case SUCCESS_TYPES.ACTIVATED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.activate'
            )
          : this.translate.instant('services.snackbar.success.activate');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.DEACTIVATED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.deactivate'
            )
          : this.translate.instant('services.snackbar.success.deactivate');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.RENAMED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.rename'
            )
          : this.translate.instant('services.snackbar.success.rename');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.MARKED:
        formattedMessage = entity
          ? this.translate.instant('services.snackbar.success-with-entity.mark')
          : this.translate.instant('services.snackbar.success.mark');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.UNMARKED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.unmark'
            )
          : this.translate.instant('services.snackbar.success.unmark');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.UPDATED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.update'
            )
          : this.translate.instant('services.snackbar.success.update');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.EXTENDED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.extended'
            )
          : this.translate.instant('services.snackbar.success.extended');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.CONFIGURABLE:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.configurable'
            )
          : this.translate.instant('services.snackbar.success.update');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.NOT_CONFIGURABLE:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.not-configurable'
            )
          : this.translate.instant('services.snackbar.success.update');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.STOPPED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.stopped'
            )
          : this.translate.instant('services.snackbar.success.stopped');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.ACTIVITY_DELETED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.activity-delete'
            )
          : this.translate.instant('services.snackbar.success.activity-delete');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.ACTIVITY_RESCHEDULE:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.activity-reschedule'
            )
          : this.translate.instant(
              'services.snackbar.success.activity-reschedule'
            );
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.ACTIVITY_EDIT:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.activity-edit'
            )
          : this.translate.instant('services.snackbar.success.activity-edit');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.ACTIVITY_COMPLETE:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.activity-complete'
            )
          : this.translate.instant(
              'services.snackbar.success.activity-complete'
            );
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.ACTIVITY_ADDED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.activity-added'
            )
          : this.translate.instant('services.snackbar.success.activity-added');
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.AGENT_REASSIGNED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.agent-reassigned'
            )
          : this.translate.instant(
              'services.snackbar.success.agent-reassigned'
            );
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.PRIVILEGE_CREATED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.privilege-created'
            )
          : this.translate.instant(
              'services.snackbar.success.privilege-created'
            );
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.PRIVILEGE_UPDATED:
        formattedMessage = entity
          ? this.translate.instant(
              'services.snackbar.success-with-entity.privilege-edited'
            )
          : this.translate.instant(
              'services.snackbar.success.privilege-edited'
            );
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.IMPERSONATE:
        formattedMessage = this.translate.instant(
          'services.snackbar.success-with-entity.impersonate'
        );
        formattedTitle = undefined;
        break;

      case SUCCESS_TYPES.IMPERSONATE_END:
        formattedMessage = this.translate.instant(
          'services.snackbar.success-with-entity.impersonate-end'
        );
        formattedTitle = undefined;
        break;
      case SUCCESS_TYPES.TARGET_UPDATED:
        formattedMessage = this.translate.instant(
          'services.snackbar.success-with-entity.target-update'
        );
        formattedTitle = undefined;
        break;
      case SUCCESS_TYPES.ACHIEVEMENT_CREATED:
        formattedMessage = this.translate.instant(
          'services.snackbar.success-with-entity.Achievement-created'
        );
        formattedTitle = undefined;
        break;
      case SUCCESS_TYPES.PAYOUT_APPROVED:
        formattedMessage = this.translate.instant(
          'services.snackbar.success-with-entity.payout-approved'
        );
        formattedTitle = undefined;
        break;
      case SUCCESS_TYPES.LAYOUT_CHANGED:
        formattedMessage = this.translate.instant(
          'services.snackbar.success-with-entity.layout-changed'
        );
        formattedTitle = undefined;
        break;

      default:
        formattedMessage =
          message || entity
            ? this.translate.instant(
                'services.snackbar.success-with-entity.custom'
              )
            : this.translate.instant('services.snackbar.success.custom');
        formattedTitle = title;
        break;
    }

    const config: Partial<IndividualConfig> = {
      positionClass: 'toast-bottom-center',
    };

    if (entity) {
      formattedMessage = entity + ' ' + formattedMessage;
    }

    this.toastr.success(formattedMessage, formattedTitle, config);
  }

  public error(
    message: string,
    title?: string,
    options: Partial<IndividualConfig> = {}
  ): void {
    const config: Partial<IndividualConfig> = {
      positionClass: 'toast-bottom-center',
      ...options,
    };

    this.toastr.error(message, title, config);
  }

  public warn(message: string, title?: string): void {
    const config: Partial<IndividualConfig> = {
      positionClass: 'toast-bottom-center',
    };

    this.toastr.warning(message, title, config);
  }

  public progress(
    progressSubject: Subject<number>,
    data: {
      filename: string;
      fileSize: string;
      type: 'DOWNLOAD' | 'UPLOAD';
      onCancel: () => void;
    }
  ) {
    const config: Partial<IndividualConfig> = {
      positionClass: 'toast-bottom-center',
      disableTimeOut: true,
      toastComponent: ProgressToastComponent,
      toastClass: `toast-custom-progress ngx-toastr toastr-icon-${
        data.type === 'DOWNLOAD' ? 'download' : 'upload'
      }`,
      payload: {
        ...data,
        progress: 0,
      },
      progressBar: true,
      progressAnimation: 'increasing',
    };

    const toast = this.toastr.show('', undefined, config);
    const toastInstance: Toast = toast.toastRef.componentInstance;

    let progress = 0;
    toastInstance.updateProgress = () => {
      toastInstance.options.payload.progress = progress;
    };

    progressSubject.subscribe({
      next: (val) => {
        progress = val;
        toastInstance.updateProgress();
        toastInstance.resetTimeout();
      },
      complete: () => {
        toastInstance.remove();
      },
    });
  }
}
