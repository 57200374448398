export enum VEHICLE_USE_TYPES {
  PRIVATE = 'PRIVATE',
  HIRING = 'HIRING',
  RENT = 'RENT',
}

export enum VEHICLE_TYPES {
  CAR = 'CAR',
  VAN = 'VAN',
  LORRY = 'LORRY',
  BUS = 'BUS',
}

export enum FINANCIAL_INTEREST_TYPES {
  LEASE = 'LEASE',
  LOAN = 'LOAN',
  HIRE = 'HIRE',
  NONE = 'NONE',
}

export enum COMMUNICATION_MODES {
  EMAIL = 'EMAIL',
  LETTERS = 'LETTERS',
  FAX = 'FAX',
}

export enum COMMUNICATION_LANGUAGES {
  SINHALA = 'SINHALA',
  ENGLISH = 'ENGLISH',
  TAMIL = 'TAMIL',
}

// export enum COVER_TYPES {
//   COMPREHENSIVE = 'COMPREHENSIVE',
//   THIRD_PARTY = 'THIRD PARTY FIRE THEFT',
//   THIRD_ONLY = 'THIRD ONLY',
// }

// For Dropdowns
export enum FUEL_TYPES {
  PETROL = 'PETROL',
  DIESEL = 'DIESEL',
  HYBRID = 'HYBRID',
  ELECTRIC = 'ELECTRIC',
}

// For Dropdowns
export enum NATURE_OF_LOSS {
  NORMAL_ACCIDENTS = 'Normal Accidents',
  RIOT_AND_STRIKE = 'Riot & Strike',
  HIT_AND_RUN = 'Hit & Run',
  FLOOD = 'Flood',
  THEFT = 'Theft',
}

export enum PROPOSAL_FLOW_MEDICAL_AILMENTS {
  // NOTE: Keep all the values in this "simple letters and underscore" format.
  // And try to not to change (recuse much as possible) old enums in future.
  // because same values using in DB attribute naming as well.

  HEART_DISEASE = 'heart_disease',
  ARTHRITIS = 'arthritis',
  ASTHMA = 'asthma',
  BACK_DISORDER = 'back_disorder',
  STOMACH_BOWEL_COMPLAINTS = 'stomach_bowel_complaints',
  MUSCULO_SKELETAL = 'musculo_skeletal',
  DIABETES = 'diabetes',
  HIGH_BLOOD_PRESSURE = 'high_blood_pressure',
  EPILEPSY = 'epilepsy',
  GENITO_URINARY_DISORDER = 'genito_urinary_disorder',
  GYNAECOLOGICAL_COMPLAINTS = 'gynaecological_complaints',
}

export enum INTERMEDIATE_BOOLEAN {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
  INTERMEDIATE = 'INTERMEDIATE',
}

export enum POLICY_PROPOSAL_LANGUAGES {
  ENGLISH = 'ENGLISH',
  SINHALA = 'SINHALA',
  TAMIL = 'TAMIL',
}

export enum POLICY_PROPOSAL_COMMUNICATION_METHODS {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PHYSICAL_LETTER = 'PHYSICAL_LETTER',
}

export enum POLICY_TYPE_DOCUMENT {
  E_POLICE = 'E_POLICE',
  PRE_PRINTED = 'PRE_PRINTED',
}

export enum POLICY_PROPOSAL_CONTACT_METHODS {
  DIGITAL = 'DIGITAL',
  TRADITIONAL = 'TRADITIONAL',
}
