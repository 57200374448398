import {
  IPerformanceSheetConfig,
  IPerformanceSheetConfigCriteria,
  IPerformanceSheetConfigFilter,
} from '../../performance-sheets/config';

export type ITargetConfigCriteria = IPerformanceSheetConfigCriteria;
export type ITargetConfigFilter = IPerformanceSheetConfigFilter;

export interface ITargetConfig
  extends Omit<IPerformanceSheetConfig, 'criteria'> {
  criteria: ITargetConfigCriteria[];
}

///////

export enum PRINT_VIEW_TEMPLATES {
  TARGET_SHEET = 'targetSheet',
  ACHIEVEMENT_SHEET = 'achievementSheet',
}
