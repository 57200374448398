/*
 * This File contains hardcoded values for the AVO
 */

import {
  BENEFIT_RECEIVING_PERIOD_TYPES,
  FREQUENCY_CODE,
  IProductTypeList,
  IQuotationProduct,
  LIFE_ASSURED,
  MEDICAL_BENEFIT_TYPES,
  PAYMENT_MODE,
  PRODUCT_CODE,
  PRODUCT_TYPE,
  RIDER_CODE,
  RIDER_CODE_DESCRIPTION,
  RIDER_DISPLAY_CODE,
} from '../leads';

import { PROPOSAL_FLOW_MEDICAL_AILMENTS } from './proposal-enums';

export const product_details = [
  { type: PRODUCT_TYPE.NGT01, code: 'NGT01' },
  /* { type: PRODUCT_TYPE.NGT02, code: 'NGT02' }, */
  { type: PRODUCT_TYPE.NJU4, code: 'NJU4' },
  /* { type: PRODUCT_TYPE.JCA01, code: 'JCA01' }, */
  { type: PRODUCT_TYPE.NSW, code: 'NSW' },
  { type: PRODUCT_TYPE.NSPS, code: 'NSPS' },
  { type: PRODUCT_TYPE.LSP, code: 'LSP' },
  { type: PRODUCT_TYPE.NJEV, code: 'NJEV' },
  { type: PRODUCT_TYPE.LI4, code: 'LI4' },
  { type: PRODUCT_TYPE.LIF1, code: 'LIF1' },
  { type: PRODUCT_TYPE.JDS, code: 'JDS' },
];

interface IJanashakthiPremiumPackages {
  SA: number;
  loadingPremim: number;
  loadingtype: string;
  premium: number;
  riderCode: string;
}

export const PRODUCT_TYPE_LIST: IProductTypeList[] = [
  // {
  //   key: PRODUCT_TYPE.JCA01,
  //   code: PRODUCT_CODE.JCA01,
  //   icon: 'janashakthi_cash_advance',
  //   selected: false,
  //   canContinue: true,
  //   disabled: false,
  //   recommended: false,
  //   pre_set: false,
  // },
  {
    key: PRODUCT_TYPE.NJU4,
    code: PRODUCT_CODE.NJU4,
    icon: 'janashakthi_life_unlimited',
    selected: false,
    canContinue: true,
    disabled: false,
    disabled_quote: true,
    recommended: false,
    pre_set: false,
  },
  {
    key: PRODUCT_TYPE.JDS,
    code: PRODUCT_CODE.JDS,
    icon: 'dream_saver',
    selected: false,
    canContinue: true,
    disabled: false,
    disabled_quote: true,
    recommended: false,
    pre_set: false,
  },
  // {
  //   key: PRODUCT_TYPE.NGT02,
  //   code: PRODUCT_CODE.NGT02,
  //   icon: 'family_plus',
  //   selected: false,
  //   canContinue: true,
  //   disabled: false,
  //   recommended: false,
  //   pre_set: false,
  // },
  {
    key: PRODUCT_TYPE.NGT01,
    code: PRODUCT_CODE.NGT01,
    icon: 'jeevitha_vardana',
    selected: false,
    canContinue: true,
    disabled: false,
    disabled_quote: false,
    recommended: false,
    pre_set: false,
  },
  {
    key: PRODUCT_TYPE.NSPS,
    code: PRODUCT_CODE.NSPS,
    icon: 'shilpashakthi',
    selected: false,
    canContinue: true,
    disabled: false,
    disabled_quote: false,
    recommended: false,
    pre_set: false,
  },
  {
    key: PRODUCT_TYPE.NSW,
    code: PRODUCT_CODE.NSW,
    icon: 'suwashakthi',
    selected: false,
    canContinue: true,
    disabled: false,
    disabled_quote: false,
    recommended: false,
    pre_set: false,
  },
  {
    key: PRODUCT_TYPE.LI4,
    code: PRODUCT_CODE.LI4,
    icon: 'janashakthi_investment',
    selected: false,
    canContinue: true,
    disabled: false,
    disabled_quote: false,
    recommended: false,
    pre_set: false,
  },
  {
    key: PRODUCT_TYPE.LSP,
    code: PRODUCT_CODE.LSP,
    icon: 'swarnashakthi',
    selected: false,
    canContinue: true,
    disabled: false,
    disabled_quote: false,
    recommended: false,
    pre_set: false,
  },
  {
    key: PRODUCT_TYPE.NJEV,
    code: PRODUCT_CODE.NJEV,
    icon: 'jeevitha_thilana',
    selected: false,
    canContinue: true,
    disabled: false,
    disabled_quote: false,
    recommended: false,
    pre_set: false,
  },
  {
    key: PRODUCT_TYPE.LIF1,
    code: PRODUCT_CODE.LIF1,
    icon: 'life_investment_flexi_1',
    selected: false,
    canContinue: true,
    disabled: false,
    disabled_quote: true,
    recommended: false,
    pre_set: false,
  },
];

export const PRODUCT_TO_RIDER_MAPPING = [
  {
    key: PRODUCT_CODE.JCA01,
    active_sections: [
      RIDER_CODE.BASIC_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_ACCIDENT_COVER,
      RIDER_CODE.CRITICAL_ILLNESS_COVER,
      RIDER_CODE.FAMILY_INCOME_COVER,
      RIDER_CODE.HOSPITAL_CASH_DAY_COVER,
      RIDER_CODE.FUNERAL_EXPENSES_COVER,
      RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_CODE.NJU4,
    active_sections: [
      RIDER_CODE.BASIC_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_ACCIDENT_COVER,
      RIDER_CODE.CRITICAL_ILLNESS_COVER,
      RIDER_CODE.FAMILY_INCOME_COVER,
      RIDER_CODE.HOSPITAL_CASH_DAY_COVER,
      RIDER_CODE.FUNERAL_EXPENSES_COVER,
      RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_CODE.JDS,
    active_sections: [RIDER_CODE.BASIC_LIFE_COVER],
  },
  {
    key: PRODUCT_CODE.NGT02,
    active_sections: [
      RIDER_CODE.BASIC_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_ACCIDENT_COVER,
      RIDER_CODE.CRITICAL_ILLNESS_COVER,
      RIDER_CODE.FAMILY_INCOME_COVER,
      RIDER_CODE.HOSPITAL_CASH_DAY_COVER,
      RIDER_CODE.FUNERAL_EXPENSES_COVER,
      RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_CODE.NGT01,
    active_sections: [
      RIDER_CODE.BASIC_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_ACCIDENT_COVER,
      RIDER_CODE.ADDITIONAL_LIFE_COVER,
      RIDER_CODE.FAMILY_INCOME_COVER,
      RIDER_CODE.FUNERAL_EXPENSES_COVER,
      RIDER_CODE.HOSPITAL_CASH_DAY_COVER,
      RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS,
      RIDER_CODE.CRITICAL_ILLNESS_COVER,
    ],
  },
  {
    key: PRODUCT_CODE.NSPS,
    active_sections: [
      RIDER_CODE.BASIC_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_ACCIDENT_COVER,
      RIDER_CODE.CRITICAL_ILLNESS_COVER,
      RIDER_CODE.FAMILY_INCOME_COVER,
      RIDER_CODE.HOSPITAL_CASH_DAY_COVER,
      RIDER_CODE.FUNERAL_EXPENSES_COVER,
      RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_CODE.NSW,
    active_sections: [
      RIDER_CODE.BASIC_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_ACCIDENT_COVER,
      RIDER_CODE.CRITICAL_ILLNESS_COVER,
      RIDER_CODE.FAMILY_INCOME_COVER,
      RIDER_CODE.HOSPITAL_CASH_DAY_COVER,
      RIDER_CODE.FUNERAL_EXPENSES_COVER,
      RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_CODE.LI4,
    active_sections: [
      RIDER_CODE.BASIC_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_ACCIDENT_COVER,
      RIDER_CODE.CRITICAL_ILLNESS_COVER,
      RIDER_CODE.FAMILY_INCOME_COVER,
      RIDER_CODE.HOSPITAL_CASH_DAY_COVER,
      RIDER_CODE.FUNERAL_EXPENSES_COVER,
      RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_CODE.LSP,
    active_sections: [RIDER_CODE.BASIC_LIFE_COVER],
  },
  {
    key: PRODUCT_CODE.NJEV,
    active_sections: [RIDER_CODE.BASIC_LIFE_COVER],
  },
  {
    key: PRODUCT_CODE.LIF1,
    active_sections: [
      RIDER_CODE.BASIC_LIFE_COVER,
      RIDER_CODE.ADDITIONAL_ACCIDENT_COVER,
      RIDER_CODE.CRITICAL_ILLNESS_COVER,
      RIDER_CODE.FAMILY_INCOME_COVER,
      RIDER_CODE.HOSPITAL_CASH_DAY_COVER,
      RIDER_CODE.FUNERAL_EXPENSES_COVER,
      RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
];

export const getProductMode = (
  product_code: PRODUCT_CODE
): {
  is_investment: boolean;
  is_policy: boolean;
} => {
  switch (product_code) {
    case PRODUCT_CODE.LI4:
      return { is_investment: true, is_policy: true };

    case PRODUCT_CODE.NGT01:
    case PRODUCT_CODE.NSW:
    case PRODUCT_CODE.NJEV:
    case PRODUCT_CODE.NSPS:
    case PRODUCT_CODE.NJU4:
      return { is_investment: false, is_policy: true };

    default:
      return { is_investment: true, is_policy: true };
  }
};

export const getPaymentMode = (product_code: PRODUCT_CODE): string[] => {
  switch (product_code) {
    case PRODUCT_CODE.NJEV:
      return [
        PAYMENT_MODE.MONTHLY,
        PAYMENT_MODE.QUARTERLY,
        PAYMENT_MODE.HALF_YEARLY,
        PAYMENT_MODE.YEARLY,
        // PAYMENT_MODE.SINGLE,
      ];

    case PRODUCT_CODE.LI4:
      return [
        PAYMENT_MODE.MONTHLY,
        PAYMENT_MODE.QUARTERLY,
        PAYMENT_MODE.HALF_YEARLY,
        PAYMENT_MODE.YEARLY,
        PAYMENT_MODE.SINGLE,
      ];

    case PRODUCT_CODE.LSP:
    case PRODUCT_CODE.JDS:
      return [PAYMENT_MODE.SINGLE];

    default:
      return [
        PAYMENT_MODE.MONTHLY,
        PAYMENT_MODE.QUARTERLY,
        PAYMENT_MODE.HALF_YEARLY,
        PAYMENT_MODE.YEARLY,
      ];
  }
};

export const getProductName = (
  code: PRODUCT_CODE
): PRODUCT_TYPE | undefined => {
  return PRODUCT_TYPE_LIST.find((product) => product.code === code)?.key;
};

export const DISEASES = [
  { id: '1', diseaseName: PROPOSAL_FLOW_MEDICAL_AILMENTS.ARTHRITIS },
  { id: '2', diseaseName: PROPOSAL_FLOW_MEDICAL_AILMENTS.ASTHMA },
  { id: '3', diseaseName: PROPOSAL_FLOW_MEDICAL_AILMENTS.BACK_DISORDER },
  { id: '4', diseaseName: PROPOSAL_FLOW_MEDICAL_AILMENTS.DIABETES },
  { id: '5', diseaseName: PROPOSAL_FLOW_MEDICAL_AILMENTS.EPILEPSY },
  {
    id: '6',
    diseaseName: PROPOSAL_FLOW_MEDICAL_AILMENTS.GENITO_URINARY_DISORDER,
  },
  {
    id: '7',
    diseaseName: PROPOSAL_FLOW_MEDICAL_AILMENTS.GYNAECOLOGICAL_COMPLAINTS,
  },
  { id: '8', diseaseName: PROPOSAL_FLOW_MEDICAL_AILMENTS.HEART_DISEASE },
  { id: '9', diseaseName: PROPOSAL_FLOW_MEDICAL_AILMENTS.HIGH_BLOOD_PRESSURE },
  { id: '10', diseaseName: PROPOSAL_FLOW_MEDICAL_AILMENTS.MUSCULO_SKELETAL },
  {
    id: '11',
    diseaseName: PROPOSAL_FLOW_MEDICAL_AILMENTS.STOMACH_BOWEL_COMPLAINTS,
  },
];

export const getRiderName = (riderCode: RIDER_CODE): string => {
  switch (riderCode) {
    case RIDER_CODE.BASIC_LIFE_COVER:
      return 'Basic Life Cover';

    case RIDER_CODE.ADDITIONAL_ACCIDENT_COVER:
      return 'Additional Accident Cover / TPD / PPD';

    case RIDER_CODE.ACCIDENT_COVER:
      return 'Accident Cover';

    case RIDER_CODE.ADDITIONAL_LIFE_COVER:
      return 'Additional Life Cover';

    case RIDER_CODE.CRITICAL_ILLNESS_COVER:
      return 'Critical Illness Cover';

    case RIDER_CODE.FAMILY_INCOME_COVER:
      return 'Family Income Benefit Cover';

    case RIDER_CODE.FUNERAL_EXPENSES_COVER:
      return 'Funeral Expenses Cover';

    case RIDER_CODE.HOSPITAL_CASH_DAY_COVER:
      return 'Hospital Cash Day Cover';

    case RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS:
      return 'Hospital Reimbursement Cashless Cover';
  }
};

// conditions for Rider Mapping

export const get_FIB_CB_FE_MaximumLimit = (
  sum_assured: number,
  min: number,
  max: number,
  rate: number
): number => {
  const maxLimit = (sum_assured * rate) / 100;
  return maxLimit < min ? 0 : maxLimit > max ? max : maxLimit;
};

const _FIB_entry_value = 5000;
const _FIB_maximum_value = 100000;
const _FIB_rate = 2.5;

export const getFIBMaximumLimit = (
  sum_assured: number,
  min = _FIB_entry_value,
  max = _FIB_maximum_value,
  rate = _FIB_rate
): number => {
  const maxLimit = (sum_assured * rate) / 100;
  return maxLimit < min ? 0 : maxLimit > max ? max : maxLimit;
};

const _CB_entry_value = 1000;
const _CB_maximum_value = 10000;
const _CB_rate = 10;

export const getCashBenefitLimit = (sum_assured: number): number => {
  const maxLimit = (sum_assured * _CB_rate) / 100;
  return maxLimit < _CB_entry_value
    ? 0
    : maxLimit > _CB_maximum_value
    ? _CB_maximum_value
    : maxLimit;
};

const _FE_entry_value = 1000;
const _FE_maximum_value = 10000;
const _FE_rate = 10;

export const getFuneralExpenseLimit = (sum_assured: number): number => {
  const maxLimit = (sum_assured * _FE_rate) / 100;
  return maxLimit < _FE_entry_value
    ? 0
    : maxLimit > _FE_maximum_value
    ? _FE_maximum_value
    : maxLimit;
};

// calculate maximum term for policy

//Reimbursement
// 1. Schemas -> {id(type),sum_assured_value,countries}
// Reimbursement Reference -> fields : {id,sum,countries('string')}

//3 Riders Config
// 1 Reference , 3 Reference ??
//    if 1 by 1 reference , totally 3 -> Reference -> fields : {maxLimit, entryValue, rate}
//    if 1 reference -> fields: {maxLimit, entryValue, rate, rider(string)}
//      * rider -> which rider to apply

// product wise config
//    if 1 reference -> fields: {maxLimit, entryValue, rate, rider(string), product_code (string)}
//        *What contain string
//            *'LI4' -> This should apply for those products

/*  product_info configurations */
//  what to config :-
//    contribution_amount -> dependent with some other values (LI4 -> preferred payment mode, ) ??
//

/* product_info equations */
export const getMaxPolicyTerm = (
  next_age: number,
  ceasing_cover_age: number
): number => {
  return next_age > ceasing_cover_age ? 0 : ceasing_cover_age - next_age;
};

const CEASING_AGE: { [index: string]: number } = {
  [PRODUCT_CODE.LI4]: 65,
};

export const getMaxPolicyTermByProduct = (
  next_age: number,
  product_code: PRODUCT_CODE
): number => {
  return getMaxPolicyTerm(next_age, CEASING_AGE[product_code]);
};

export const MAX_AGE_PRODUCT: {
  [index: string]: { [index: string]: { [index: string]: number } };
} = {
  [PRODUCT_CODE.LI4]: {
    [RIDER_CODE.ADDITIONAL_ACCIDENT_COVER]: {
      [LIFE_ASSURED.MAIN]: 19,
      [LIFE_ASSURED.SPOUSE]: 19,
      [LIFE_ASSURED.DEPENDENT]: 19,
    },
    [RIDER_CODE.CRITICAL_ILLNESS_COVER]: {
      [LIFE_ASSURED.MAIN]: 19,
      [LIFE_ASSURED.SPOUSE]: 19,
      [LIFE_ASSURED.DEPENDENT]: 19,
    },
    [RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS]: {
      [LIFE_ASSURED.MAIN]: 19,
      [LIFE_ASSURED.SPOUSE]: 19,
      [LIFE_ASSURED.DEPENDENT]: 19,
    },
    [RIDER_CODE.FUNERAL_EXPENSES_COVER]: {
      [LIFE_ASSURED.MAIN]: 19,
      [LIFE_ASSURED.SPOUSE]: 19,
      [LIFE_ASSURED.DEPENDENT]: 19,
    },
    [RIDER_CODE.HOSPITAL_CASH_DAY_COVER]: {
      [LIFE_ASSURED.MAIN]: 19,
      [LIFE_ASSURED.SPOUSE]: 19,
      [LIFE_ASSURED.DEPENDENT]: 19,
    },
  },
};

export const MIN_AGE_PRODUCT: {
  [index: string]: { [index: string]: { [index: string]: number } };
} = {
  [PRODUCT_CODE.LI4]: {
    [RIDER_CODE.ADDITIONAL_ACCIDENT_COVER]: {
      [LIFE_ASSURED.MAIN]: 19,
      [LIFE_ASSURED.SPOUSE]: 19,
      [LIFE_ASSURED.DEPENDENT]: 19,
    },
    [RIDER_CODE.CRITICAL_ILLNESS_COVER]: {
      [LIFE_ASSURED.MAIN]: 19,
      [LIFE_ASSURED.SPOUSE]: 19,
      [LIFE_ASSURED.DEPENDENT]: 19,
    },
    [RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS]: {
      [LIFE_ASSURED.MAIN]: 19,
      [LIFE_ASSURED.SPOUSE]: 19,
      [LIFE_ASSURED.DEPENDENT]: 19,
    },
    [RIDER_CODE.FUNERAL_EXPENSES_COVER]: {
      [LIFE_ASSURED.MAIN]: 19,
      [LIFE_ASSURED.SPOUSE]: 19,
      [LIFE_ASSURED.DEPENDENT]: 19,
    },
    [RIDER_CODE.HOSPITAL_CASH_DAY_COVER]: {
      [LIFE_ASSURED.MAIN]: 19,
      [LIFE_ASSURED.SPOUSE]: 19,
      [LIFE_ASSURED.DEPENDENT]: 19,
    },
  },
};

//

export const getRiderCodeDescription = (riderCode: string): string => {
  switch (riderCode) {
    case RIDER_CODE.BASIC_LIFE_COVER:
      return RIDER_CODE_DESCRIPTION.BASIC_LIFE_COVER;
    case RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS:
      return RIDER_CODE_DESCRIPTION.HOSPITAL_REIMBURSEMENT_CASHLESS;
    case RIDER_CODE.ADDITIONAL_ACCIDENT_COVER:
      return RIDER_CODE_DESCRIPTION.ADDITIONAL_ACCIDENT_COVER;
    case RIDER_CODE.FAMILY_INCOME_COVER:
      return RIDER_CODE_DESCRIPTION.FAMILY_INCOME_COVER;
    case RIDER_CODE.CRITICAL_ILLNESS_COVER:
      return RIDER_CODE_DESCRIPTION.CRITICAL_ILLNESS_COVER;
    case RIDER_CODE.HOSPITAL_CASH_DAY_COVER:
      return RIDER_CODE_DESCRIPTION.HOSPITAL_CASH_DAY_COVER;
    case RIDER_CODE.FUNERAL_EXPENSES_COVER:
      return RIDER_CODE_DESCRIPTION.FUNERAL_EXPENSES_COVER;
    case RIDER_CODE.ADDITIONAL_LIFE_COVER:
      return RIDER_CODE_DESCRIPTION.ADDITIONAL_LIFE_COVER;
    default:
      return '';
  }
};

export const getRiderDisplayCode = (riderCode: string): string => {
  switch (riderCode) {
    case RIDER_CODE.BASIC_LIFE_COVER:
      return RIDER_DISPLAY_CODE.BASIC_LIFE_COVER;
    case RIDER_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS:
      return RIDER_DISPLAY_CODE.HOSPITAL_REIMBURSEMENT_CASHLESS;
    case RIDER_CODE.ADDITIONAL_ACCIDENT_COVER:
      return RIDER_DISPLAY_CODE.ADDITIONAL_ACCIDENT_COVER;
    case RIDER_CODE.FAMILY_INCOME_COVER:
      return RIDER_DISPLAY_CODE.FAMILY_INCOME_COVER;
    case RIDER_CODE.CRITICAL_ILLNESS_COVER:
      return RIDER_DISPLAY_CODE.CRITICAL_ILLNESS_COVER;
    case RIDER_CODE.HOSPITAL_CASH_DAY_COVER:
      return RIDER_DISPLAY_CODE.HOSPITAL_CASH_DAY_COVER;
    case RIDER_CODE.FUNERAL_EXPENSES_COVER:
      return RIDER_DISPLAY_CODE.FUNERAL_EXPENSES_COVER;
    case RIDER_CODE.ADDITIONAL_LIFE_COVER:
      return RIDER_DISPLAY_CODE.ADDITIONAL_LIFE_COVER;
    default:
      return '';
  }
};

export const getWarningMessageForProduct = (
  product_code: PRODUCT_CODE
): string | undefined => {
  switch (product_code) {
    case PRODUCT_CODE.LI4:
      return 'leads.quotation-issue.risk-info.li4-warn';
    case PRODUCT_CODE.NGT01:
      return 'leads.quotation-issue.risk-info.ngt01-warn';
    case PRODUCT_CODE.NJEV:
      return 'leads.quotation-issue.risk-info.njev-warn';
    case PRODUCT_CODE.LIF1:
      return 'leads.quotation-issue.risk-info.lif1-warn';
    case PRODUCT_CODE.LSP:
      return 'leads.quotation-issue.risk-info.lsp-warn';
    case PRODUCT_CODE.NSPS:
      return 'leads.quotation-issue.risk-info.nsps-warn';
    case PRODUCT_CODE.NJU4:
      return 'leads.quotation-issue.risk-info.nju4-warn';
    case PRODUCT_CODE.NSW:
      return 'leads.quotation-issue.risk-info.nsw-warn';
    case PRODUCT_CODE.JDS:
      return 'leads.quotation-issue.risk-info.jds-warn';
    default:
      return undefined;
  }
};

export const isPackageProduct = (product_code: PRODUCT_CODE): boolean => {
  switch (product_code) {
    case PRODUCT_CODE.NSPS:
    case PRODUCT_CODE.LSP:
    case PRODUCT_CODE.NJU4:
      return true;

    default:
      return false;
  }
};

export const convertModeIntoJData = (
  payment_mode: PAYMENT_MODE
): FREQUENCY_CODE => {
  switch (payment_mode) {
    case PAYMENT_MODE.HALF_YEARLY:
      return FREQUENCY_CODE.HALF_YEARLY;

    case PAYMENT_MODE.YEARLY:
      return FREQUENCY_CODE.ANNUAL;

    case PAYMENT_MODE.MONTHLY:
      return FREQUENCY_CODE.MONTHLY;

    case PAYMENT_MODE.QUARTERLY:
      return FREQUENCY_CODE.QUARTERLY;

    case PAYMENT_MODE.SINGLE:
      return FREQUENCY_CODE.SINGLE;
  }
};

export const getPackageBenefit = (
  product_code: PRODUCT_CODE,
  packages: IJanashakthiPremiumPackages[],
  bsa_value?: number,
  product_info?: IQuotationProduct
): { name: string; value: string }[] => {
  switch (product_code) {
    case PRODUCT_CODE.NSPS:
    case PRODUCT_CODE.LSP:
      if (!packages) return [];
      return [
        {
          name: 'PAC - ACCIDENT COVER',
          value: convertToLocale(
            packages?.find(
              (rider) => rider.riderCode === RIDER_CODE.ACCIDENT_COVER
            )?.SA
          ),
        },
      ];

    case PRODUCT_CODE.NJU4:
      if (!bsa_value) return [];
      return [
        {
          name: 'PAC - ACCIDENT COVER',
          value: convertToLocale(bsa_value * 2),
        },
        {
          name: 'CIC - CRITICAL ILLNESS COVER',
          value: convertToLocale(bsa_value),
        },
        getMedicalCoverPlanPackage(
          product_info?.medical_benefit,
          bsa_value,
          product_info?.benefit_receiving_period ===
            BENEFIT_RECEIVING_PERIOD_TYPES.AFTER_MATURITY
        ),
      ];

    default:
      return [];
  }
};

const getMedicalCoverPlanPackage = (
  medical_benefit_type: MEDICAL_BENEFIT_TYPES | undefined,
  value: number,
  is_doubled: boolean
): { name: string; value: string } => {
  const calculated_benefit =
    value *
    (medical_benefit_type === MEDICAL_BENEFIT_TYPES.REIMBURSEMENT
      ? 0.2
      : 0.005);
  const maximum =
    (is_doubled ? 2 : 1) *
    (medical_benefit_type === MEDICAL_BENEFIT_TYPES.REIMBURSEMENT
      ? 500000
      : 25000);
  return {
    name:
      medical_benefit_type === MEDICAL_BENEFIT_TYPES.PER_DAY_COVER
        ? 'HCB - HOSPITAL COVER'
        : 'HR - HOSPITAL REIMBURSEMENT',
    value: convertToLocale(
      calculated_benefit < maximum ? calculated_benefit : maximum
    ),
  };
};

const convertToLocale = (inputNumber: number | undefined): string => {
  return (
    inputNumber?.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }) ?? '0.00'
  );
};
