import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import {
  INTEGRATION_PERMISSION_ACTIONS,
  MODULES,
  PERMISSION_ACTIONS,
} from '@shared/constants';
import { getModulePermission } from '@shared/utils';

import {
  LoggedUserService,
  UserInfoResponseDTO,
} from '../../modules/auth/services';
import { ModulesService } from '../../modules/modules/services/modules.service';
import {
  IAM_STATUS,
  IamConfigService,
} from '../../modules/setup/iam-config/services/iam-config.service';
import {
  DMS_STATUS,
  StorageSetupService,
} from '../../modules/setup/storage/services/storage-setup.service';
import { FlowControlService } from '../../services/flow-control.service';
import {
  IShowModule,
  PermissionFiltrationService,
} from '../../services/permission-filtration.service';

export interface IModuleIconData {
  routerLink: string;
  tooltip: string;
  image: string;
  alt: string;
  title: string;
  searchKey: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  moduleFilterValue = '';
  filteredModuleCards = [];
  loggedUserInfo: UserInfoResponseDTO;
  noticeCount = 0;
  isNoticesModuleEnabled = false;
  isNoticesModuleReadPermission = false;
  private subscriptions = new Subscription();

  isInternalIAM = true;
  isInternalStorage = true;

  isEmptyModules = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    route: ActivatedRoute,
    flowControlService: FlowControlService,
    private permissionFiltrationService: PermissionFiltrationService,
    private loggedUserService: LoggedUserService,
    private modulesService: ModulesService,
    private storageConfigService: StorageSetupService,
    private iamConfigService: IamConfigService
  ) {
    flowControlService.updateRouteInfo(
      router.url.split('?')[0],
      route.snapshot.data
    );
  }

  ngOnInit() {
    this.initializeLoggedUserData();
    this.initializeModulesData();
    this.router.navigate([], {
      queryParams: {
        returnUrl: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeLoggedUserData(): void {
    this.loggedUserService.dataStore.subscribe((data) => {
      this.loggedUserInfo = data;
      this.isNoticesModuleReadPermission =
        this.permissionFiltrationService.validatePermissions({
          permissions: getModulePermission(MODULES.NOTICES, [
            PERMISSION_ACTIONS.READ,
          ]),
        });
      this.initializeIamConfig();
      this.initializeConnectedStorageConfig();
      this.getAllModules();
    });
  }

  initializeModulesData(): void {
    this.subscriptions.add(
      this.modulesService
        .listenToAvailability([MODULES.NOTICES])
        .subscribe((change) => {
          this.isNoticesModuleEnabled = change;
        })
    );
  }

  private initializeIamConfig(): void {
    this.subscriptions.add(
      this.iamConfigService.dataStore.subscribe((data) => {
        const iamStatus = data?.iam?.status;
        if (
          iamStatus === IAM_STATUS.INTERNAL ||
          iamStatus === IAM_STATUS.INTERNAL_DISCONNECTED ||
          iamStatus === IAM_STATUS.INTERNAL_PENDING
        ) {
          this.isInternalIAM = true;
        } else {
          this.isInternalIAM = false;
        }
      })
    );
  }

  private initializeConnectedStorageConfig(): void {
    this.subscriptions.add(
      this.storageConfigService.dataStore.subscribe((data) => {
        const storageStatus = data?.storage?.status;
        if (
          storageStatus === DMS_STATUS.INTERNAL ||
          storageStatus === DMS_STATUS.INTERNAL_DISCONNECTED ||
          storageStatus === DMS_STATUS.INTERNAL_PENDING ||
          storageStatus === DMS_STATUS.EXTERNAL_PENDING
        ) {
          this.isInternalStorage = true;
        } else {
          this.isInternalStorage = false;
        }
      })
    );
  }
  getAllModules() {
    this.translate
      .get([
        'pages.home.leads',
        'pages.home.leads-module-tooltip',
        'pages.home.activities',
        'pages.home.activities-module-tooltip',
        'pages.home.competitions',
        'pages.home.competitions-module-tooltip',
        'pages.home.agents',
        'pages.home.agents-module-tooltip',
        'pages.home.targets',
        'pages.home.targets-module-tooltip',
        'pages.home.achievements',
        'pages.home.achievements-module-tooltip',
        'pages.home.recruitments',
        'pages.home.recruitments-module-tooltip',
        'pages.home.setup',
        'pages.home.setup-module-tooltip',
        'pages.home.knowledgebase',
        'pages.home.knowledgebase-module-tooltip',
        'pages.home.logs',
        'pages.home.logs-module-tooltip',
        'pages.home.permissions',
        'pages.home.permissions-module-tooltip',
        'pages.home.identities',
        'pages.home.identities-module-tooltip',
        'pages.home.modules',
        'pages.home.modules-module-tooltip',
        'pages.home.messages',
        'pages.home.messages-module-tooltip',
        'pages.home.apps',
        'pages.home.apps-module-tooltip',
        'pages.home.storages',
        'pages.home.storages-module-tooltip',
        'pages.home.connections',
        'pages.home.connections-module-tooltip',
        'pages.home.roles',
        'pages.home.roles-module-tooltip',
        'pages.home.drives',
        'pages.home.drives-module-tooltip',
        'pages.home.recycleBin',
        'pages.home.recycleBin-module-tooltip',
        'pages.home.notices',
        'pages.home.proposals-module-tooltip',
        'pages.home.proposals',
        'pages.home.endorsements-module-tooltip',
        'pages.home.endorsements',
        'pages.home.policies-module-tooltip',
        'pages.home.cover-notes-face',
        'pages.home.cover-notes-module-tooltip',
        'pages.home.claims-face',
        'pages.home.claims-module-tooltip',
        'pages.home.customers-face',
        'pages.home.customers-module-tooltip',
        'pages.home.invoices-face',
        'pages.home.invoices-module-tooltip',
        'pages.home.receipts-face',
        'pages.home.receipts-module-tooltip',
        'pages.home.chart-of-accounts-face',
        'pages.home.chart-of-accounts-module-tooltip',
        'pages.home.entries-face',
        'pages.home.entries-module-tooltip',
        'pages.home.payouts-face',
        'pages.home.payouts-module-tooltip',
        'pages.home.endpoints-face',
        'pages.home.endpoints-module-tooltip',
        'pages.home.mappings-face',
        'pages.home.mappings-module-tooltip',
      ])
      .subscribe((translations) => {
        const allModuleCards: IShowModule[] = [
          //--------AVO related--------
          {
            data: {
              routerLink: '/app/leads',
              tooltip: translations['pages.home.leads-module-tooltip'],
              image: 'assets/modules/svg/Leads.svg',
              alt: 'leads',
              title: translations['pages.home.leads'],
              searchKey: 'leads',
            },
            allowedPermissions: getModulePermission(MODULES.LEADS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/activities',
              tooltip: translations['pages.home.activities-module-tooltip'],
              image: 'assets/modules/svg/Activities.svg',
              alt: 'activities',
              title: translations['pages.home.activities'],
              searchKey: 'activities',
            },
            allowedPermissions: getModulePermission(MODULES.ACTIVITIES, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/competitions',
              tooltip: translations['pages.home.competitions-module-tooltip'],
              image: 'assets/modules/svg/Competitions.svg',
              alt: 'competitions',
              title: translations['pages.home.competitions'],
              searchKey: 'competitions',
            },
            allowedPermissions: getModulePermission(MODULES.COMPETITIONS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/agents',
              tooltip: translations['pages.home.agents-module-tooltip'],
              image: 'assets/modules/svg/Agents.svg',
              alt: 'agents',
              title: translations['pages.home.agents'],
              searchKey: 'agents',
            },
            allowedPermissions: getModulePermission(MODULES.AGENTS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/targets',
              tooltip: translations['pages.home.targets-module-tooltip'],
              image: 'assets/modules/svg/Targets.svg',
              alt: 'targets',
              title: translations['pages.home.targets'],
              searchKey: 'targets',
            },
            allowedPermissions: getModulePermission(MODULES.TARGETS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/achievements',
              tooltip: translations['pages.home.achievements-module-tooltip'],
              image: 'assets/modules/svg/Achievements.svg',
              alt: 'achievements',
              title: translations['pages.home.achievements'],
              searchKey: 'achievements',
            },
            allowedPermissions: getModulePermission(MODULES.ACHIEVEMENTS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/recruitments',
              tooltip: translations['pages.home.recruitments-module-tooltip'],
              image: 'assets/modules/svg/Recruitments.svg',
              alt: 'recruitments',
              title: translations['pages.home.recruitments'],
              searchKey: 'recruitments',
            },
            allowedPermissions: getModulePermission(MODULES.RECRUITMENTS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/payouts',
              tooltip: translations['pages.home.payouts-module-tooltip'],
              image: 'assets/modules/svg/Payouts.svg',
              alt: 'payouts',
              title: translations['pages.home.payouts-face'],
              searchKey: 'payouts',
            },
            allowedPermissions: getModulePermission(MODULES.PAYOUTS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          //---------End FOMS related-------------

          //---------BOMS related-----------------
          {
            data: {
              routerLink: '/app/customers',
              tooltip: translations['pages.home.customers-module-tooltip'],
              image: 'assets/modules/svg/Customers.svg',
              alt: 'leads',
              title: translations['pages.home.customers-face'],
              searchKey: 'leads',
            },
            allowedPermissions: getModulePermission(MODULES.CUSTOMERS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/policies',
              tooltip: translations['pages.home.proposals-module-tooltip'],
              image: 'assets/modules/svg/policy.svg',
              alt: 'leads',
              title: translations['pages.home.proposals'],
              searchKey: 'leads',
            },
            allowedPermissions: getModulePermission(MODULES.PROPOSALS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/cover-notes',
              tooltip: translations['pages.home.cover-notes-module-tooltip'],
              image: 'assets/modules/svg/CoverNote.svg',
              alt: 'cover-notes',
              title: translations['pages.home.cover-notes-face'],
              searchKey: 'cover-notes',
            },
            allowedPermissions: getModulePermission(MODULES.COVER_NOTES, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/claims',
              tooltip: translations['pages.home.claims-module-tooltip'],
              image: 'assets/modules/svg/Claims.svg',
              alt: 'claims',
              title: translations['pages.home.claims-face'],
              searchKey: 'claims',
            },
            allowedPermissions: getModulePermission(MODULES.CLAIMS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/endorsements',
              tooltip: translations['pages.home.endorsements-module-tooltip'],
              image: 'assets/modules/svg/Endorsements.svg',
              alt: 'endorsements',
              title: translations['pages.home.endorsements'],
              searchKey: 'endorsements',
            },
            allowedPermissions: getModulePermission(MODULES.ENDORSEMENTS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              searchKey: 'invoices',
              title: translations['pages.home.invoices-face'],
              routerLink: '/app/invoices',
              tooltip: translations['pages.home.invoices-module-tooltip'],
              image: 'assets/modules/svg/Invoices.svg',
              alt: ' local_atm',
            },
            allowedPermissions: getModulePermission(MODULES.INVOICES, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              searchKey: 'receipts',
              title: translations['pages.home.receipts-face'],
              routerLink: '/app/receipts',
              tooltip: translations['pages.home.receipts-module-tooltip'],
              image: 'assets/modules/svg/Receipt.svg',
              alt: 'receipts',
            },
            allowedPermissions: getModulePermission(MODULES.RECEIPTS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          //---------End of BOMS related-------------
          //---------Accounting related-----------------
          {
            data: {
              searchKey: 'chart-of-accounts',
              title: translations['pages.home.chart-of-accounts-face'],
              routerLink: '/app/chart-of-accounts',
              tooltip:
                translations['pages.home.chart-of-accounts-module-tooltip'],
              image: 'assets/modules/svg/Chart-of-Accounts.svg',
              alt: 'chart-of-accounts',
            },
            allowedPermissions: getModulePermission(MODULES.CHART_OF_ACCOUNTS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              searchKey: 'entries',
              title: translations['pages.home.entries-face'],
              routerLink: '/app/entries',
              tooltip: translations['pages.home.entries-module-tooltip'],
              image: 'assets/modules/svg/Entries.svg',
              alt: 'entries',
            },
            allowedPermissions: getModulePermission(MODULES.ENTRIES, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          //---------End of Accounting related-------------
          {
            data: {
              routerLink: '/app/modules',
              tooltip: translations['pages.home.modules-module-tooltip'],
              image: 'assets/modules/svg/Modules.svg',
              alt: 'modules',
              title: translations['pages.home.modules'],
              searchKey: 'modules',
            },
            allowedPermissions: getModulePermission(MODULES.MODULES, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/apps',
              tooltip: translations['pages.home.apps-module-tooltip'],
              image: 'assets/modules/svg/Apps.svg',
              alt: 'apps',
              title: translations['pages.home.apps'],
              searchKey: 'apps',
            },
            allowedPermissions: getModulePermission(MODULES.APPS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/authorization/permissions',
              tooltip: translations['pages.home.permissions-module-tooltip'],
              image: 'assets/modules/svg/Permissions.svg',
              alt: 'permissions',
              title: translations['pages.home.permissions'],
              searchKey: 'permissions',
            },
            allowedPermissions: getModulePermission(
              MODULES.AUTHORIZATION_PERMISSIONS,
              [PERMISSION_ACTIONS.READ]
            ),
          },
          {
            data: {
              routerLink: '/app/authorization/roles',
              tooltip: translations['pages.home.roles-module-tooltip'],
              image: 'assets/modules/svg/Roles.svg',
              alt: 'roles',
              title: translations['pages.home.roles'],
              searchKey: 'roles',
            },
            allowedPermissions: getModulePermission(
              MODULES.AUTHORIZATION_ROLES,
              [PERMISSION_ACTIONS.READ]
            ),
          },
          {
            data: {
              routerLink: '/app/integrations/endpoints',
              tooltip: translations['pages.home.endpoints-module-tooltip'],
              image: 'assets/modules/svg/Endpoints.svg',
              alt: 'endpoints',
              title: translations['pages.home.endpoints-face'],
              searchKey: 'endpoints',
            },
            allowedPermissions: getModulePermission(MODULES.IDENTITIES, [
              INTEGRATION_PERMISSION_ACTIONS.READ_ENDPOINTS,
            ]),
          },
          {
            data: {
              routerLink: '/app/integrations/mappings',
              tooltip: translations['pages.home.mappings-module-tooltip'],
              image: 'assets/modules/svg/Mappings.svg',
              alt: 'mappings',
              title: translations['pages.home.mappings-face'],
              searchKey: 'mappings',
            },
            allowedPermissions: getModulePermission(MODULES.IDENTITIES, [
              INTEGRATION_PERMISSION_ACTIONS.READ_MAPPINGS,
            ]),
          },
          {
            data: {
              routerLink: '/app/identities',
              tooltip: translations['pages.home.identities-module-tooltip'],
              image: 'assets/modules/svg/Identity.svg',
              alt: 'identities',
              title: translations['pages.home.identities'],
              searchKey: 'identities',
            },
            allowedPermissions: getModulePermission(MODULES.IDENTITIES, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/connections',
              tooltip: translations['pages.home.connections-module-tooltip'],
              image: 'assets/modules/svg/Connections.svg',
              alt: 'connections',
              title: translations['pages.home.connections'],
              searchKey: 'connections',
            },
            allowedPermissions: getModulePermission(MODULES.CONNECTIONS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/messages',
              tooltip: translations['pages.home.messages-module-tooltip'],
              image: 'assets/modules/svg/Messages.svg',
              alt: 'messages',
              title: translations['pages.home.messages'],
              searchKey: 'messages',
            },
            allowedPermissions: getModulePermission(MODULES.MESSAGES, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/storage/drives',
              tooltip: translations['pages.home.drives-module-tooltip'],
              image: 'assets/modules/svg/Drive.svg',
              alt: 'drives',
              title: translations['pages.home.drives'],
              searchKey: 'drives',
            },
            allowedPermissions: getModulePermission(MODULES.STORAGE_DRIVE, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/storage/trash',
              tooltip: translations['pages.home.recycleBin-module-tooltip'],
              image: 'assets/modules/svg/Recycle Bin.svg',
              alt: 'recycle bin',
              title: translations['pages.home.recycleBin'],
              searchKey: 'trash',
            },
            allowedPermissions: getModulePermission(
              MODULES.STORAGE_RECYCLE_BIN,
              [PERMISSION_ACTIONS.READ]
            ),
          },
          {
            data: {
              routerLink: '/app/notices',
              tooltip: translations['pages.home.notices-module-tooltip'],
              image: 'assets/modules/svg/News.svg',
              alt: 'notices',
              title: translations['pages.home.notices'],
              searchKey: 'notices',
            },
            allowedPermissions: getModulePermission(MODULES.NOTICES, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/knowledgebase',
              tooltip: translations['pages.home.knowledgebase-module-tooltip'],
              image: 'assets/modules/svg/Knowledgebase.svg',
              alt: 'knowledgebase',
              title: translations['pages.home.knowledgebase'],
              searchKey: 'knowledgebase articles',
            },
            allowedPermissions: getModulePermission(MODULES.KNOWLEDGEBASE, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/logs',
              tooltip: translations['pages.home.logs-module-tooltip'],
              image: 'assets/modules/svg/Logs.svg',
              alt: 'logs',
              title: translations['pages.home.logs'],
              searchKey: 'logs',
            },
            allowedPermissions: getModulePermission(MODULES.LOGS, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
          {
            data: {
              routerLink: '/app/setup/general',
              tooltip: translations['pages.home.setup-module-tooltip'],
              image: 'assets/modules/svg/Setup.svg',
              alt: 'setup',
              title: translations['pages.home.setup'],
              searchKey: 'setup',
            },
            allowedPermissions: getModulePermission(MODULES.SETUP, [
              PERMISSION_ACTIONS.READ,
            ]),
          },
        ];

        let filteredModules = allModuleCards;

        if (!this.isInternalIAM) {
          filteredModules = filteredModules.filter((module) => {
            return !(
              module.data.routerLink.includes('/app/identities') ||
              module.data.routerLink.includes('/app/connections') ||
              module.data.routerLink.includes('/app/apps')
            );
          });
        }

        if (!this.isInternalStorage) {
          filteredModules = filteredModules.filter(
            (module) => !module.data.routerLink.includes('/app/storage/')
          );
        }

        if (this.loggedUserInfo) {
          this.filteredModuleCards =
            this.permissionFiltrationService.filterModules(filteredModules);
        } else {
          this.filteredModuleCards = [];
        }
      });
  }

  filter(str: string) {
    return (
      str.toLowerCase().search(this.moduleFilterValue.trim().toLowerCase()) > -1
    );
  }

  getNoticeCount(count) {
    this.noticeCount = count;
  }

  checkFilterEmpty() {
    this.isEmptyModules =
      this.filteredModuleCards.find((value) => this.filter(value.searchKey)) ==
      undefined;
  }
}
