export enum AUTH_CONFIG_KEYS {
  JWT_SECRET = 'JWT_SECRET',
  CLIENT_CREDENTIAL_SECRET = 'CLIENT_CREDENTIAL_SECRET',
  ENTITY_PUBLIC_SHARE_SECRET = 'ENTITY_PUBLIC_SHARE_SECRET',
  ACCESS_TOKEN_DURATION = 'ACCESS_TOKEN_DURATION',
  REFRESH_TOKEN_DURATION = 'REFRESH_TOKEN_DURATION',
  REFRESH_TOKEN_OFFLINE_DURATION = 'REFRESH_TOKEN_OFFLINE_DURATION',
  VERIFICATION_TOKEN_DURATION = 'VERIFICATION_TOKEN_DURATION',
  CLIENT_CREDENTIAL_ACCESS_TOKEN_DURATION = 'CLIENT_CREDENTIAL_ACCESS_TOKEN_DURATION',
}

export enum SMS_CONFIG_KEYS {
  OTP_MESSAGE = 'OTP_MESSAGE',
  PAYMENT_MESSAGE = 'PAYMENT_MESSAGE',
  OTP_MESSAGE_SECTION_TWO = 'OTP_MESSAGE_SECTION_TWO',
  OTP_DURATION_IN_MINUTE = 'OTP_DURATION_IN_MINUTE',
  VERIFY_TOKEN_DURATION_IN_MINUTE = 'VERIFY_TOKEN_DURATION_IN_MINUTE',
  ENTITY_SHARE_LINK_MESSAGE_SECTION_ONE = 'ENTITY_SHARE_LINK_MESSAGE_SECTION_ONE',
  ENTITY_SHARE_LINK_MESSAGE_SECTION_TWO = 'ENTITY_SHARE_LINK_MESSAGE_SECTION_TWO',
  OTP_MESSAGE_SECTION_PAYMENT = 'OTP_MESSAGE_SECTION_PAYMENT',
  OTP_MESSAGE_SECTION_PAYMENT_PROPOSAL_NUMBER = 'OTP_MESSAGE_SECTION_PAYMENT_PROPOSAL_NUMBER',
  OTP_MESSAGE_THANK_YOU_JANASHAKTHI = 'OTP_MESSAGE_THANK_YOU_JANASHAKTHI',
}

export enum PASSWORD_RESET_STRATEGY {
  OTP = 'OTP',
  LINK = 'LINK',
}

export enum PASSWORD_RESET_METHOD {
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

export enum AUTHORIZER_TYPES {
  DEFAULT = 'DEFAULT',
  AZURE_AD = 'A_AD',
}

export enum GRANT_TYPE {
  REFRESH_TOKEN = 'token',
  CODE = 'code',
}

export enum CODE_CHALLENGE_METHODS {
  S256 = 'S256',
  PLAIN = 'plain',
}

export enum AUTH_TYPE {
  OAUTH_2_PKCE = 'O_PKCE',
  TOKEN_MODE = 'T_MODE',
}
