// To mitigate circular dependency
export type IQuotationProduct = {
  [K in PRODUCT_INFO as Exclude<
    K,
    PRODUCT_INFO.THIRD_PARTY
  >]?: QuotationProductsType<K>;
};

export type QuotationProductsType<T> = T extends
  | PRODUCT_INFO.POLICY_TERM
  | PRODUCT_INFO.PREMIUM_TERM
  ? number
  : T extends
      | PRODUCT_INFO.SPOUSE_COVERED
      | PRODUCT_INFO.SELF_COVERED
      | PRODUCT_INFO.DEPENDENT_COVERED
      | PRODUCT_INFO.BUSINESS_PERSON_COVERED
      | PRODUCT_INFO.COVERED_TO_BE_INCLUDED
      | PRODUCT_INFO.MORE_LIFE_COVER
  ? boolean
  : T extends PRODUCT_INFO.COVER_TYPE
  ? QUOTATION_COVER_TYPES
  : T extends PRODUCT_INFO.CASHLESS_FAMILY_TYPE
  ? CASHLESS_FAMILY_FLAGS
  : T extends PRODUCT_INFO.MEDICAL_BENEFIT
  ? MEDICAL_BENEFIT_TYPES
  : T extends PRODUCT_INFO.BENEFIT_RECEIVING_PERIOD
  ? BENEFIT_RECEIVING_PERIOD_TYPES
  : T extends PRODUCT_INFO.REIMBURSEMENT_COVER_TYPE
  ? REIMBURSEMENT_COVER_TYPES
  : string;

export interface IProductTypeList {
  key: PRODUCT_TYPE;
  code: PRODUCT_CODE;
  icon: string;
  selected: boolean;
  canContinue: boolean;
  disabled: boolean;
  disabled_quote: boolean;
  recommended: boolean;
  pre_set: boolean;
}

export enum LEAD_TYPES {
  CUSTOMER = 'CUSTOMER',
  RECRUITMENT = 'RECRUITMENT',
}

// IMPORTANT: Keep in sync with LEAD_TYPES
export enum LEADS_DEFAULT_ADDITIONAL_FIELDS_LEAD_TYPE_DROPDOWN_OPTIONS {
  CUSTOMER = 'Customer',
  RECRUITMENT = 'Recruitment',
}

export enum LEAD_STATUSES {
  SUSPECT = 'SUSPECT',
  PROSPECT = 'PROSPECT',
  QUOTATION = 'QUOTATION',
  QUOTATION_INPROGRESS = 'QUOTATION_INPROGRESS',
  REJECTED = 'REJECTED',
  REASSIGNED = 'REASSIGNED',
  REFER_NEXT_INTERVIEW = 'REFER_NEXT_INTERVIEW',
  REFER_TO_EXAM = 'REFER_TO_EXAM',
  RETURN = 'RETURN',
  NEED_ANALYSIS_PENDING = 'NEED_ANALYSIS_PENDING',
  NEED_ANALYSIS = 'NEED_ANALYSIS',
  RETURN_DOCUMENT = 'RETURN_DOCUMENT',
  PENDING_CODE_CREATION = 'PENDING_CODE_CREATION',
  RECRUITED = 'RECRUITED',
  INCOMPLETE = 'INCOMPLETE', // for public api
  PROPOSAL_SUBMITTED = 'PROPOSAL_SUBMITTED',
  POLICY_ISSUED = 'POLICY_ISSUED',
}

// export enum POLICY_STATUSES {
//   PROPOSAL = 'PROPOSAL',
//   PROPOSAL_PENDING = 'PROPOSAL_PENDING',
//   PAYMENT_PENDING = 'PENDING_PAYMENT',
//   PAYMENT_FAILED = 'PAYMENT_FAILED',
//   PROPOSAL_COMPLETED = 'PROPOSAL_COMPLETED',
//   QUICK_QUOTE = 'QUICK_QUOTE',
//   QUOTATION_INPROGRESS = 'QUOTATION (IN PROGRESS)',
// }
// export interface ICustomerInformationFormGroup {
//   name: string;
//   nic_number: string;
//   date_of_birth: Date;
//   age: number;
//   gender: GENDERS;
//   email: string;
//   profession_nature: PROFESSION_NATURE;
//   is_insurance_cover_included: boolean;
//   is_spouse_covered: boolean;
//   spouse_date_of_birth: Date;
//   spouse_age: GENDERS;
//   spouse_gender: string;
//   spouse_profession_nature: PROFESSION_NATURE;
//   is_dependents_covered: boolean;
//   //dependents: IQuotationRiderDependentDetails[];
//   cashless_cover_type: CASHLESS_COVER_TYPES;
// }

export enum RECRUITMENT_STATUSES {
  EXITED = 'EXITED',
  PENDING_CODE_CREATION = 'PENDING_CODE_CREATION',
  PENDING_CODE_CREATION_REJECTED = 'PENDING_CODE_CREATION_REJECTED',
  PENDING_INTERVIEW = 'PENDING_INTERVIEW',
  RECRUITED = 'RECRUITED',
  REFER_NEXT_INTERVIEW = 'REFER_NEXT_INTERVIEW',
  REFER_TO_EXAM = 'REFER_TO_EXAM',
  REJECTED = 'REJECTED',
  RETURN = 'RETURN',
  RETURN_DOCUMENT = 'RETURN_DOCUMENT',
}
// export enum CASHLESS_COVER_TYPES {
//   NONE = 'NONE',
//   FAMILY = 'FAMILY',
//   SELF = 'SELF',
// }
export enum AGENT_ASSIGNMENT_MODES {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
  PUBLIC = 'PUBLIC',
}

// export enum GENDERS {
//   MALE = 'MALE',
//   FEMALE = 'FEMALE',
// }
export enum PROFESSION_NATURE {
  RISKY = 'RISKY',
  NON_RISKY = 'NON_RISKY',
}

export enum LEADS_DEFAULT_ADDITIONAL_FIELDS {
  CHANNEL_TYPE = 'CHANNEL TYPE',
  LEAD_TYPE = 'LEAD TYPE',
}

export enum LEADS_DEFAULT_ADDITIONAL_FIELDS_CHANNEL_TYPE_DROPDOWN_OPTIONS {
  AGENTS = 'Agent',
  PUBLIC_API = 'Public API',
}

export enum LEAD_FIELD_VALIDATION_ERROR_TYPES {
  NOT_UNIQUE = 'NOT_UNIQUE',
  IS_EMPTY = 'IS_EMPTY',
  FIELDS_MISSING = 'FIELDS_MISSING',
  NOT_VALID = 'NOT_VALID',
}

export enum PRODUCT_TYPE {
  JCA01 = 'Janashakthi Cash Advance',
  NJU4 = 'Janashakthi Life Unlimited',
  JDS = 'Janashakthi Dream Saver',
  NGT02 = 'Family Plus',
  NGT01 = 'Jeevitha Vardhana',
  NSPS = 'Shilpashakthi',
  NSW = 'Suwashakthi',
  LI4 = 'Janashakthi Investment',
  LSP = 'Swarnashakthi',
  NJEV = 'Jeevitha Thilina',
  LIF1 = 'Life Investment Flexi 1',
}

export enum PRODUCT_CODE {
  JCA01 = 'JCA01',
  NJU4 = 'NJU4',
  JDS = 'JDS',
  NGT02 = 'NGT02',
  NGT01 = 'NGT01',
  NSPS = 'NSPS',
  NSW = 'NSW',
  LI4 = 'LI4',
  LSP = 'LSP',
  NJEV = 'NJEV',
  LIF1 = 'LIF1',
}

export enum PRODUCT_PURPOSE {
  JCA01 = '',
  NJU4 = 'Protection & Health',
  JDS = 'Savings and Investment',
  NGT02 = '',
  NGT01 = 'Protection & Health',
  NSPS = "Child's future",
  NSW = 'Protection & Health',
  LI4 = 'Retirement, Savings and Investment',
  LSP = 'Savings and Investment',
  NJEV = "Child's future",
  LIF1 = '',
}

export enum RIDER_CODE {
  BASIC_LIFE_COVER = 'BSA',
  HOSPITAL_REIMBURSEMENT_CASHLESS = 'HRCL',
  ADDITIONAL_ACCIDENT_COVER = 'LACCID_R1',
  ACCIDENT_COVER = 'ACCID',
  FAMILY_INCOME_COVER = 'FIB',
  CRITICAL_ILLNESS_COVER = 'LCRITL_R1',
  HOSPITAL_CASH_DAY_COVER = 'LHOSPT_R1',
  FUNERAL_EXPENSES_COVER = 'FUNEX',
  ADDITIONAL_LIFE_COVER = 'LADDI_R1',
}

export enum RIDER_DISPLAY_CODE {
  BASIC_LIFE_COVER = 'BSA',
  HOSPITAL_REIMBURSEMENT_CASHLESS = 'HRCL',
  ADDITIONAL_ACCIDENT_COVER = 'PAC',
  FAMILY_INCOME_COVER = 'FIB',
  CRITICAL_ILLNESS_COVER = 'CIC',
  HOSPITAL_CASH_DAY_COVER = 'HCB',
  FUNERAL_EXPENSES_COVER = 'FE',
  ADDITIONAL_LIFE_COVER = 'ALC',
}

export enum RIDER_CODE_DESCRIPTION {
  BASIC_LIFE_COVER = 'Basic Cover',
  HOSPITAL_REIMBURSEMENT_CASHLESS = 'This shall provide a Cashless Benefit in the event of Hospitalization based on the number of days hospitalized.',
  ADDITIONAL_ACCIDENT_COVER = 'This shall provide Accidental Death Cover, Total Permanent Disability Benefit (Due to Accident or Sickness), and Partial Permanent Disability Benefit (Due to Accident).',
  FAMILY_INCOME_COVER = 'This shall pay the monthly income of the family unit in case of Death or Total Permanent Disability of the life assured.',
  CRITICAL_ILLNESS_COVER = 'This shall protect you on 25 or 39 Critical Ailments.',
  HOSPITAL_CASH_DAY_COVER = 'This shall provide a Cash Benefit in the event of Hospitalization based on the number of days hospitalized.',
  FUNERAL_EXPENSES_COVER = 'This will cover Funeral Expenses.',
  ADDITIONAL_LIFE_COVER = 'This shall enhance the protection while charging a low premium.',
}

// don't change this
export enum LIFE_ASSURED {
  MAIN = 'main',
  SPOUSE = 'spouse',
  DEPENDENT = 'dependents',
  THIRD_PERSON = 'third_person',
  //BUSINESS_PARTNER = 'business',
}

// TODO: Remove @Nilush
export enum RISK_INFORMATION_SECTION {
  BASIC_LIFE_COVER = 'basic_life_cover',
  CRITICAL_ILLNESS_COVER = 'critical_illness_cover',
  ADDITIONAL_ACCIDENT_COVER = 'additional_accident_cover',
  HOSPITAL_CASH_DAY_COVER = 'hospital_cash_day_cover',
  ADDITIONAL_LIFE_COVER = 'additional_life_cover',
  FIB_PER_MONTH = 'fib_per_month',
  FUNERAL_EXPENSES = 'funeral_expenses',
  HOSPITAL_REIMBURSEMENT_CASHLESS = 'hospital_Reimbursement_cashless',
}

export enum REIMBURSEMENT_FLAG {
  INDIVIDUAL = 'I',
  FAMILY = 'F',
  NONE = '',
}

export enum BOOLEAN {
  YES = 'Y',
  NO = 'N',
}

export enum SCHEME_TYPES {
  TO_BE_ADDED = '',
}

export enum CASHLESS_FAMILY_FLAGS {
  FAMILY_INDIVIDUAL = 'I',
  FAMILY_FLOATER = 'F',
  NONE = '',
}

export enum J_GENDER {
  MALE = 'M',
  FEMALE = 'F',
  TO_BE_ADDED = '',
}

export enum FREQUENCY_CODE {
  SINGLE = 'S',
  ANNUAL = 'A',
  MONTHLY = 'M',
  QUARTERLY = 'Q',
  HALF_YEARLY = 'H',
}

export enum MEDICAL_BENEFIT_TYPES {
  PER_DAY_COVER = 'PER_DAY',
  REIMBURSEMENT = 'REIMBURSEMENT',
}

export enum QUOTATION_COVER_TYPES {
  INDIVIDUAL = 'INDIVIDUAL',
  FAMILY = 'FAMILY',
  NONE = 'None',
}

export enum BENEFIT_RECEIVING_PERIOD_TYPES {
  WITHIN_POLICY_TERM = 'WP',
  AFTER_MATURITY = 'AM',
}

export enum REIMBURSEMENT_COVER_TYPES {
  INDIVIDUAL = 'I',
  FAMILY = 'F',
  // At the moment None is unused
  NONE = 'N',
}

export const PRODUCT_LIST = [
  {
    key: 'a',
    name: PRODUCT_TYPE.NGT01,
  },
  // {
  //   key: 'b',
  //   name: PRODUCT_TYPE.NGT02,
  // },
  {
    key: 'c',
    name: PRODUCT_TYPE.NJU4,
  },
  // {
  //   key: 'd',
  //   name: PRODUCT_TYPE.JCA01,
  // },
  {
    key: 'e',
    name: PRODUCT_TYPE.NSW,
  },
  {
    key: 'f',
    name: PRODUCT_TYPE.NSPS,
  },
  {
    key: 'g',
    name: PRODUCT_TYPE.LSP,
  },

  {
    key: 'h',
    name: PRODUCT_TYPE.NJEV,
  },

  {
    key: 'i',
    name: PRODUCT_TYPE.LI4,
  },
  {
    key: 'j',
    name: PRODUCT_TYPE.JDS,
  },
  {
    key: 'k',
    name: PRODUCT_TYPE.LIF1,
  },
];
export const PRODUCT_LIST_MAPPING = {
  need1: ['e'],
  need2: ['f', 'a' /* , 'b' */],
  need3: ['a' /* , 'b' */],
  need4: ['j', 'i'],
  need5: ['c'],
  need6: ['a', /*  'b', 'd', */ 'g', 'h', 'i', 'j'],
  need7: ['e', 'k'],
};

export enum PRODUCT_REFERENCES_BENEFIT_NAME {
  BASIC_LIFE_COVER = 'Basic Life Cover',
  ADDITIONAL_ACCIDENT_COVER = 'Additional Accident Cover',
  ADDITIONAL_LIFE_COVER = 'Additional Life Cover',
  CRITICAL_ILLNESS_COVER = 'Critical Illness Cover',
  FAMILY_INCOME_COVER = 'Family Income Benefit Per Month',
  FUNERAL_EXPENSES_COVER = 'Funeral Expense',
  HOSPITAL_PER_DAY_COVER = 'Hospital Cash Plan Per Day',
  HOSPITAL_REIMBURSEMENT_COVER = 'Hospital Reimbursement - Cashless',
}
export enum PRODUCT_REFERENCES_SUBSECTIONS_RATE {
  BASIC_SELF_RATE = 'Basic Self Rate',
  ADDITIONAL_SELF_RATE = 'Additional Self Rate',
  ADDITIONAL_ACCIDENT_SELF_RATE = 'Additional Accident Self Rate',
  CRITICAL_ILLNESS_SELF_RATE = 'Critical illness Self Rate',
  HOSPITAL_PER_DAY_SELF_RATE = 'Hospital Per Day Self Rate',
  HOSPITAL_REIMBURSEMENT_SELF_RATE = 'Hospital Reimbursement Self Rate',
  FAMILY_INCOME_SELF_RATE = 'Family Income Self Rate',
  FUNERAL_EXPENSES_SELF_RATE = 'Funeral Expenses Self Rate',

  BASIC_SPOUSE_RATE = 'Basic Spouse Rate',
  ADDITIONAL_SPOUSE_RATE = 'Additional Spouse Rate',
  ADDITIONAL_ACCIDENT_SPOUSE_RATE = 'Additional Accident Spouse Rate',
  CRITICAL_ILLNESS_SPOUSE_RATE = 'Critical illness Spouse Rate',
  HOSPITAL_PER_DAY_SPOUSE_RATE = 'Hospital Per Day Spouse Rate',
  HOSPITAL_REIMBURSEMENT_SPOUSE_RATE = 'Hospital Reimbursement Spouse Rate',
  FAMILY_INCOME_SPOUSE_RATE = 'Family Income Spouse Rate',
  FUNERAL_EXPENSES_SPOUSE_RATE = 'Funeral Expenses Spouse Rate',

  BASIC_DEPENDENTS_RATE = 'Basic Dependents Rate',
  ADDITIONAL_DEPENDENTS_RATE = 'Additional Dependents Rate',
  ADDITIONAL_ACCIDENT_DEPENDENTS_RATE = 'Additional Accident Dependents Rate',
  CRITICAL_ILLNESS_DEPENDENTS_RATE = 'Critical illness Dependents Rate',
  HOSPITAL_PER_DAY_DEPENDENTS_RATE = 'Hospital Per Day Dependents Rate',
  HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE = 'Hospital Reimbursement Dependents Rate',
  FAMILY_INCOME_DEPENDENTS_RATE = 'Family Income Dependents Rate',
  FUNERAL_EXPENSES_DEPENDENTS_RATE = 'Funeral Expenses Dependents Rate',
}

export enum PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE {
  BASIC_SELF_MAX_AGE = 'Basic Self Max Age',
  ADDITIONAL_SELF_MAX_AGE = 'Additional Self Max Age',
  ADDITIONAL_ACCIDENT_SELF_MAX_AGE = 'Additional Accident Self Max Age',
  CRITICAL_ILLNESS_SELF_MAX_AGE = 'Critical illness Self Max Age',
  HOSPITAL_PER_DAY_SELF_MAX_AGE = 'Hospital Per Day Self Max Age',
  HOSPITAL_REIMBURSEMENT_SELF_MAX_AGE = 'Hospital Reimbursement Self Max Age',
  FAMILY_INCOME_SELF_MAX_AGE = 'Family Income Self Max Age',
  FUNERAL_EXPENSES_SELF_MAX_AGE = 'Funeral Expenses Self Max Age',

  BASIC_SPOUSE_MAX_AGE = 'Basic Spouse Max Age',
  ADDITIONAL_SPOUSE_MAX_AGE = 'Additional Spouse Max Age',
  ADDITIONAL_ACCIDENT_SPOUSE_MAX_AGE = 'Additional Accident Spouse Max Age',
  CRITICAL_ILLNESS_SPOUSE_MAX_AGE = 'Critical illness Spouse Max Age',
  HOSPITAL_PER_DAY_SPOUSE_MAX_AGE = 'Hospital Per Day Spouse Max Age',
  HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_AGE = 'Hospital Reimbursement Spouse Max Age',
  FAMILY_INCOME_SPOUSE_MAX_AGE = 'Family Income Spouse Max Age',
  FUNERAL_EXPENSES_SPOUSE_MAX_AGE = 'Funeral Expenses Spouse Max Age',

  BASIC_DEPENDENTS_MAX_AGE = 'Basic Dependents Max Age',
  ADDITIONAL_DEPENDENTS_MAX_AGE = 'Additional Dependents Max Age',
  ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_AGE = 'Additional Accident Dependents Max Age',
  CRITICAL_ILLNESS_DEPENDENTS_MAX_AGE = 'Critical illness Dependents Max Age',
  HOSPITAL_PER_DAY_DEPENDENTS_MAX_AGE = 'Hospital Per Day Dependents Max Age',
  HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_AGE = 'Hospital Reimbursement Dependents Max Age',
  FAMILY_INCOME_DEPENDENTS_MAX_AGE = 'Family Income Dependents Max Age',
  FUNERAL_EXPENSES_DEPENDENTS_MAX_AGE = 'Funeral Expenses Dependents Max Age',
}

export enum PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE {
  BASIC_SELF_MIN_AGE = 'Basic Self Min Age',
  ADDITIONAL_SELF_MIN_AGE = 'Additional Self Min Age',
  ADDITIONAL_ACCIDENT_SELF_MIN_AGE = 'Additional Accident Self Min Age',
  CRITICAL_ILLNESS_SELF_MIN_AGE = 'Critical illness Self Min Age',
  HOSPITAL_PER_DAY_SELF_MIN_AGE = 'Hospital Per Day Self Min Age',
  HOSPITAL_REIMBURSEMENT_SELF_MIN_AGE = 'Hospital Reimbursement Self Min Age',
  FAMILY_INCOME_SELF_MIN_AGE = 'Family Income Self Min Age',
  FUNERAL_EXPENSES_SELF_MIN_AGE = 'Funeral Expenses Self Min Age',

  BASIC_SPOUSE_MIN_AGE = 'Basic Spouse Min Age',
  ADDITIONAL_SPOUSE_MIN_AGE = 'Additional Spouse Min Age',
  ADDITIONAL_ACCIDENT_SPOUSE_MIN_AGE = 'Additional Accident Spouse Min Age',
  CRITICAL_ILLNESS_SPOUSE_MIN_AGE = 'Critical illness Spouse Min Age',
  HOSPITAL_PER_DAY_SPOUSE_MIN_AGE = 'Hospital Per Day Spouse Min Age',
  HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_AGE = 'Hospital Reimbursement Spouse Min Age',
  FAMILY_INCOME_SPOUSE_MIN_AGE = 'Family Income Spouse Min Age',
  FUNERAL_EXPENSES_SPOUSE_MIN_AGE = 'Funeral Expenses Spouse Min Age',

  BASIC_DEPENDENTS_MIN_AGE = 'Basic Dependents Min Age',
  ADDITIONAL_DEPENDENTS_MIN_AGE = 'Additional Dependents Min Age',
  ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_AGE = 'Additional Accident Dependents Min Age',
  CRITICAL_ILLNESS_DEPENDENTS_MIN_AGE = 'Critical illness Dependents Min Age',
  HOSPITAL_PER_DAY_DEPENDENTS_MIN_AGE = 'Hospital Per Day Dependents Min Age',
  HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_AGE = 'Hospital Reimbursement Dependents Min Age',
  FAMILY_INCOME_DEPENDENTS_MIN_AGE = 'Family Income Dependents Min Age',
  FUNERAL_EXPENSES_DEPENDENTS_MIN_AGE = 'Funeral Expenses Dependents Min Age',
}

export enum PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE_NEW {
  BASIC_SELF_MAX_AGE = 'BSA Self Max',
  ADDITIONAL_SELF_MAX_AGE = 'LADDI_R1 Self Max',
  ADDITIONAL_ACCIDENT_SELF_MAX_AGE = 'LACCID_R1 Self Max',
  CRITICAL_ILLNESS_SELF_MAX_AGE = 'LCRITL_R1 Self Max',
  HOSPITAL_PER_DAY_SELF_MAX_AGE = 'LHOSPT_R1 Self Max',
  HOSPITAL_REIMBURSEMENT_SELF_MAX_AGE = 'HRCL Self Max',
  FAMILY_INCOME_SELF_MAX_AGE = 'FIB Self Max',
  FUNERAL_EXPENSES_SELF_MAX_AGE = 'FUNEX Self Max',

  BASIC_SPOUSE_MAX_AGE = 'BSA Spouse Max',
  ADDITIONAL_SPOUSE_MAX_AGE = 'LADDI_R1 Spouse Max',
  ADDITIONAL_ACCIDENT_SPOUSE_MAX_AGE = 'LACCID_R1 Spouse Max',
  CRITICAL_ILLNESS_SPOUSE_MAX_AGE = 'LCRITL_R1 Spouse Max',
  HOSPITAL_PER_DAY_SPOUSE_MAX_AGE = 'LHOSPT_R1 Spouse Max',
  HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_AGE = 'HRCL Spouse Max',
  FAMILY_INCOME_SPOUSE_MAX_AGE = 'FIB Spouse Max',
  FUNERAL_EXPENSES_SPOUSE_MAX_AGE = 'FUNEX Spouse Max',

  BASIC_DEPENDENTS_MAX_AGE = 'BSA Dependents Max',
  ADDITIONAL_DEPENDENTS_MAX_AGE = 'LADDI_R1 Dependents Max',
  ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_AGE = 'LACCID_R1 Dependents Max',
  CRITICAL_ILLNESS_DEPENDENTS_MAX_AGE = 'LCRITL_R1 Dependents Max',
  HOSPITAL_PER_DAY_DEPENDENTS_MAX_AGE = 'LHOSPT_R1 Dependents Max',
  HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_AGE = 'HRCL Dependents Max',
  FAMILY_INCOME_DEPENDENTS_MAX_AGE = 'FIB Dependents Max',
  FUNERAL_EXPENSES_DEPENDENTS_MAX_AGE = 'FUNEX Dependents Max',
}

export enum PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE_NEW {
  BASIC_SELF_MIN_AGE = 'BSA Self Min',
  ADDITIONAL_SELF_MIN_AGE = 'LADDI_R1 Self Min',
  ADDITIONAL_ACCIDENT_SELF_MIN_AGE = 'LACCID_R1 Self Min',
  CRITICAL_ILLNESS_SELF_MIN_AGE = 'LCRITL_R1 Self Min',
  HOSPITAL_PER_DAY_SELF_MIN_AGE = 'LHOSPT_R1 Self Min',
  HOSPITAL_REIMBURSEMENT_SELF_MIN_AGE = 'HRCL Self Min',
  FAMILY_INCOME_SELF_MIN_AGE = 'FIB Self Min',
  FUNERAL_EXPENSES_SELF_MIN_AGE = 'FUNEX Self Min',

  BASIC_SPOUSE_MIN_AGE = 'BSA Spouse Min',
  ADDITIONAL_SPOUSE_MIN_AGE = 'LADDI_R1 Spouse Min',
  ADDITIONAL_ACCIDENT_SPOUSE_MIN_AGE = 'LACCID_R1 Spouse Min',
  CRITICAL_ILLNESS_SPOUSE_MIN_AGE = 'LCRITL_R1 Spouse Min',
  HOSPITAL_PER_DAY_SPOUSE_MIN_AGE = 'LHOSPT_R1 Spouse Min',
  HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_AGE = 'HRCL Spouse Min',
  FAMILY_INCOME_SPOUSE_MIN_AGE = 'FIB Spouse Min',
  FUNERAL_EXPENSES_SPOUSE_MIN_AGE = 'FUNEX Spouse Min',

  BASIC_DEPENDENTS_MIN_AGE = 'BSA Dependents Min',
  ADDITIONAL_DEPENDENTS_MIN_AGE = 'LADDI_R1 Dependents Min',
  ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_AGE = 'LACCID_R1 Dependents Min',
  CRITICAL_ILLNESS_DEPENDENTS_MIN_AGE = 'LCRITL_R1 Dependents Min',
  HOSPITAL_PER_DAY_DEPENDENTS_MIN_AGE = 'LHOSPT_R1 Dependents Min',
  HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_AGE = 'HRCL Dependents Min',
  FAMILY_INCOME_DEPENDENTS_MIN_AGE = 'FIB Dependents Min',
  FUNERAL_EXPENSES_DEPENDENTS_MIN_AGE = 'FUNEX Dependents Min',
}

export enum PRODUCT_REFERENCES_SUBSECTIONS_MISC_AGE {
  SELF_MIN_AGE = 'Self Min',
  SELF_MAX_AGE = 'Self Max',
  SELF_CEASING_AGE = 'Self Ceasing',
}

export enum PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION {
  CONTRIBUTION_AMOUNT_SINGLE_MIN = 'Contribution Amount Single Min',
  CONTRIBUTION_AMOUNT_SINGLE_MAX = 'Contribution Amount Single Max',

  CONTRIBUTION_AMOUNT_YEARLY_MIN = 'Contribution Amount Yearly Min',
  CONTRIBUTION_AMOUNT_YEARLY_MAX = 'Contribution Amount Yearly Max',

  CONTRIBUTION_AMOUNT_HALF_YEARLY_MIN = 'Contribution Amount Half Yearly Min',
  CONTRIBUTION_AMOUNT_HALF_YEARLY_MAX = 'Contribution Amount Half Yearly Max',

  CONTRIBUTION_AMOUNT_QUARTERLY_MIN = 'Contribution Amount Quarterly Min',
  CONTRIBUTION_AMOUNT_QUARTERLY_MAX = 'Contribution Amount Quarterly Max',

  CONTRIBUTION_AMOUNT_MONTHLY_MIN = 'Contribution Amount Monthly Min',
  CONTRIBUTION_AMOUNT_MONTHLY_MAX = 'Contribution Amount Monthly Max',
}

export enum PRODUCT_REFERENCES_SUBSECTIONS {
  BASIC_SELF_MIN_ASSURED = 'Basic Self Assured Min',
  BASIC_SELF_MAX_ASSURED = 'Basic Self Assured Max',
  ADDITIONAL_SELF_MIN_ASSURED = 'Additional Self Assured Min',
  ADDITIONAL_SELF_MAX_ASSURED = 'Additional Self Assured Max',
  ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED = 'Additional Accident Self Assured Min',
  ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED = 'Additional Accident Self Assured Max',
  CRITICAL_ILLNESS_SELF_MIN_ASSURED = 'Critical illness Self Assured Min',
  CRITICAL_ILLNESS_SELF_MAX_ASSURED = 'Critical illness Self Assured Max',
  HOSPITAL_PER_DAY_SELF_MIN_ASSURED = 'Hospital Per Day Self Assured Min',
  HOSPITAL_PER_DAY_SELF_MAX_ASSURED = 'Hospital Per Day Self Assured Max',
  HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED = 'Hospital Reimbursement Self Assured Min',
  HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED = 'Hospital Reimbursement Self Assured Max',
  FAMILY_INCOME_SELF_MIN_ASSURED = 'Family Income Self Assured Min',
  FAMILY_INCOME_SELF_MAX_ASSURED = 'Family Income Self Assured Max',
  FUNERAL_EXPENSES_SELF_MIN_ASSURED = 'Funeral Expenses Self Assured Min',
  FUNERAL_EXPENSES_SELF_MAX_ASSURED = 'Funeral Expenses Self Assured Max',

  BASIC_SPOUSE_MIN_ASSURED = 'Basic Spouse Assured Min',
  BASIC_SPOUSE_MAX_ASSURED = 'Basic Spouse Assured Max',
  ADDITIONAL_SPOUSE_MIN_ASSURED = 'Additional Spouse Assured Min',
  ADDITIONAL_SPOUSE_MAX_ASSURED = 'Additional Spouse Assured Max',
  ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED = 'Additional Accident Spouse Assured Min',
  ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED = 'Additional Accident Spouse Assured Max',
  CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED = 'Critical illness Spouse Assured Min',
  CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED = 'Critical illness Spouse Assured Max',
  HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED = 'Hospital Per Day Spouse Assured Min',
  HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED = 'Hospital Per Day Spouse Assured Max',
  HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED = 'Hospital Reimbursement Spouse Assured Min',
  HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED = 'Hospital Reimbursement Spouse Assured Max',
  FAMILY_INCOME_SPOUSE_MIN_ASSURED = 'Family Income Spouse Assured Min',
  FAMILY_INCOME_SPOUSE_MAX_ASSURED = 'Family Income Spouse Assured Max',
  FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED = 'Funeral Expenses Spouse Assured Min',
  FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED = 'Funeral Expenses Spouse Assured Max',

  BASIC_DEPENDENTS_MIN_ASSURED = 'Basic Dependents Assured Min',
  BASIC_DEPENDENTS_MAX_ASSURED = 'Basic Dependents Assured Max',
  ADDITIONAL_DEPENDENTS_MIN_ASSURED = 'Additional Dependents Assured Min',
  ADDITIONAL_DEPENDENTS_MAX_ASSURED = 'Additional Dependents Assured Max',
  ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED = 'Additional Accident Dependents Assured Min',
  ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED = 'Additional Accident Dependents Assured Max',
  CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED = 'Critical illness Dependents Assured Min',
  CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED = 'Critical illness Dependents Assured Max',
  HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED = 'Hospital Per Day Dependents Assured Min',
  HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED = 'Hospital Per Day Dependents Assured Max',
  HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED = 'Hospital Reimbursement Dependents Assured Min',
  HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED = 'Hospital Reimbursement Dependents Assured Max',
  FAMILY_INCOME_DEPENDENTS_MIN_ASSURED = 'Family Income Dependents Assured Min',
  FAMILY_INCOME_DEPENDENTS_MAX_ASSURED = 'Family Income Dependents Assured Max',
  FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED = 'Funeral Expenses Dependents Assured Min',
  FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED = 'Funeral Expenses Dependents Assured Max',

  YEARLY_MIN_MODE = 'Min Yearly Mode',
  HALF_YEARLY_MIN_MODE = 'Min Half Yearly Mode',
  QUARTERLY_MIN_MODE = 'Min Quarterly Mode',
  MONTHLY_MIN_MODE = 'Min Month Mode',
  SINGLE_MIN_MODE = 'Min Single Mode',

  BASIC_MULTIPLE = 'Basic Multiple',
  ADDITIONAL_MULTIPLE = 'Additional Multiple',
  ADDITIONAL_ACCIDENT_MULTIPLE = 'Additional Accident Multiple',
  CRITICAL_ILLNESS_MULTIPLE = 'Critical illness Multiple',
  HOSPITAL_PER_DAY_MULTIPLE = 'Hospital Per Day Multiple',
  HOSPITAL_REIMBURSEMENT_MULTIPLE = 'Hospital Reimbursement Multiple',
  FAMILY_INCOME_MULTIPLE = 'Family Income Multiple',
  FUNERAL_EXPENSES_MULTIPLE = 'Funeral Expenses Multiple',

  SELF_MAX_AGE = 'Self Max Age',
  SELF_MIN_AGE = 'Self Min Age',
}

export const PRODUCT_REFERENCE_SUBSECTION_RATE_ARRAY: PRODUCT_REFERENCES_SUBSECTIONS_RATE[] =
  [
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.BASIC_SELF_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.ADDITIONAL_SELF_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.ADDITIONAL_ACCIDENT_SELF_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.CRITICAL_ILLNESS_SELF_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.HOSPITAL_PER_DAY_SELF_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.HOSPITAL_REIMBURSEMENT_SELF_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.FAMILY_INCOME_SELF_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.FUNERAL_EXPENSES_SELF_RATE,

    PRODUCT_REFERENCES_SUBSECTIONS_RATE.BASIC_SPOUSE_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.ADDITIONAL_SPOUSE_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.ADDITIONAL_ACCIDENT_SPOUSE_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.CRITICAL_ILLNESS_SPOUSE_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.HOSPITAL_PER_DAY_SPOUSE_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.HOSPITAL_REIMBURSEMENT_SPOUSE_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.FAMILY_INCOME_SPOUSE_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.FUNERAL_EXPENSES_SPOUSE_RATE,

    PRODUCT_REFERENCES_SUBSECTIONS_RATE.BASIC_DEPENDENTS_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.ADDITIONAL_DEPENDENTS_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.ADDITIONAL_ACCIDENT_DEPENDENTS_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.CRITICAL_ILLNESS_DEPENDENTS_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.HOSPITAL_PER_DAY_DEPENDENTS_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.HOSPITAL_REIMBURSEMENT_DEPENDENTS_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.FAMILY_INCOME_DEPENDENTS_RATE,
    PRODUCT_REFERENCES_SUBSECTIONS_RATE.FUNERAL_EXPENSES_DEPENDENTS_RATE,
  ];

export const PRODUCT_REFERENCE_SUBSECTION_CONTRIBUTION_ARRAY: PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION[] =
  [
    PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION.CONTRIBUTION_AMOUNT_SINGLE_MIN,
    PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION.CONTRIBUTION_AMOUNT_SINGLE_MAX,
    PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION.CONTRIBUTION_AMOUNT_YEARLY_MIN,
    PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION.CONTRIBUTION_AMOUNT_YEARLY_MAX,
    PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION.CONTRIBUTION_AMOUNT_HALF_YEARLY_MIN,
    PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION.CONTRIBUTION_AMOUNT_HALF_YEARLY_MAX,
    PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION.CONTRIBUTION_AMOUNT_QUARTERLY_MIN,
    PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION.CONTRIBUTION_AMOUNT_QUARTERLY_MAX,
    PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION.CONTRIBUTION_AMOUNT_MONTHLY_MIN,
    PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION.CONTRIBUTION_AMOUNT_MONTHLY_MAX,
  ];

export const PRODUCT_REFERENCE_SUBSECTION_MAX_AGE_ARRAY: PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE[] =
  [
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.BASIC_SELF_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.ADDITIONAL_SELF_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.ADDITIONAL_ACCIDENT_SELF_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.CRITICAL_ILLNESS_SELF_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.HOSPITAL_PER_DAY_SELF_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.HOSPITAL_REIMBURSEMENT_SELF_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.FAMILY_INCOME_SELF_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.FUNERAL_EXPENSES_SELF_MAX_AGE,

    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.BASIC_SPOUSE_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.ADDITIONAL_SPOUSE_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.ADDITIONAL_ACCIDENT_SPOUSE_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.CRITICAL_ILLNESS_SPOUSE_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.HOSPITAL_PER_DAY_SPOUSE_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.FAMILY_INCOME_SPOUSE_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.FUNERAL_EXPENSES_SPOUSE_MAX_AGE,

    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.BASIC_DEPENDENTS_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.ADDITIONAL_DEPENDENTS_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.CRITICAL_ILLNESS_DEPENDENTS_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.HOSPITAL_PER_DAY_DEPENDENTS_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.FAMILY_INCOME_DEPENDENTS_MAX_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE.FUNERAL_EXPENSES_DEPENDENTS_MAX_AGE,
  ];

export const PRODUCT_REFERENCE_SUBSECTION_MIN_AGE_ARRAY: PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE[] =
  [
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.BASIC_SELF_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.ADDITIONAL_SELF_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.ADDITIONAL_ACCIDENT_SELF_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.CRITICAL_ILLNESS_SELF_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.HOSPITAL_PER_DAY_SELF_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.HOSPITAL_REIMBURSEMENT_SELF_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.FAMILY_INCOME_SELF_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.FUNERAL_EXPENSES_SELF_MIN_AGE,

    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.BASIC_SPOUSE_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.ADDITIONAL_SPOUSE_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.ADDITIONAL_ACCIDENT_SPOUSE_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.CRITICAL_ILLNESS_SPOUSE_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.HOSPITAL_PER_DAY_SPOUSE_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.FAMILY_INCOME_SPOUSE_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.FUNERAL_EXPENSES_SPOUSE_MIN_AGE,

    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.BASIC_DEPENDENTS_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.ADDITIONAL_DEPENDENTS_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.CRITICAL_ILLNESS_DEPENDENTS_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.HOSPITAL_PER_DAY_DEPENDENTS_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.FAMILY_INCOME_DEPENDENTS_MIN_AGE,
    PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE.FUNERAL_EXPENSES_DEPENDENTS_MIN_AGE,
  ];

export const PRODUCT_REFERENCE_SUBSECTION_ARRAY: (
  | PRODUCT_REFERENCES_SUBSECTIONS
  | PRODUCT_REFERENCES_SUBSECTIONS_RATE
  | PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE
  | PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE
  | PRODUCT_REFERENCES_SUBSECTIONS_CONTRIBUTION
)[] = [
  PRODUCT_REFERENCES_SUBSECTIONS.BASIC_MULTIPLE,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_MULTIPLE,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_ACCIDENT_MULTIPLE,
  PRODUCT_REFERENCES_SUBSECTIONS.CRITICAL_ILLNESS_MULTIPLE,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_PER_DAY_MULTIPLE,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_REIMBURSEMENT_MULTIPLE,
  PRODUCT_REFERENCES_SUBSECTIONS.FAMILY_INCOME_MULTIPLE,
  PRODUCT_REFERENCES_SUBSECTIONS.FUNERAL_EXPENSES_MULTIPLE,

  PRODUCT_REFERENCES_SUBSECTIONS.BASIC_SELF_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_SELF_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_ACCIDENT_SELF_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.CRITICAL_ILLNESS_SELF_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_PER_DAY_SELF_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_REIMBURSEMENT_SELF_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FAMILY_INCOME_SELF_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FUNERAL_EXPENSES_SELF_MIN_ASSURED,

  PRODUCT_REFERENCES_SUBSECTIONS.BASIC_SELF_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_SELF_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_ACCIDENT_SELF_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.CRITICAL_ILLNESS_SELF_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_PER_DAY_SELF_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_REIMBURSEMENT_SELF_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FAMILY_INCOME_SELF_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FUNERAL_EXPENSES_SELF_MAX_ASSURED,

  PRODUCT_REFERENCES_SUBSECTIONS.BASIC_SPOUSE_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_SPOUSE_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_ACCIDENT_SPOUSE_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.CRITICAL_ILLNESS_SPOUSE_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_PER_DAY_SPOUSE_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_REIMBURSEMENT_SPOUSE_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FAMILY_INCOME_SPOUSE_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FUNERAL_EXPENSES_SPOUSE_MIN_ASSURED,

  PRODUCT_REFERENCES_SUBSECTIONS.BASIC_SPOUSE_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_SPOUSE_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_ACCIDENT_SPOUSE_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.CRITICAL_ILLNESS_SPOUSE_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_PER_DAY_SPOUSE_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_REIMBURSEMENT_SPOUSE_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FAMILY_INCOME_SPOUSE_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FUNERAL_EXPENSES_SPOUSE_MAX_ASSURED,

  PRODUCT_REFERENCES_SUBSECTIONS.BASIC_DEPENDENTS_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_DEPENDENTS_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_ACCIDENT_DEPENDENTS_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.CRITICAL_ILLNESS_DEPENDENTS_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_PER_DAY_DEPENDENTS_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_REIMBURSEMENT_DEPENDENTS_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FAMILY_INCOME_DEPENDENTS_MIN_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FUNERAL_EXPENSES_DEPENDENTS_MIN_ASSURED,

  PRODUCT_REFERENCES_SUBSECTIONS.BASIC_DEPENDENTS_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_DEPENDENTS_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.ADDITIONAL_ACCIDENT_DEPENDENTS_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.CRITICAL_ILLNESS_DEPENDENTS_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_PER_DAY_DEPENDENTS_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.HOSPITAL_REIMBURSEMENT_DEPENDENTS_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FAMILY_INCOME_DEPENDENTS_MAX_ASSURED,
  PRODUCT_REFERENCES_SUBSECTIONS.FUNERAL_EXPENSES_DEPENDENTS_MAX_ASSURED,

  PRODUCT_REFERENCES_SUBSECTIONS.YEARLY_MIN_MODE,
  PRODUCT_REFERENCES_SUBSECTIONS.HALF_YEARLY_MIN_MODE,
  PRODUCT_REFERENCES_SUBSECTIONS.QUARTERLY_MIN_MODE,
  PRODUCT_REFERENCES_SUBSECTIONS.MONTHLY_MIN_MODE,

  ...PRODUCT_REFERENCE_SUBSECTION_RATE_ARRAY,
  ...PRODUCT_REFERENCE_SUBSECTION_MIN_AGE_ARRAY,
  ...PRODUCT_REFERENCE_SUBSECTION_MAX_AGE_ARRAY,
  PRODUCT_REFERENCES_SUBSECTIONS.SELF_MIN_AGE,
  PRODUCT_REFERENCES_SUBSECTIONS.SELF_MAX_AGE,
  ...PRODUCT_REFERENCE_SUBSECTION_CONTRIBUTION_ARRAY,
  PRODUCT_REFERENCES_SUBSECTIONS.SINGLE_MIN_MODE,
];

export const PRODUCT_AGE_REFERENCE_ARRAY: (
  | PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE_NEW
  | PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE_NEW
)[] = [
  ...Object.values(PRODUCT_REFERENCES_SUBSECTIONS_MAX_AGE_NEW),
  ...Object.values(PRODUCT_REFERENCES_SUBSECTIONS_MIN_AGE_NEW),
];

export enum PAYMENT_MODE {
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
  HALF_YEARLY = 'Half Yearly',
  QUARTERLY = 'Quarterly',
  SINGLE = 'Single',
}

export enum PRODUCT_INFO {
  SPOUSE_COVERED = 'spouse_covered',
  SELF_COVERED = 'self_covered',
  DEPENDENT_COVERED = 'dependent_covered',
  BUSINESS_PERSON_COVERED = 'business_partner_covered',
  COVERED_TO_BE_INCLUDED = 'cover_to_be',

  POLICY_TERM = 'policy_term',
  PREMIUM_TERM = 'premium_term',

  PREFERRED_LANGUAGE = 'preferred_language',
  PAYMENT_METHOD = 'preferred_payment_method',
  CONTRIBUTION_AMOUNT = 'contribution_amount',
  WITHDRAWAL_FREQUENCY = 'withdrawal_frequency',
  RECEIVING_PERIOD = 'receiving_period',

  THIRD_PARTY = 'third_person',

  COVER_TYPE = 'cover_type',
  CASHLESS_FAMILY_TYPE = 'family_type',
  MEDICAL_BENEFIT = 'medical_benefit',

  MORE_LIFE_COVER = 'is_needed_more_life_cover',

  // NJU4 special
  BENEFIT_RECEIVING_PERIOD = 'benefit_receiving_period',
  REIMBURSEMENT_COVER_TYPE = 'reimbursement_cover_type',
}

export const PRODUCT_RISK_INFO = [
  {
    key: PRODUCT_TYPE.JCA01,
    active_sections: [
      RISK_INFORMATION_SECTION.BASIC_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_ACCIDENT_COVER,
      RISK_INFORMATION_SECTION.CRITICAL_ILLNESS_COVER,
      RISK_INFORMATION_SECTION.FIB_PER_MONTH,
      RISK_INFORMATION_SECTION.HOSPITAL_CASH_DAY_COVER,
      RISK_INFORMATION_SECTION.FUNERAL_EXPENSES,
      RISK_INFORMATION_SECTION.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_TYPE.NJU4,
    active_sections: [
      RISK_INFORMATION_SECTION.BASIC_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_ACCIDENT_COVER,
      RISK_INFORMATION_SECTION.CRITICAL_ILLNESS_COVER,
      RISK_INFORMATION_SECTION.FIB_PER_MONTH,
      RISK_INFORMATION_SECTION.HOSPITAL_CASH_DAY_COVER,
      RISK_INFORMATION_SECTION.FUNERAL_EXPENSES,
      RISK_INFORMATION_SECTION.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_TYPE.JDS,
  },
  {
    key: PRODUCT_TYPE.NGT02,
    active_sections: [
      RISK_INFORMATION_SECTION.BASIC_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_ACCIDENT_COVER,
      RISK_INFORMATION_SECTION.CRITICAL_ILLNESS_COVER,
      RISK_INFORMATION_SECTION.FIB_PER_MONTH,
      RISK_INFORMATION_SECTION.HOSPITAL_CASH_DAY_COVER,
      RISK_INFORMATION_SECTION.FUNERAL_EXPENSES,
      RISK_INFORMATION_SECTION.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_TYPE.NGT01,
    active_sections: [
      RISK_INFORMATION_SECTION.BASIC_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_ACCIDENT_COVER,
      RISK_INFORMATION_SECTION.CRITICAL_ILLNESS_COVER,
      RISK_INFORMATION_SECTION.FIB_PER_MONTH,
      RISK_INFORMATION_SECTION.HOSPITAL_CASH_DAY_COVER,
      RISK_INFORMATION_SECTION.FUNERAL_EXPENSES,
      RISK_INFORMATION_SECTION.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_TYPE.NSPS,
    active_sections: [
      RISK_INFORMATION_SECTION.BASIC_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_ACCIDENT_COVER,
      RISK_INFORMATION_SECTION.CRITICAL_ILLNESS_COVER,
      RISK_INFORMATION_SECTION.FIB_PER_MONTH,
      RISK_INFORMATION_SECTION.HOSPITAL_CASH_DAY_COVER,
      RISK_INFORMATION_SECTION.FUNERAL_EXPENSES,
      RISK_INFORMATION_SECTION.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_TYPE.NSW,
    active_sections: [
      RISK_INFORMATION_SECTION.BASIC_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_ACCIDENT_COVER,
      RISK_INFORMATION_SECTION.CRITICAL_ILLNESS_COVER,
      RISK_INFORMATION_SECTION.FIB_PER_MONTH,
      RISK_INFORMATION_SECTION.HOSPITAL_CASH_DAY_COVER,
      RISK_INFORMATION_SECTION.FUNERAL_EXPENSES,
      RISK_INFORMATION_SECTION.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_TYPE.LI4,
    active_sections: [
      RISK_INFORMATION_SECTION.BASIC_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_ACCIDENT_COVER,
      RISK_INFORMATION_SECTION.CRITICAL_ILLNESS_COVER,
      RISK_INFORMATION_SECTION.FIB_PER_MONTH,
      RISK_INFORMATION_SECTION.HOSPITAL_CASH_DAY_COVER,
      RISK_INFORMATION_SECTION.FUNERAL_EXPENSES,
      RISK_INFORMATION_SECTION.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
  {
    key: PRODUCT_TYPE.LSP,
    active_sections: [RISK_INFORMATION_SECTION.BASIC_LIFE_COVER],
  },
  {
    key: PRODUCT_TYPE.NJEV,
    active_sections: [RISK_INFORMATION_SECTION.BASIC_LIFE_COVER],
  },
  {
    key: PRODUCT_TYPE.LIF1,
    active_sections: [
      RISK_INFORMATION_SECTION.BASIC_LIFE_COVER,
      RISK_INFORMATION_SECTION.ADDITIONAL_ACCIDENT_COVER,
      RISK_INFORMATION_SECTION.CRITICAL_ILLNESS_COVER,
      RISK_INFORMATION_SECTION.FIB_PER_MONTH,
      RISK_INFORMATION_SECTION.HOSPITAL_CASH_DAY_COVER,
      RISK_INFORMATION_SECTION.FUNERAL_EXPENSES,
      RISK_INFORMATION_SECTION.HOSPITAL_REIMBURSEMENT_CASHLESS,
    ],
  },
];

export enum ELEMENT_TYPE {
  TEXT = 'TEXT',
  KEY_VALUE_PAIR_TABLE = 'KEY_VALUE_PAIR_TABLE',
  TWO_COLUMN_TABLE = 'TWO_COLUMN_TABLE',
  MULTI_COLUMN_TABLE = 'MULTI_COLUMN_TABLE',
  END_DOCUMENT = 'END_DOCUMENT',
}

export enum NEED_ANALYSIS_STATUS {
  NOT_INITIATED = 'NI',
  IN_PROGRESS = 'I',
  COMPLETED = 'C',
  RE_INITIATED = 'R',
  RE_COMPLETED = 'RC',
}
