import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { IName } from '@shared/interfaces';

import { environment } from '../../../../environments/environment';

export const noWhitespaceValidator: ValidatorFn = (control: FormControl) => {
  if (!control.value) return null;
  if (typeof control.value !== 'string') return null;

  // const isWhitespace = control.value.trim().length === 0;
  // const isValid = !isWhitespace;
  return !(control.value.trim().length === 0) ? null : { whitespace: true };
};

export const noWhitespaceValidatorForName: ValidatorFn = (
  control: FormControl
) => {
  if (!control.value) return null;
  if (typeof control.value !== 'string') return null;

  // const isWhitespace = control.value.trim().length === 0;
  // const isValid = !isWhitespace;
  return !(control.value.trim().length === 0) &&
    control.value.trim().split(' ')?.length > 2
    ? null
    : { whitespace: true };
};

export const noWhitespaceValidatorForNames: ValidatorFn = (
  control: FormControl<IName>
) => {
  if (!control.value) return null;
  // if (typeof control.value !== 'string') return null;

  // const isWhitespace = control.value.trim().length === 0;
  // const isValid = !isWhitespace;
  return (control.value as IName)?.last_name?.trim()?.length === 0
    ? { whitespace: true }
    : null;
};

export const noAllowSelfDomainValidator = (
  control: FormControl
): ValidationErrors => {
  const selfDomain = environment.WEB_URL;
  if (control.value?.toString().includes(selfDomain)) {
    return { selfDomain: true };
  }
  return null;
};
