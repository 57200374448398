<div class="container" #container>
  <div class="row">
    <div *ngIf="loading" class="loading-overlay">
      <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="col-md-12 p-0">
      <div
        class="container_drop"
        *ngIf="allowUpload && imgURLList.length === 0"
      >
        <ngx-file-drop>
          <input
            style="z-index: 2"
            #file
            type="file"
            class="file-upload-input"
            multiple
            (change)="fileProgress(file.files)"
            [disabled]="remainingFilesCount === 0"
            id="uploadFile"
            onclick="this.value=null;"
          />
          <div class="neo-empty-storage">
            <div class="neo-empty-storage__image__container" style="z-index: 1">
              <lottie-player
                src="/assets/animations/bulk-upload.json"
                background="transparent"
                speed="1"
                loop
                autoplay
                alt="bulk-upload"
              ></lottie-player>
            </div>

            <div class="mt-3 properties">
              <span> {{ 'shared.drag-and-drop.heading.only' | translate }}</span
              ><strong>{{ allowedTypesNames }}</strong
              >{{ 'shared.drag-and-drop.heading.which-less-than' | translate
              }}<strong>{{ maxSize }} MB</strong
              >{{ 'shared.drag-and-drop.heading.allowed' | translate }}<br />
            </div>
            <div
              class="d-flex neo-empty-storage__browse text-center"
              style="margin-bottom: 1rem"
              [style.flex-direction]="dialogBoxWidth > 480 ? 'row' : 'column'"
            >
              <span style="font-weight: 500">{{
                'shared.drag-and-drop.description' | translate
              }}</span>
              <span
                style="
                  font-weight: 500;
                  margin-right: 0.8rem;
                  margin-left: 0.7rem;
                "
                >{{ 'shared.drag-and-drop.or' | translate }}</span
              >
              <button
                class="browse"
                mat-stroked-button
                color="primary"
                style="z-index: 2"
                (click)="file.click()"
              >
                {{ 'shared.drag-and-drop.browse' | translate }}
              </button>
            </div>
          </div>
        </ngx-file-drop>
      </div>
      <div
        *ngIf="imgURLList.length !== 0 && showUploadedFiles"
        class="row"
        style="display: flex; justify-content: center; align-items: center"
      >
        <div *ngFor="let imgURL of imgURLList; let i = index">
          <div class="col-md-4 my-2">
            <div
              style="
                display: flex;
                flex-flow: column wrap;
                justify-content: center;
                align-items: center;
              "
            >
              <img
                src="{{ imgURL }}"
                alt="img"
                style="
                  min-height: 60px;
                  min-width: 60px;
                  max-height: 120px;
                  max-width: 120px;
                "
              />
              <p>{{ fileNames[i] }}</p>
              <button
                *ngIf="allowDelete"
                mat-button
                (click)="deleteImage(imgURL, i)"
                mat-tooltip="Delete image"
              >
                <mat-icon>delete</mat-icon>&nbsp;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
