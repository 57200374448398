export enum POLICIES_DEFAULT_FIELDS {
  SMOKE_TYPE = 'Smoke Type',
  ALCOHOL_TYPE = 'Alcohol Type',
  PAIN_LOCATION = 'PAIN LOCATION',
  PAIN_SEVERITY = 'PAIN SEVERITY',
  CIRCUMSTANCE = 'CIRCUMSTANCE',
  PER = 'PER',
  PAID_BY = 'PAID_BY',
  PREFERRED_BY = 'PREFERRED_BY',
  PREGNANT_STAGE = 'PREGNANT_STAGE',
  OUTSIDE_COUNTRY = 'OUTSIDE_COUNTRY',
  ARTHRITIS_TYPE = 'ARTHRITIS TYPE',
  GYNECOLOGICAL_TYPE = 'GYNECOLOGICAL_TYPE',
  DISEASE_DOCUMENT = 'Disease Document',
  DOCTOR = 'DOCTOR',
  HOSPITAL = 'HOSPITAL',
  CCM_ENDPOINT = 'CCM_ENDPOINTS',
  EXPIRATION_SETUP = 'Expiration Setup',
  BANK_DETAILS = 'Bank Details',
  BRANCH_DETAILS = 'Branch Details',

  // Expiration Configurations
  EXPIRE_QUICK_QUOTE_INTO_QUOTE = 'QUICK TO QUOTE',
  EXPIRE_QUOTE_IN_PROGRESS_INTO_QUOTE = 'QUOTE PROGRESS TO QUOTE',
  EXPIRE_QUOTE__INTO_PROPOSAL = 'QUOTE TO PROPOSAL',
}
