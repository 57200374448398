export enum PRIVILEGE_ATTACHMENT_TYPE {
  ROLE = 'ROLE',
  RULE = 'RULE',
}

export enum PRIVILEGE_NATURE_TYPE {
  PRODUCT = 'PRODUCT',
  RECRUITMENT = 'RECRUITMENT',
}

export enum PRIVILEGE_REFERENCE_CATEGORIES {
  DESIGNATIONS = 'Designations',
  COMMISSIONS = 'Commissions',
}
