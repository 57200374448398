export enum USER_TYPES {
  EL_ADMIN = 'EL',
  USER = 'U',
}

export enum ACCOUNT_STATES {
  NEW = 'NEW',
  VERIFIED = 'VERIFIED',
  NEW_BLOCKED = 'NEW_BLOCKED',
  VERIFIED_BLOCKED = 'VERIFIED_BLOCKED',
  DELETED = 'DELETED',
}

export enum IDENTITIES_BULK_FIELDS {
  SALUTATION = 'salutation',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL = 'email',
  REPORTS_TO_USERNAME = 'reports_to_username',
  USERNAME = 'username',
  PASSWORD = 'password',
  DIAL_CODE = 'dial_code',
  PHONE_NUMBER = 'phone_number',
  INTERNAL_FIELDS = 'internal_fields',
}

export enum IDENTITIES_BULK_FIELDS_FOR_CSV {
  SALUTATION = 'salutation',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL = 'email',
  REPORTS_TO_USERNAME = 'reports_to_username',
  USERNAME = 'username',
  PASSWORD = 'password',
  DIAL_CODE = 'dial_code',
  PHONE_NUMBER = 'phone_number',
}

export enum SALUTATIONS {
  MIST = 'MIST',
  PROF = 'PROF',
  HISEXC = 'HISEXC',
  HEREXC = 'HEREXC',
  HON = 'HON',
  HONR = 'HONR',
  BRIG = 'BRIG',
  GNRL = 'GNRL',
  MYLOARD = 'MYLOARD',
  CAPT = 'CAPT',
  MR = 'MR',
  MRS = 'MRS',
  MISS = 'MISS',
  MS = 'MS',
  DR = 'DR',
  M_S = 'M/S',
  NM = 'NM',
  REV = 'REV',
  BABY = 'BABY',
  DR_MRS = 'DR.(MRS)',
  DR_MISS = 'DR.(MISS)',
  MAST = 'MAST',
}

export const getSalutationDisplayValue = (
  salutation: SALUTATIONS | string
): string => {
  switch (salutation) {
    case SALUTATIONS.MIST:
      return 'Mist.';

    case SALUTATIONS.PROF:
      return 'Prof.';

    case SALUTATIONS.HISEXC:
      return 'Hisexc.';

    case SALUTATIONS.HEREXC:
      return 'Herexc.';

    case SALUTATIONS.HON:
      return 'Hon.';

    case SALUTATIONS.HONR:
      return 'Honr.';

    case SALUTATIONS.BRIG:
      return 'Brig.';

    case SALUTATIONS.GNRL:
      return 'Gnrl.';

    case SALUTATIONS.MYLOARD:
      return 'Myloard';

    case SALUTATIONS.MR:
      return 'Mr.';

    case SALUTATIONS.MRS:
      return 'Mrs.';

    case SALUTATIONS.MISS:
      return 'Miss';

    case SALUTATIONS.MS:
      return 'Ms.';

    case SALUTATIONS.DR:
      return 'Dr.';

    case SALUTATIONS.M_S:
      return 'M/S';

    case SALUTATIONS.REV:
      return 'Rev.';

    case SALUTATIONS.BABY:
      return 'Baby';

    case SALUTATIONS.DR_MRS:
      return 'Dr. (Mrs.)';

    case SALUTATIONS.DR_MISS:
      return 'Dr. (Miss)';

    case SALUTATIONS.MAST:
      return 'Mast';

    case SALUTATIONS.CAPT:
      return 'Capt.';

    case SALUTATIONS.NM:
      return 'Nm';

    default:
      return '';
  }
};

export const LANGUAGE_CODES = [
  'ab',
  'aa',
  'af',
  'ak',
  'sq',
  'am',
  'ar',
  'an',
  'hy',
  'as',
  'av',
  'ae',
  'ay',
  'az',
  'bm',
  'ba',
  'eu',
  'be',
  'bn',
  'bi',
  'bs',
  'br',
  'bg',
  'my',
  'ca',
  'ch',
  'ce',
  'ny',
  'zh',
  'cu',
  'cv',
  'kw',
  'co',
  'cr',
  'hr',
  'cs',
  'da',
  'dv',
  'nl',
  'dz',
  'en',
  'eo',
  'et',
  'ee',
  'fo',
  'fj',
  'fi',
  'fr',
  'fy',
  'ff',
  'gd',
  'gl',
  'lg',
  'ka',
  'de',
  'el',
  'kl',
  'gn',
  'gu',
  'ht',
  'ha',
  'he',
  'hz',
  'hi',
  'ho',
  'hu',
  'is',
  'io',
  'ig',
  'id',
  'ia',
  'ie',
  'iu',
  'ik',
  'ga',
  'it',
  'ja',
  'jv',
  'kn',
  'kr',
  'ks',
  'kk',
  'km',
  'ki',
  'rw',
  'ky',
  'kv',
  'kg',
  'ko',
  'kj',
  'ku',
  'lo',
  'la',
  'lv',
  'li',
  'ln',
  'lt',
  'lu',
  'lb',
  'mk',
  'mg',
  'ms',
  'ml',
  'mt',
  'gv',
  'mi',
  'mr',
  'mh',
  'mn',
  'na',
  'nv',
  'nd',
  'nr',
  'ng',
  'ne',
  'no',
  'nb',
  'nn',
  'ii',
  'oc',
  'oj',
  'or',
  'om',
  'os',
  'pi',
  'ps',
  'fa',
  'pl',
  'pt',
  'pa',
  'qu',
  'ro',
  'rm',
  'rn',
  'ru',
  'se',
  'sm',
  'sg',
  'sa',
  'sc',
  'sr',
  'sn',
  'sd',
  'si',
  'sk',
  'sl',
  'so',
  'st',
  'es',
  'su',
  'sw',
  'ss',
  'sv',
  'tl',
  'ty',
  'tg',
  'ta',
  'tt',
  'te',
  'th',
  'bo',
  'ti',
  'to',
  'ts',
  'tn',
  'tr',
  'tk',
  'tw',
  'ug',
  'uk',
  'ur',
  'uz',
  've',
  'vi',
  'vo',
  'wa',
  'cy',
  'wo',
  'xh',
  'yi',
  'yo',
  'za',
  'zu',
];

export enum IDENTITIES_CUSTOM_PERMISSIONS {
  IDENTITIES_HIERARCHY = 'HIERARCHY:IDENTITIES',
  IDENTITIES_INTEGRATION = 'INTEGRATION:IDENTITIES',
  IDENTITIES_IMPERSONATE = 'IMPERSONATE:IDENTITIES',
}

export const IDENTITIES_ACTIONS = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  RESTORE: 'RESTORE',
  RESET: 'RESET',
  BLOCK: 'BLOCK',
  UNBLOCK: 'UNBLOCK',
  RESEND: 'RESEND',
  CONNECT: 'CONNECT',
  VIEW: 'VIEW',
  ADD: 'ADD',
  IMPERSONATE: 'IMPERSONATE',
  VIEW_ACHIEVEMENTS: 'VIEW_ACHIEVEMENTS',
  VIEW_TARGETS: 'VIEW_TARGETS',
};

export const IDENTITIES_ACCOUNT_STATE = {
  NEW: 'NEW',
  VERIFIED: 'VERIFIED',
  NEW_BLOCKED: 'NEW_BLOCKED',
  VERIFIED_BLOCKED: 'VERIFIED_BLOCKED',
  DELETED: 'DELETED',
};

export const IDENTITIES_SPEED_DIAL = {
  ADD_NEW: 'ADD_NEW',
  VIEW_HIERARCHY: 'VIEW_HIERARCHY',
  EXPORT_AS_CSV: 'EXPORT_AS_CSV',
};
