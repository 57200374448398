export enum FORMULA_ERROR_CODES {
  ERR_INVALID_CONDITIONAL_EXPRESSION = 'ERR_INVALID_CONDITIONAL_EXPRESSION',
  ERR_INVALID_COMPARISON_EXPRESSION = 'ERR_INVALID_COMPARISON_EXPRESSION',
  ERR_INVALID_MATHEMATICAL_EXPRESSION = 'ERR_INVALID_MATHEMATICAL_EXPRESSION',
  ERR_INVALID_BRACKETS = 'ERR_INVALID_BRACKETS',
  ERR_INVALID_ELEMENT = 'ERR_INVALID_ELEMENT',
  ERR_INVALID_PARAMS = 'ERR_INVALID_PARAMS',
  ERR_INVALID_FORMULA = 'ERR_INVALID_FORMULA',
  MAX_TRIES_EXCEEDED = 'ERR_MAX_TRIES_EXCEEDED',
}

export enum FORMULA_AUTHENTICATION_TYPES {
  NONE = 'NONE',
  BASIC = 'BASIC',
  BEARER = 'BEARER',
}

export enum FORMULA_ELEMENT_TYPES {
  CURSOR = 'CURSOR',
  OPERATOR = 'OPERATOR',
  CONSTANT = 'CONSTANT',
  REFERENCE = 'REFERENCE',
  DATA_REFERENCE = 'DATA_REFERENCE',
  INTEGRATION_MAPPING = 'INTEGRATION_MAPPING',
  EXTERNAL_DATA = 'EXTERNAL_DATA',
  CUSTOM_VARIABLE = 'CUSTOM_VARIABLE',
  FILE_DATA = 'FILE_DATA',
  PRODUCT = 'PRODUCT',
  TARGET = 'TARGET',
}

export enum OPERATOR_TYPES {
  BASIC = 'BASIC',
  COMPARATIVE = 'COMPARATIVE',
  LOGICAL = 'LOGICAL',
  CONDITIONAL = 'CONDITIONAL',
}

export enum VARIABLE_DATA_TYPES {
  // Be careful when adding new types here. Because it will break FormulaService
  NUMBER = 'Number',
  TEXT = 'Text',
  DATE = 'Date',
}

export enum DATA_REFERENCE_MODULES {
  ADVISOR = 'ADVISOR',
}

export enum EXTERNAL_DATA_TYPES {
  FORM_DATA_LOAN_AMOUNT = 'Form Data: Loan Amount',
  FORM_DATA_CUSTOMER_AGE = 'Form Data: Customer Age',
  FORM_DATA_CUSTOMER_GENDER = 'Form Data: Customer Gender',
}

export enum COMPARE_WITH {
  DIRECT = 'direct',
  LOCAL = 'local',
}

export enum ASSIGNMENT_TYPES {
  DIRECT = 'direct',
  LOCAL = 'local',
  RUNTIME = 'runtime',
  PREDEFINED_DYNAMIC = 'predefined_dynamic',
  PREDEFINED_STATIC = 'predefined_static',
}

export enum TARGET_PARAMS {
  START_DATE = 'start_date',
  END_DATE = 'end_date',
}

export enum RUNTIME_COLLECTABLE_FIELDS {
  AGENT_CODE = 'agent_code',
  // START_DATE = 'start_date',
  // END_DATE = 'end_date',
  YEAR = 'year',
  MONTH_NUMBER_ZERO_BASED = 'month_number_zero_based',
  MONTH_NUMBER_ONE_BASED = 'month_number_one_based',
  MONTH_NAME_THREE_CHARACTERS = 'month_name_three_characters',
  MONTH_START_DATE = 'month_start_date',
  MONTH_END_DATE = 'month_end_date',
  QUARTER_START_DATE = 'quarter_start_date',
  QUARTER_END_DATE = 'quarter_end_date',
  HALF_YEAR_START_DATE = 'half_year_start_date',
  HALF_YEAR_END_DATE = 'half_year_end_date',
  YEAR_START_DATE = 'year_start_date',
  YEAR_END_DATE = 'year_end_date',
  BATCH_START_DATE = 'batch_start_date',
  BATCH_END_DATE = 'batch_end_date',
  PREVIOUS_MONTH_START_DATE = 'previous_month_start_date',
  PREVIOUS_MONTH_END_DATE = 'previous_month_end_date',
  PREVIOUS_MONTH_BATCH_START_DATE = 'previous_month_batch_start_date',
  PREVIOUS_MONTH_BATCH_END_DATE = 'previous_month_batch_end_date',
}

export enum DATE_FIELDS {
  START_DATE = 'Start Date',
  END_DATE = 'End Date',
}
