export enum CONNECTIONS_DEFAULT_FIELDS {
  NATIONAL_IDENTITY_CARD_NUMBER = 'National Identity Card (NIC) Number',
  CONTACT_NUMBER = 'Contact Number (Type)',
  APPOINTMENT_DATE = 'Appointment Date',
  INIT_APPOINTMENT_DATE = 'Initial Appointment Date',
  BASIC_SALARY = 'Basic Salary',
  BUSINESS_DEVELOPMENT_GRANT = 'Business Development Grant',
  DESIGNATION_CODE = 'Designation Code',
  REGION = 'Region',
  ZONE = 'Zone',
  AGENT_CODE = 'Agent Code',
  BANK = 'Bank',
  BRANCH = 'Branch (Code)',
  ACCOUNT_NUMBER = 'Account Number',
  ACCOUNT_HOLDER_NAME = 'Account Holder Name',
}

export enum CONNECTION_EVENTS {
  ADD_OR_UPDATE_CONNECTION = 'ADD_OR_UPDATE_CONNECTION',
}
