export enum DATA_REFERENCE_MODULE_PROPERTIES {
  BASIC_SALARY = 'Basic Salary',
  BDG = 'BDG',
  APPOINTMENT_DATE = 'Appointment Date',
}

export enum REFERENCE_COMPARISON_TYPES {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT EQUAL',
  GREATER_THAN = 'GREATER THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER THAN OR EQUAL',
  LESS_THAN = 'LESS THAN',
  LESS_THAN_OR_EQUAL = 'LESS THAN OR EQUAL',
}

export enum FIELD_VALIDATION_ERROR_TYPES {
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE',
  TYPE_MISMATCH = 'TYPE_MISMATCH',
}

export enum REFERENCE_CATEGORY_TYPES {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum DEFAULT_REFERENCES {
  LEAD_RE_ASSIGN_DURATIONS = 'Lead Re-assign Durations',
}
// OPERATING_BRANCHES = 'Operating Branches',

export enum PREDEFINED_REFERENCE_CATEGORIES {
  COUNTRIES = 'COUNTRIES',
  STATES = 'STATES',
  DISTRICTS = 'DISTRICTS',
  CITIES = 'CITIES',
}

export enum SYSTEM_GENERATED_REFERENCE_SECTIONS {
  PREDEFINED = 'Pre-defined',
  UNCATEGORIZED = 'Uncategorized',
  DELETED = 'Deleted',
}

export enum REFERENCE_TYPES {
  GENERAL = 'GENERAL',
  COUNTRIES = 'COUNTRIES',
  STATES = 'STATES',
  DISTRICTS = 'DISTRICTS',
  CITIES = 'CITIES',
}
