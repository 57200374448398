import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MODULES, PERMISSION_ACTIONS } from '@shared/constants';
import { getModulePermission } from '@shared/utils';

import { ModuleGuard } from './modules/core/guards/module.guard';
import { PermissionGuard } from './modules/core/guards/permission.guard';
import { BankOrderTemplateComponent } from './modules/payouts/pages/bank-order-template/bank-order-template.component';
import { PayslipTemplateComponent } from './modules/payouts/pages/payslip-template/payslip-template.component';
import { IAMGuard } from './modules/setup/iam-config/guards/iam.guard';
import { UserManagementGuard } from './modules/setup/iam-config/guards/user-management.guard';
import {
  IAMUserManagement,
  USER_MGT_FEATURES,
} from './modules/setup/iam-config/services/iam-config.service';
import { StorageGuard } from './modules/setup/storage/guards/storage.guard';
import { AboutComponent } from './pages/about/about.component';
import { ViewReleaseNoteComponent } from './pages/about/popups/view-release-note/view-release-note.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DeactivateModuleComponent } from './pages/deactivate-module/deactivate-module.component';
import { HomeComponent } from './pages/home/home.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PersonalizationComponent } from './pages/personalization/personalization.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AppGuard } from './app.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/app/launchpad',
    pathMatch: 'full',
  },
  {
    path: 'app',
    redirectTo: '/app/launchpad',
    pathMatch: 'full',
  },
  {
    path: 'app/auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'app/preview',
    loadChildren: () =>
      import('./modules/preview/preview.module').then((m) => m.PreviewModule),
  },
  {
    path: 'payouts/payslip/print',
    component: PayslipTemplateComponent,
  },
  {
    path: 'payouts/bank-order/print',
    component: BankOrderTemplateComponent,
  },
  {
    path: 'app',
    component: DashboardComponent,
    canActivate: [AppGuard],
    children: [
      {
        path: 'launchpad',
        component: HomeComponent,
        data: {
          title: 'app.launchpad',
          goBackEnabled: false,
          searchBarEnabled: false,
        },
      },
      {
        path: 'about',
        component: AboutComponent,
        data: {
          title: 'app.about',
          goBackEnabled: true,
        },
      },
      {
        path: 'view-release-note',
        component: ViewReleaseNoteComponent,
        data: {
          title: 'view-release-note',
          goBackEnabled: true,
        },
      },
      {
        path: 'personalization',
        component: PersonalizationComponent,
        data: {
          title: 'Personalization',
          goBackEnabled: true,
        },
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: {
          title: 'app.profile',
          goBackEnabled: true,
        },
      },
      {
        path: 'profile/reset-password',
        component: ResetPasswordComponent,
        canActivate: [UserManagementGuard],
        data: {
          title: 'app.reset-password',
          goBackEnabled: true,
          config: USER_MGT_FEATURES.SELF_PASSWORD_RESET,
          configCb: (data: IAMUserManagement) => {
            return data[USER_MGT_FEATURES.SELF_PASSWORD_RESET];
          },
        },
      },
      {
        path: 'apps',
        loadChildren: () =>
          import('./modules/connected-apps/connected-apps.module').then(
            (m) => m.ConnectedAppsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard, IAMGuard],
        data: {
          module: MODULES.APPS,
          allowedPermissions: getModulePermission(MODULES.APPS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'connections',
        loadChildren: () =>
          import('./modules/connections/connections.module').then(
            (m) => m.ConnectionsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard, IAMGuard],
        data: {
          module: MODULES.CONNECTIONS,
          allowedPermissions: getModulePermission(MODULES.CONNECTIONS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'authorization',
        loadChildren: () =>
          import('./modules/permissions/permissions.module').then(
            (m) => m.PermissionModule
          ),
      },
      {
        path: 'reference-categories',
        loadChildren: () =>
          import('./modules/references/references.module').then(
            (m) => m.ReferencesModule
          ),
      },
      {
        path: 'setup',
        loadChildren: () =>
          import('./modules/setup/setup.module').then((m) => m.SetupModule),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.SETUP,
          allowedPermissions: getModulePermission(MODULES.IDENTITIES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'identities',
        loadChildren: () =>
          import('./modules/identities/identities.module').then(
            (m) => m.IdentitiesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard, IAMGuard],
        data: {
          module: MODULES.IDENTITIES,
          allowedPermissions: getModulePermission(MODULES.IDENTITIES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },

      {
        path: 'targets',
        loadChildren: () =>
          import('./modules/targets/targets.module').then(
            (m) => m.TargetsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.TARGETS,
          allowedPermissions: getModulePermission(MODULES.TARGETS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'achievements',
        loadChildren: () =>
          import('./modules/achievements/achievements.module').then(
            (m) => m.AchievementsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.ACHIEVEMENTS,
          allowedPermissions: getModulePermission(MODULES.ACHIEVEMENTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },

      {
        path: 'knowledgebase',
        loadChildren: () =>
          import('./modules/knowledgebase/knowledgebase.module').then(
            (m) => m.KnowledgebaseModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.KNOWLEDGEBASE,
          allowedPermissions: getModulePermission(MODULES.KNOWLEDGEBASE, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'notices',
        loadChildren: () =>
          import('./modules/notices/notices.module').then(
            (m) => m.NoticesModule
          ),
        canActivate: [ModuleGuard],
        data: {
          module: MODULES.NOTICES,
        },
      },
      {
        path: 'logs',
        loadChildren: () =>
          import('./modules/logs/logs.module').then((m) => m.LogsModule),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.LOGS,
          allowedPermissions: getModulePermission(MODULES.LOGS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('./modules/messages/message.module').then(
            (m) => m.MessageModule
          ),
      },
      {
        path: 'storage',
        loadChildren: () =>
          import('./modules/storage/storage.module').then(
            (m) => m.StorageModule
          ),
        canLoad: [StorageGuard],
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./modules/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.NOTIFICATIONS,
          allowedPermissions: getModulePermission(MODULES.NOTIFICATIONS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'modules',
        loadChildren: () =>
          import('./modules/modules/modules.module').then(
            (m) => m.ModulesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.MODULES,
          allowedPermissions: getModulePermission(MODULES.MODULES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'integrations',
        loadChildren: () =>
          import('./modules/integrations/integrations.module').then(
            (m) => m.IntegrationsModule
          ),
      },
      {
        path: 'deactivated',
        component: DeactivateModuleComponent,
        data: {
          goBackEnabled: true,
        },
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
        data: {
          title: 'pages.access-denied.error-title',
          goBackEnabled: true,
        },
      },

      // Business
      {
        path: 'customers',
        loadChildren: () =>
          import('./modules/customers/customers.module').then(
            (m) => m.CustomersModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.CUSTOMERS,
          allowedPermissions: getModulePermission(MODULES.CUSTOMERS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'proposals',
        loadChildren: () =>
          import('./modules/policies/policies.module').then(
            (m) => m.PoliciesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.PROPOSALS,
          allowedPermissions: getModulePermission(MODULES.PROPOSALS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      // {
      //   path: 'endorsements',
      //   loadChildren: () =>
      //     import('./modules/endorsements/endorsements.module').then(
      //       (m) => m.EndorsementsModule
      //     ),
      //   canLoad: [ModuleGuard, PermissionGuard],
      //   data: {
      //     module: MODULES.ENDORSEMENTS,
      //     allowedPermissions: getModulePermission(MODULES.ENDORSEMENTS, [
      //       PERMISSION_ACTIONS.READ,
      //     ]),
      //   },
      // },
      {
        path: 'cover-notes',
        loadChildren: () =>
          import('./modules/cover-notes/cover-notes.module').then(
            (m) => m.CoverNotesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.COVER_NOTES,
          allowedPermissions: getModulePermission(MODULES.COVER_NOTES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },

      // AVO
      {
        path: 'leads',
        loadChildren: () =>
          import('./modules/leads/leads.module').then((m) => m.LeadsModule),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.LEADS,
          allowedPermissions: getModulePermission(MODULES.LEADS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'activities',
        loadChildren: () =>
          import('./modules/activities/activities.module').then(
            (m) => m.ActivitiesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.ACTIVITIES,
          allowedPermissions: getModulePermission(MODULES.ACTIVITIES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'referrers',
        loadChildren: () =>
          import('./modules/referrers/referrers.module').then(
            (m) => m.ReferrersModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.REFERRERS,
          allowedPermissions: getModulePermission(MODULES.REFERRERS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'promotions',
        loadChildren: () =>
          import('./modules/promotions/promotions.module').then(
            (m) => m.PromotionsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.PROMOTIONS,
          allowedPermissions: getModulePermission(MODULES.PROMOTIONS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'agents',
        loadChildren: () =>
          import('./modules/agents/agents.module').then((m) => m.AgentsModule),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.AGENTS,
          allowedPermissions: getModulePermission(MODULES.AGENTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'recruitments',
        loadChildren: () =>
          import('./modules/recruitment/recruitment.module').then(
            (m) => m.RecruitmentsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.RECRUITMENTS,
          allowedPermissions: getModulePermission(MODULES.RECRUITMENTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'competitions',
        loadChildren: () =>
          import('./modules/competitions/competitions.module').then(
            (m) => m.CompetitionsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.COMPETITIONS,
          allowedPermissions: getModulePermission(MODULES.COMPETITIONS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'payouts',
        loadChildren: () =>
          import('./modules/payouts/payouts.module').then(
            (m) => m.PayoutsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.PAYOUTS,
          allowedPermissions: getModulePermission(MODULES.PAYOUTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      //accounting
      {
        path: 'chart-of-accounts',
        loadChildren: () =>
          import('./modules/chart-of-accounts/chart-of-accounts.module').then(
            (m) => m.ChartOfAccountsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.CHART_OF_ACCOUNTS,
          allowedPermissions: getModulePermission(MODULES.CHART_OF_ACCOUNTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'entries',
        loadChildren: () =>
          import('./modules/entries/entries.module').then(
            (m) => m.EntriesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.ENTRIES,
          allowedPermissions: getModulePermission(MODULES.ENTRIES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
    ],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
